import React, { Component,useRef } from "react";
import * as Cookies from 'js-cookie'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
// import moment from "moment";
import DatePicker from "react-datepicker";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import '../../assets/fonts/css/icons.css'
import Validator from '../../utils/Validator'
import { RESOLUTION_ARR } from '../../utils/Settings'
import Header from '../common/Header'
import Footer from '../common/Footer'
import Sidebar from '../common/Sidebar'
import { API_BASE_URL,SOCKET_URL, BASE_URL } from "../constants/AppConstants";
import io from "socket.io-client"; 
import TimePicker from 'react-time-picker';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const axios = require('axios').default;
const loginUser= localStorage.getItem('loginUser');
var moment = require('moment-timezone');
const myTimeZone= localStorage.getItem('myTimeZone');
// moment().tz(myTimeZone).format();
const socketUrl = SOCKET_URL;
var globalSocket;
toast.configure();

export default class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      joinBtn: false,
      channel: '',
      baseMode: '',
      transcode: '',
      attendeeMode: '',
      videoProfile: '',
      debateDuration:'',
      upcoming:0,
      pending:0,
      debateTopic:'',
      debateDate:'',
      debateTime:'', 
      filter:'',
      allDebates:[], 
      upcomingDebates:[],
      copied:false,
      copyBtn:"Copy",
      debateInvitations:[]
    }
    //this.state = this.initialState;


    // this.allDebates = this.allDebates.bind(this);
    //this.allDebates = this.allDebates.bind(this);
  }
  

   
  // handleChange = async (date) => {
  //   // alert(date);
  //   //v//ar s=date.split('T');

  //   this.setState({
  //     debateDate: date
  //   })
  // }
  handleChange = async (date) => {

    if(moment(date, 'YYYY-MM-DD').isValid()){
      moment(date.toISOString()).format()
      var newdate=moment(date.toISOString()).format();
      var debate_date=newdate.split('T');
      
      // alert(debate_date[0]);

      this.setState({
        debateDate: date,
        filter: debate_date[0]
      })
    }
  }

  
  componentDidMount() {
    this.getDebates()
   // console.log(moment.utc("2021-08-24 04:00:00").tz('Asia/kolkata').format('YYYY-MM-DD hh:mm:ss A')) 
   this.initSocket()
  }


  initSocket = async () => {
    let socket = io.connect(socketUrl)
    globalSocket=socket;
     
  }

  getDebates = async () => {
    let response = await axios.get(API_BASE_URL+'debatesList/'+loginUser);
    let response2 = await axios.get(API_BASE_URL+'upcoming-debates/'+loginUser);
    // console.log(response.data.data);
    // console.log('response.data.data');
    
    let upcomingCount=0;
    for(let deb of response2.data.data){
      // alert(deb.started)
      if(deb.started !== "2"){
        // alert("entered")
        upcomingCount++;
      }
    }
   
    this.setState({
        allDebates: response.data.data,
        pending:response.data.counts_pending,
        // upcoming:response.data.counts_upcoming
        upcoming:upcomingCount
    }); 

    
   // let noti = await axios.get(API_BASE_URL+'update-pending-notification/'+loginUser);
  }

  updateNotification = async (id) => {
    let noti = await axios.get(API_BASE_URL+'update-pending-notification/'+loginUser, {'debate_id':id,'user_id':loginUser});
  }
  

  acceptDebate = async (id) => {
   
    let data = await axios.post(API_BASE_URL+'debate/accept',{'debate_id':id,'user_id':loginUser});    
    toast.success('Thanks for accepting the debate invitation.', {autoClose:3000});
    await globalSocket.emit( 'accept_debate', {'debate_id':id,'user_id':loginUser} )
    this.updateNotification(id);
    this.getDebates();
    
  } 
  declineDebate = async (id) => {

    let data = await axios.post(API_BASE_URL+'debate/decline',{'debate_id':id,'user_id':1});    
    toast.warning('You have declined the debate invitation.', {autoClose:10000});
    await globalSocket.emit( 'decline_debate', {'debate_id':id,'user_id':loginUser} )
    this.updateNotification(id);
    this.getDebates();
  }

  cancelDebate=async(id)=>{

    let data = await axios.post(API_BASE_URL+'debate/decline',{'debate_id':id,'user_id':1});
    toast.warning('You have cancelled the debate invitation.', {autoClose:10000});
    await globalSocket.emit( 'decline_debate', {'debate_id':id,'user_id':loginUser} )
    //console.log(this.state); 
    this.updateNotification(id);
    this.getDebates();
  }
  
  inviteDebate=async(id)=>{
    Cookies.set('debate_id', id);
    setTimeout(function(){ 
        //window.location.hash = "my-created-debates"
        window.location.hash = "#/debate/invite/"+id;
    }, 1000);

  }
  filter=async()=>{
    // alert(this.state.debateDate) 
    // var s=date.split('T');
    let response = await axios.post(API_BASE_URL+'debate/filter',{'debate_date':this.state.filter,userId:loginUser,'type':'pending'});
    this.setState({
      allDebates: response.data.data 

    });
    
  }
  
  createNotification = (type) => {
    return () => {
      switch (type) {
        case 'info':
          NotificationManager.info('Info message');
          break;
        case 'success':
          NotificationManager.success('Success message', 'Title here');
          break;
        case 'warning':
          NotificationManager.warning('Warning message', 'Close after 3000ms', 3000);
          break;
        case 'error':
          NotificationManager.error('Error message', 'Click me!', 5000, () => {
            alert('callback');
          });
          break;
      }
    };
  };


  handleJoin = async () => {

    console.log(this.state)
    Cookies.set('channel', this.state.channel)
    Cookies.set('baseMode', this.state.baseMode)
    Cookies.set('transcode', this.state.transcode)
    Cookies.set('attendeeMode', this.state.attendeeMode)
    Cookies.set('videoProfile', this.state.videoProfile)
    var payload = {
      'debate_duration':this.state.debateDuration,
      'topic':this.state.debateTopic,
      'debate_date':this.state.debateDate,
      'debate_time':this.state.debateTime,
      'userId':1
    } 
    await axios.post(API_BASE_URL+'debate',payload).then(function (response) {
      toast.success('Debate has been created.', {autoClose:1000});
    }).catch(function (error) {
      console.log(error);
    });
  }



  render() {
    const {allDebates} = this.state;
    //var loggedinTimeZone = localStorage.getItem('myTimeZone');
    // loggedinTimeZone = loggedinTimeZone.replace("Asia/Calcutta", "Asia/Kolkata");
    var profile_image = "assets/images/profile.png";
    // if(row.debateWith.userId == loginUser){
    //   profile_image = row.debateWith.profile_image
    // }else{
    //   profile_image = row.user.profile_image
    // }
    
    
    return (
      <>
      <Header/>
      <section class="debate_outer">
        <div class="container custom_container">
          <div class="row">
            <div class="col-md-12">
            <div class="set_debate_main">              
                <div class="debate_title">Set Up A Debate</div>
                <a class="create_debate_btn1" href="#/set-up-new-debate">Create Debate</a>
            </div>
            <div class="debatetab_outer">
                <div class="debate_left">
                    <Sidebar upcoming={this.state.upcoming} pending={this.state.pending} />
                </div>
                <div class="debate_right upcoming_debate_outer">
                  <div class="mt-2 tab-content">
                    <div class="tab-pane active" id="home" role="tabpanel">
                      <div class="tab_inner upcoming_debate">
                        <div class="share_div filter_outer"> 
                          <div class="date_input">
                        
                            {/*<input class="custom_input" onChange={e => this.setState({ filter: e.target.value })} type="date" placeholder="" name=""/>*/}
                            <DatePicker
                                className="custom_input"
                                selected={ this.state.debateDate } 
                                onChange={ this.handleChange }
                                name="startDate"
                                minDate={moment().toDate()}
                                dateFormat="dd-MM-yyyy"
                                autoComplete="off"
                            />
                      
                          </div>
                          <input class="" autocomplete="false" type="button" value="Filter" name="" onClick={this.filter}/>
                        </div>
                        <ul class="upcoming_ul"> 
                          {   
                              this.state.allDebates.map((row,i) =>
        
                              
                             
                              (   
                                  
                                

                                
                                <li key={i} className={ (row.user.id!=loginUser && row.debate_status=="pending" )? 'invite':''}>

                                  <div class="upcoming_date">Date - {moment(row.debate_date).format("LL")} </div>
                                  
                                  {/* <div class="upcoming_date">Scheduled Time - {moment.utc(row.utc_date_time).tz(row.timezone).format('LT') } @ {row.timezone} </div> */}
                                  <div class="upcoming_date">Scheduled Time - {moment(`${row.debate_date} ${row.debate_time}`).format('LT') } @ {row.timezone} </div>
                                  {/* <div class="upcoming_date">My Time Zone - {moment.utc(row.utc_date_time).tz(loggedinTimeZone).format('hh:mm:ss A') }  @ {loggedinTimeZone}  </div> */}
                                 
                                  <div class="upcoming_li_inner">
                                     
                                     {(row.userId == loginUser)?
                                      <div class="upcoming_li_left">
                                      <img src={(row.debateWith.profile_image)?row.debateWith.profile_image:'assets/images/profile.png'} alt=""/>
                                      <div class="desc_div">
                                          { (row.debateWith == loginUser)?
                                            <a href={"https://twitter.com/"+ row.debateWith.email}  target="_blank">
                                              <div class="name_title">11{(row.userId == loginUser)?row.user.name: row.debateWith.name}</div>
                                            </a>
                                              :
                                            <a href={"https://twitter.com/"+ row.debateWith.email }  target="_blank">
                                              <div class="name_title">{row.debateWith.name }</div>
                                            </a>
                                          }
                                          {
                                           (loginUser == row.user.id)?(<p>@{row.debateWith.email.substring(0, 8)}...</p>):''   
                                          }
                                        {/* <p>@{(row.user.email)?row.user.email.substring(0, 8): row.user.email.substring(0, 8)}...</p> */}
                                      </div>
                                      </div>
                                     :
                                    <div class="upcoming_li_left">
                                     <img src={(row.user.profile_image)?row.user.profile_image:'assets/images/profile.png'} alt=""/>
                                      <div class="desc_div">
                                          { (row.user == loginUser)?
                                            <a href={"https://twitter.com/"+ row.debateWith.email}  target="_blank">
                                              <div class="name_title">33{(row.debateWith == loginUser)?row.debateWith.name: row.user.name}</div>
                                            </a>
                                              :
                                            <a href={"https://twitter.com/"+ row.user.email }  target="_blank">
                                              <div class="name_title">{row.user.name}</div>
                                            </a>
                                          }
                                        <p>@{(row.user.email)?row.user.email.substring(0, 8): row.debateWith.email.substring(0, 8)}...</p>
                                      </div>
                                      </div>
                                    }

                                      
                                     
                                     <div class="upcoming_li_right">
                                        <p><span>Topic : </span> {row.topic}</p> 
                                        <p><span>Debate Link : </span>{row.debate_public_link.substring(0, 20)}....  <CopyToClipboard text={BASE_URL+'#/event-link/'+row.id} onCopy={() => toast.success('Link copied.', {autoClose:3000}) }><button class="copy"><i className="fal fa-copy"></i></button></CopyToClipboard></p> 
                                        {(row.user.id!=loginUser && row.debate_status=="pending" )?(<a class="submit_btn invite" href="javascript:void(0)" onClick={e=>this.acceptDebate(row.id)}>Accept</a>):"" }
                                        {(row.user.id!=loginUser && row.debate_status=="pending" )?(<a class="cancel_btn invite" href="javascript:void(0)" onClick={e=>this.declineDebate(row.id)}>Decline</a>):"" }
                                        {(row.user.id==loginUser)?(<a class="submit_btn decline_btn" href="javascript:void(0)">Invitation sent</a>):"" }
                                        {(row.user.id==loginUser)?(<a  class="cancel_btn" href="javascript:void(0)" onClick={e=>this.cancelDebate(row.id)}>Cancel</a>):"" }
                                        {this.state.copied ? <span style={{color: 'red'}}>Copied.</span> : null}
                                         
                                     </div>
                                  </div>
                                </li> 
                              ))
                         }  
                        </ul>
                        <div class="row no_records">
                          { this.state.allDebates.length<1 ? "You have no pending debates":""}
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="profile" role="tabpanel">
                      <div class="tab_inner">
                        <div class="share_div"><a href="#"><i class="fas fa-share"></i>Share</a></div>
                        <div class="tab_inputs">
                          <label class="custom_label">Topic</label>
                          <input class="custom_input" type="text" placeholder="" name="" onChange={e => this.setState({ debateTopic: e.target.value })}/>
                          
                          <label class="custom_label">Date</label>
                          <div class="date_input">
                            <input class="custom_input" type="date" placeholder="" name="" onChange={e => this.setState({ debateDate: e.target.value })}/>
                            <i class="fal fa-calendar"></i>
                          </div>
                          {/*<DatePicker
                            onChange={e => this.setState({ debateTime: e.target.value })}
                            value={this.state.debateTime}
                          />*/}
                          <label class="custom_label">Time</label>
                          <div class="date_input">
                            <input class="custom_input" type="time" placeholder="" name=""onChange={e => this.setState({ debateTime: e.target.value })}/>
                            <i class="fal fa-calendar"></i>
                          </div>
                          {/*<TimePicker
                            onChange={e => this.setState({ debateDuration: e.target.value })}
                            value={this.state.debateDuration}
                          />*/}
                          <label class="custom_label">Debate Duration</label>
                          <input class="custom_input" type="text" placeholder="" name="" onChange={e => this.setState({ debateDuration: e.target.value })}/> 
                          <a class="submit_btn" onClick={this.handleJoin} disabled={!this.state.joinBtn}>Submit</a>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="messages" role="tabpanel">
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <NotificationContainer/>
      <Footer/>
      </>
    )
  }
}

  