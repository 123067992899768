import React, { Component,useRef, Button } from "react";
import * as Cookies from 'js-cookie'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import moment from "moment";
import DatePicker from "react-datepicker";
import TimePicker from 'rc-time-picker';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import '../../assets/fonts/css/icons.css';
import ReactDOM from 'react-dom';
import 'rc-time-picker/assets/index.css';
import "react-datepicker/dist/react-datepicker.css";
import '../../assets/fonts/css/black-dashboard.css';
import '../../assets/fonts/css/demo.css';
import '../../assets/fonts/css/bootstrap.min.css';
import '../../assets/fonts/css/style.css';
import Validator from '../../utils/Validator';
import { RESOLUTION_ARR } from '../../utils/Settings';
import Header from '../common/DashboardHeader';
import Footer from '../common/DashboardFooter';
import Sidebar from '../common/DashboardSidebar';
import { API_BASE_URL } from "../constants/AppConstants";

import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";

import DataTable, { createTheme } from 'react-data-table-component-with-filter';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

// import DatePicker from 'react-date-picker';
const axios = require('axios').default;
const loginUser= localStorage.getItem('loginUser')


const format = 'hh:mm a';

export default class Listing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      joinBtn: false,
      channel: '',
      baseMode: '',
      transcode: '',
      attendeeMode: '',
      videoProfile: '',
      debateDuration:'',
      debateTopic:'',
      debateDate:'',
      filter:'',
      allDebates:[],
      debateData:[], 
      upcomingDebates:[],
      copied:false,
      copyBtn:"Copy",
      debateInvitations:[],
      show: false,
      allUsers:[],
      debateTopic:'',
      startDate:new Date(),
      debateTime:'', 
      timezone:'',
      debateStatus:'',
      debateStatusErr:'',
      debateDurationErr:'',
      debateTopicErr:'',
      debateDateErr:'',
      debateTimeErr:'',
      userErr:'',
      debateWithUserErr:'',
      debateWithUser:'', 
      user:'', 
      timezoneErr:'',
      allTimeZone:[]

    }
  } 

  handleChange = async (date) => {
    
    var newdate=moment(date.toISOString()).format();
    var debate_date=newdate.split('T'); 
    //alert(debate_date[0]);
    this.setState({
      debateDate: debate_date[0],
      filter: debate_date[0]
    })
  }

  
  componentDidMount() {
    this.getDebates();
 
  }
 
  getDebates = async () => {
    let response = await axios.get(API_BASE_URL+'admin/debate/');
    let data = await axios.get(API_BASE_URL+'get-timezones');
    
    // console.log(response.data.data);
    // console.log('response.data.data');
    this.setState({
      allDebates: response.data.data,
      allTimeZone: data.data.data[0],
    }); 

   let users = await axios.get(API_BASE_URL+'users');
   
   console.log(users.data.data);
   // console.log('data.data[0]');
    this.setState({
      allUsers: users.data.data
    }); 

  }

  deleteDebate = async (id) => {
   
   // preventDefault();
   let data = await axios.delete(API_BASE_URL+'debates/'+id, {'id':id});

   //NotificationManager.warning('', 'You have deleted the debate invitation..');
   toast.warn('You have deleted the debate invitation.', {autoClose:3000});

   console.log(this.state);
   this.getDebates();
 }

  cancelDebate=async(id)=>{
    let data = await axios.delete(API_BASE_URL+'debate',{'debate_id':id,'user_id':1});

    toast.warn('You have cancelled the debate invitation.', {autoClose:3000});


    //NotificationManager.warning('', 'You have cancelled the debate invitation..');
    console.log(this.state); 
    this.getDebates();
  }
  inviteDebate=async(id)=>{
    Cookies.set('debate_id', id);
    // alert(id);
    setTimeout(function(){ 
        //window.location.hash = "my-created-debates"
        window.location.hash = "#/debate/invite/"+id;
    }, 1000);

  }
  filter=async()=>{
    // alert(this.state.debateDate)
    // var s=date.split('T');
    let response = await axios.post(API_BASE_URL+'debate/filter',{'debate_date':this.state.filter,userId:loginUser,'type':'pending'});
    this.setState({
      allDebates: response.data.data 
    });

  }
  

  handleClose = async (date) => {

   this.setState({
     show: false
   })
 }

  handleShow = async (id) => { 

   let data = await axios.get(API_BASE_URL+'admin/debate/'+id, {'debate_id':id,'user_id':1});
  
   
  // let utcTime = moment(data.data.utc_date_time).format("HH:mm:ss")
  // let uTcdate = moment(data.data.utc_date_time).format("YYYY-MM-DD")

   var uTcdate = moment(data.data.utc_date_time).tz(data.data.timezone).format('YYYY-MM-DD');
   var utcTime = moment(uTcdate+'T'+data.data.debate_time+'Z').utc().format('HH:mm:ss');
   
   this.setState({
      show: true,
      debateData: data.data,
      debateWithUser: data.data.debate_with,
      debateTopic: data.data.topic,
      debateDate: uTcdate,
      debateDuration: data.data.debate_duration,
      debateTime: utcTime, 
      timezone: data.data.timezone,      
      debateStatus: data.data.debate_status, 
      user: data.data.userId
    })
    
  }

  formValidation = (e) => {
       
   let isValid = true;
   
   if(this.state.user==='' || this.state.user===undefined){ 
       var isValidUser= false;
       this.setState({
         userErr: "Please select the User"
       });
       
   }else{
    isValidUser= true;
       this.setState({
         userErr: ""
       });
   }

   if(this.state.debateTopic==='' || this.state.debateTopic===undefined){ 
       var isValidTopic= false;
       this.setState({
         debateTopicErr: "Please enter the Topic"
       });
       
   }else{
     var isValidTopic= true;
       this.setState({
         debateTopicErr: ""
       });
   }

   if(this.state.debateDate==='' || this.state.debateDate===undefined){ 
       var isValidDate= false;
       this.setState({
         debateDateErr: "Please enter the date"
       });
   }else{
     var isValidDate= true;
     this.setState({
       debateDateErr: ""
     });
   }

   if(this.state.debateTime==='' || this.state.debateTime===undefined){ 
       var isValidTime= false;
       this.setState({
         debateTimeErr: "Please enter the debate time"
       });
   }else{
    var isValidTime= true;
     this.setState({
       debateTimeErr: ""
     });
   }

   if(this.state.debateDuration==='' || this.state.debateDuration===undefined){ 
       var isValidDur= false;
       this.setState({
         debateDurationErr: "Please enter the debate duration"
       });
   }else{
     var isValidDur= true;
     this.setState({
       debateDurationErr: ""
     });
   }
   if(this.state.timezone==='' || this.state.timezone===undefined){ 
       var isValidTimeZone= false;
       this.setState({
         timezoneErr: "Please select the timezone"
       });
   }else{
     var isValidTimeZone= true;
     this.setState({
       timezoneErr: ""
     });
   }

   if(this.state.debateStatus==='' || this.state.debateStatus===undefined){ 
    var isValidTimeStatus= false;
    this.setState({
      debateStatusErr: "Please select the timezone"
    });
    }else{
      var isValidTimeStatus= true;
      this.setState({
        debateStatusErr: ""
      });
    }

   if(isValidUser && isValidTopic && isValidDate && isValidDur && isValidTime && isValidTimeZone && isValidTimeStatus)
     return true;
     else
     return false;
}

handleUpdate = async () => {
  
   var isValid= await this.formValidation();
   if(isValid===true){
     
     console.log(this.state);
     Cookies.set('channel', this.state.channel)
     Cookies.set('baseMode', this.state.baseMode)
     Cookies.set('transcode', this.state.transcode)
     Cookies.set('attendeeMode', this.state.attendeeMode)
     Cookies.set('videoProfile', this.state.videoProfile)
     
    //  var local_debate_time = this.state.debateDate+' '+ this.state.debateTime;
    //  let utc= moment(local_debate_time, 'YYYY-MM-DD hh:mm:ss A').utc().format('YYYY-MM-DD hh:mm:ss a')
     
    //  let utc_time=utc.split(' ');     
    //  let utcTime = moment(utc_time[1]+' '+utc_time[2], "h:mm:ss A").format("HH:mm:ss")

   //const utcdatetime = this.state.debateDate+'T'+this.state.debateTime+'Z';
   // uTcdate = moment(utcdatetime).tz(this.state.timezone).format('YYYY-MM-DD');
   //var utcTime = moment(utcdatetime).tz(this.state.timezone).format('HH:mm:ss');

   

    
     console.log();
     
     var payload= { 
       'debate_duration':this.state.debateDuration,
       'topic':this.state.debateTopic,
       'debate_date':this.state.debateDate,      
       'debate_time':this.state.debateTime,
       'userId':this.state.user,
       'debate_with':this.state.debateWithUser, 
       'timezone':this.state.timezone,
       'debate_status':this.state.debateStatus,
    
     } 
    
     
     await axios.put(API_BASE_URL+'admin/debate/'+this.state.debateData.id, payload).then(function (response) {
       //this.createNotification('success')
       console.log(response);
       if(response.data.status=='200'){

         // let res=response.data
         //NotificationManager.success('Success', 'Debate has been created.');
         

         toast.success('Debate has been Updated.', {autoClose:3000});
         setTimeout(function(){ 
            

           window.location.hash = "dashboard-listing"
         }, 1000);

         

       }else{

         //NotificationManager.warning('', response.data.message);

         toast.warning(response.data.message, {autoClose:10000});

        
       }

       


     }).catch(function (error) { 
       console.log(error);
     });

     this.setState({
      show: false
    });

   }
   this.getDebates();
   
 }

 onChange = async (value) => {
   
      let utcTime= moment(value, 'YYYY-MM-DD HH:mm:ss A').format('HH:mm:ss')     
    //  let utc_time=utc.split(' ');     
    //  let utcTime = moment(utc_time[1]+' '+utc_time[2], "h:mm:ss A").format("HH:mm:ss")


    //  var local_debate_time = this.state.debateDate+' '+ value;

    //  alert(utc1);
    //  let utc= moment(local_debate_time, 'YYYY-MM-DD hh:mm:ss A').utc().format('YYYY-MM-DD hh:mm:ss a')
     
    //  let utc_time=utc.split(' ');     
    //  let utcTime = moment(utc_time[1]+' '+utc_time[2], "h:mm:ss A").format("HH:mm:ss")

     
     this.setState({
       debateTime: utcTime
     })
    // console.log(this.state);
   }

columns = [
      {
        name: 'Debate Topic',
        selector: row => row.topic,
        sortable: false,
        filterable: true,
        className: "topicHead"
      },
      {
        name: 'Created by',
        selector: row => row.user.name,
        sortable: false,
        filterable: true,
        className: "topicHead"
      },

      {
         name: 'Email',
         selector: row => row.user.secondary_email, 
         sortable: false,
         filterable: true,
         className: "topicHead"
      },
      {
        name: 'Views',
        selector: row => row.total_views,
        sortable: false,
        filterable: true,
        className: "topicHead"
     },
      {
        name: 'UTC Date Time',
        selector: row => moment(row.utc_date_time).format("MM/DD/YYYY hh:mm A"),
        sortable: false,
        filterable:true,
        className: "topicHead datepicker",  
        
     },{
        name: 'Debate Recording',
        cell: row => <div> 
            {row.video_link==null?"No recording":(<a href={'https://debate-consumer-recording.s3.amazonaws.com/'+row.video_link} >Download recording</a>)}
            </div>,
        sortable: false,
        filterable: true,
        className: "topicHead"
        
     },
      {
         name: 'Status',
         selector: row => row.debate_status,
         sortable: false,
         filterable: true,
         className: "topicHead"
      },
      {
         name: 'Action',
         className: "topicHead",
         cell: row => <div> 
            <a onClick={ () => { this.handleShow(row.id) } } ><i class="fa fa-edit"></i></a>&nbsp;&nbsp;&nbsp;
            <a onClick={ () => { if (window.confirm('Are you sure you wish to delete this item?')){ this.deleteDebate(row.id)} } } ><i class="fa fa-trash"></i></a>
            </div>,
       },
   ];


  render() {
    const {allDebates, allUsers} = this.state;

    var payload= {
      'debate_duration':this.state.debateDuration,
      'topic':this.state.debateTopic,
      'debate_date':this.state.debateDate,
      'debate_time':this.state.debateTime,
      'userId':this.state.user,
      'debate_with':this.state.debateWithUser, 
      'timezone':this.state.timezone,
      'debate_status':this.state.debateStatus
    }

    console.log(payload)
    console.log('allDebates')

    

    

    return (
      <>
      <div class="wrapper">
        <Sidebar/>
        <Header/>
        <div class="main-panel">    
           <nav class="navbar navbar-expand-lg navbar-absolute navbar-transparent">
               <div class="container-fluid">
                  <div class="navbar-wrapper">
                     <div class="navbar-toggle d-inline">
                        <button type="button" class="navbar-toggler">
                        <span class="navbar-toggler-bar bar1"></span>
                        <span class="navbar-toggler-bar bar2"></span>
                        <span class="navbar-toggler-bar bar3"></span>
                        </button>
                     </div>
                 
                  </div>
               </div>
            </nav>
            <div class="modal modal-search fade" id="searchModal" tabindex="-1" role="dialog" aria-labelledby="searchModal" aria-hidden="true">
               <div class="modal-dialog" role="document">
                  <div class="modal-content">
                     <div class="modal-header">
                        <input type="text" class="form-control" id="inlineFormInputGroup" placeholder="SEARCH" />
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <i class="tim-icons icon-simple-remove"></i>
                        </button>
                     </div>
                     </div>               
               </div>
            </div>         
            <div class="content">
               <div class="row">
                  <div class="col-12">
                     <div class="dasbord_heading member_outer">
                        <h3>Debate Listing</h3>
                        {/* <div class="btn_outer"><button class="btn">Add Listing</button></div> */}
                     </div>
                  </div>
               </div>
               <div class="row custom_row">
                  <div class="col-md-12 datatable">

              

                  <DataTable
                        columns={this.columns}
                        data={this.state.allDebates}
                        pagination={true}
                        sortable={true}
                        className="rwd-table rwd-table2 shadow"

                  />

                     
                  </div>
               </div>
            </div>
         
            
         </div>

    <Modal className="mainmodel" show={this.state.show} size="md" onHide={this.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit </Modal.Title>
      </Modal.Header>
      <Modal.Body>
         <div class="tab_inputs">

            <label class="custom_label">Topic</label>
            <div style={{color:'red'}}>{this.state.debateTopicErr}</div>
            <input class="custom_input" type="text" value={this.state.debateTopic} placeholder="" name="" onChange={e => this.setState({ debateTopic: e.target.value })}/>

            <div class="row">
            <div class="col-md-6">
            <label class="custom_label">Debate Created by</label> 
            {/*<input class="custom_input" type="text" placeholder="" name="" onChange={e => this.setState({ debateDuration: e.target.value })}/>*/}
            <div style={{color:'red'}}>{this.state.userErr}</div>

            <select class="custom_input" value={this.state.user} onChange={e => this.setState({ user: e.target.value })} >
               <option value="">Select User</option>
                  {
                     this.state.allUsers.map((row,i) =>(  
                        <option value={row.id}>@{row.email}</option>
                     ))
                  }  
            </select>

            </div>
            <div class="col-md-6">

            <label class="custom_label">Debate Created For</label> 
            {/*<input class="custom_input" type="text" placeholder="" name="" onChange={e => this.setState({ debateDuration: e.target.value })}/>*/}
            <div style={{color:'red'}}>{this.state.debateWithUserErr}</div>

            <select class="custom_input" value={this.state.debateWithUser} onChange={e => this.setState({ debateWithUser: e.target.value })} >
               <option value="">Select User</option>
                  {
                     this.state.allUsers.map((row,i) =>(  
                        <option value={row.id}>@{row.email}</option>
                     ))
                  }  
            </select>
            </div>
            </div>
            
            <div class="row">
            <div class="col-md-6">
            <label class="custom_label">Date</label>
            <div style={{color:'red'}}>{this.state.debateDateErr}</div>
            <div class="date_input">
               {/*<input class="custom_input" type="date" placeholder="" name="" onChange={e => this.setState({ debateDate: e.target.value })}/>*/}
               
               <DatePicker
                  className="custom_input"
                  value={ moment(this.state.debateDate).format("MM-DD-YYYY") }  
                  //selected={ moment(this.state.debateDate).format("MM-DD-yyyy") }
                  //selected={ this.state.debateDate } 
                  onChange={ this.handleChange }
                  name="startDate"
                  minDate={moment().toDate()}
                  dateFormat="MM-DD-yyyy"
                  autoComplete="off"
               /> 
               {/*<i class="fal fa-calendar"></i>*/}
            </div> 
            </div>
            <div class="col-md-6">
            <label class="custom_label">Time</label>
            <div style={{color:'red'}}>{this.state.debateTimeErr}</div>
            <div class="date_input">
               {/*<input class="custom_input" type="time" placeholder="" name=""onChange={e => this.setState({ debateTime: e.target.value })}/>*/}
               
               
               <TimePicker
                  style={{ width: 100 }}
                  showSecond={false}
                  defaultValue={moment(this.state.debateTime, "HH:mm:ss")}
                  //defaultValue={ this.state.debateTime }
                  className="custom_input"
                  onChange={this.onChange}
                  format={format}
                  use12Hours
               />
            </div>
            </div>
            </div>
            <div class="row">
            <div class="col-md-6">
            <label class="custom_label">Debate Duration</label>
            <div style={{color:'red'}}>{this.state.debateDurationErr}</div>
            {/*<input class="custom_input" type="text" placeholder="" name="" onChange={e => this.setState({ debateDuration: e.target.value })}/>*/}


            <select class="custom_input" value={this.state.debateDuration} onChange={e => this.setState({ debateDuration: e.target.value })} >
               <option value="">Select Duration</option>
               <option value="10">10 Min</option>
               <option value="20">20 Min</option>
               <option value="30">30 Min</option>
               <option value="40">40 Min</option>
               <option value="50">50 Min</option>
               <option value="60">60 Min</option>
            </select>
            </div>
            <div class="col-md-6">
            <label class="custom_label">Time-Zone</label> 
            {/*<input class="custom_input" type="text" placeholder="" name="" onChange={e => this.setState({ debateDuration: e.target.value })}/>*/}
            <div style={{color:'red'}}>{this.state.timezoneErr}</div>
           
            <select class="custom_input" value={this.state.timezone} onChange={e => this.setState({ timezone: e.target.value })} >
               <option value="">Select Timezone</option>
               <option value="Hawaii Standard Time">Hawaii Standard Time</option>
               <option value="Hawaii-Aleutian Daylight Time">Hawaii-Aleutian Daylight Time</option>
               <option value="Alaska Daylight Time">Alaska Daylight Time</option>
               <option value="Pacific Daylight Time">Pacific Daylight Time</option>
               <option value="Mountain Standard Time">Mountain Standard Time</option>
               <option value="Mountain Daylight Time">Mountain Daylight Time</option>
               <option value="Central Daylight Time">Central Daylight Time</option>
               <option value="Eastern Daylight Time">Eastern Daylight Time</option>
               
               {
                  this.state.allTimeZone.map((row,i) =>(  
                    <option value={row.timezone}>  (GMT {row.gmt_offset}) -  {row.timezone}</option>
                  ))
              }
            </select>
            </div>

            <div class="col-md-12">
            <label class="custom_label">Debate Status</label>
            <div style={{color:'red'}}>{this.state.debateStatusErr}</div>
            
            <select class="custom_input" value={this.state.debateStatus} onChange={e => this.setState({ debateStatus: e.target.value })} >
               <option value="">Select Status</option>
               <option value="accepted">Accepted</option>
               <option value="completed">Completed</option>
               <option value="cancelled">Cancelled</option>
               <option value="declined">Declined</option>
               <option value="pending">Pending</option>
               <option value="started">Started</option>
            </select>
            </div>

            </div>
            <a class="submit_btn" onClick={this.handleUpdate} disabled={!this.state.joinBtn}>Submit</a>
            </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>


        <NotificationContainer/>
        <Footer/>
      </div>
     
      </>
    )
  }
}

  