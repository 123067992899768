import React, { Component } from "react";
import * as Cookies from 'js-cookie'
import DatePicker from "react-datepicker";
import ReactDOM from 'react-dom';
import 'rc-time-picker/assets/index.css';
import "react-datepicker/dist/react-datepicker.css";
// import TimePicker from 'react-gradient-timepicker'; 
// import 'bootstrap/dist/css/bootstrap.min.css';
import TimePicker from 'rc-time-picker';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import moment from "moment";
import '../../assets/fonts/css/icons.css'
import Validator from '../../utils/Validator'
import { RESOLUTION_ARR } from '../../utils/Settings'
import Header from '../common/Header'
import Footer from '../common/Footer'
import Sidebar from '../common/Sidebar'
import { API_BASE_URL,SOCKET_URL } from "../constants/AppConstants";
import io from "socket.io-client"; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();
var globalSocket;
const socketUrl = SOCKET_URL;
const format = 'h:mm a';
// import TimePicker from 'react-time-picker';
//import DatePicker from 'react-date-picker';
const axios = require('axios').default;
// var TimePicker = require('react-gradient-timepicker');
const loginUser= localStorage.getItem('loginUser');
export default class CreateDebate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      joinBtn: false,
      channel: '',
      baseMode: '',
      transcode: '',
      attendeeMode: '',
      videoProfile: '',
      debateDuration:'',
      debateTopic:'',
      debateDate:'',
      startDate:new Date(),
      debateTime:'', 
      timezone:'',
      debateDurationErr:'',
      debateTopicErr:'',
      debateDateErr:'',
      debateTimeErr:'',
      userErr:'', 
      user:'', 
      timezoneErr:'',
      allDebates:[], 
      upcomingDebates:[],
      debateInvitations:[],
      allUsers:[],
      allTimeZone:[]
    }
    //this.state = this.initialState;


    // this.allDebates = this.allDebates.bind(this);
    //this.allDebates = this.allDebates.bind(this);
  }
  

   
  
  componentDidMount() {
      this.getDebates(); 
      this.setState({
        debateDate: moment().format('YYYY-MM-DD') ,
        debateTime: moment().format('HH:mm:ss')
      })
      this.initSocket()
      
  }
 
  initSocket = async () => {
    let socket = io.connect(socketUrl)
    globalSocket=socket;
    this.setState({ socket })
    socket.on('connect', () => console.log( 'Connected')) 
    
    socket.on('create_debate',(data)=>{
      
      console.log(data)
    
    })
}
  getDebates = async () => {

    let users = await axios.get(API_BASE_URL+'users');
    let data = await axios.get(API_BASE_URL+'get-timezones');

     this.setState({
      allTimeZone: data.data.data[0],
      allUsers: users.data.data
    }); 

    let response = await axios.get(API_BASE_URL+'debatesList/'+loginUser);
    let response2 = await axios.get(API_BASE_URL+'upcoming-debates/'+loginUser);
    // console.log(response.data.data);
    // console.log('response.data.data');
    let upcomingCount=0;
    for(let deb of response2.data.data){
      // alert(deb.started)
      if(deb.started !== "2"){
        // alert("entered")
        upcomingCount++;
      }
    }
    this.setState({
      allDebates: response.data.data,
      pending:response.data.counts_pending,
      // upcoming:response.data.counts_upcoming
      upcoming:upcomingCount
    }); 
    // let noti = await axios.get(API_BASE_URL+'update-pending-notification/'+loginUser);

  }

  // onChanges = async (value) =>  {
  //     console.log('fvg' + value );
  // }


  
  formValidation = (e) => {
       
      let isValid = true;
      let userValid = true; 
      let debateValid = true; 
      let dateValid = true; 
      let timeValid = true; 
      let durationValid = true; 
      let timezoneValid = true;

      if(this.state.user==='' || this.state.user===undefined){ 
          userValid = false;
          this.setState({
            userErr: "Please select the User"
          });
          
      }else{
        userValid= true;
          this.setState({
            userErr: ""
          });
      }

      if(this.state.debateTopic==='' || this.state.debateTopic===undefined){ 

          debateValid= false;
          this.setState({
            debateTopicErr: "Please enter the Topic"
          });
          
      }else{
          debateValid= true;
          this.setState({
            debateTopicErr: ""
          });
      }

      if(this.state.debateDate==='' || this.state.debateDate===undefined){ 
          dateValid = false;
          this.setState({
            debateDateErr: "Please enter the date"
          });

      }else{
        dateValid = true;
        this.setState({
          debateDateErr: ""
        });
      }
 
      if(this.state.debateTime==='' || this.state.debateTime===undefined){ 
          timeValid= false;
          this.setState({
            debateTimeErr: "Please enter the debate time"
          });
      }else{
          timeValid= true;
          this.setState({
            debateTimeErr: ""
          });
      }
  
      if(this.state.debateDuration==='' || this.state.debateDuration===undefined){ 

          durationValid = false;
          this.setState({
            debateDurationErr: "Please enter the debate duration"
          });

      }else{

        durationValid = true;
        this.setState({
          debateDurationErr: ""
        });

      }
      if(this.state.timezone==='' || this.state.timezone===undefined){ 

          timezoneValid = false;
          this.setState({
            timezoneErr: "Please select the timezone"
          });

      }else{

        timezoneValid = true;
        this.setState({
          timezoneErr: ""
        });

      }

      if( ( timezoneValid == false ) ||  ( durationValid == false ) ||  ( timeValid == false )  ||  ( userValid == false )  ||  ( debateValid == false ) || (dateValid == false) ){
        isValid = false;
      }

      return isValid;
  }

  handleChange = (date) => {
    var newdate=moment(date.toISOString()).format();
    var debate_date=newdate.split('T'); 
    //alert(debate_date[0]);
    this.setState({
      debateDate: debate_date[0],
      filter: debate_date[0]
    })
  } 


  handleValueChange =  (value) => {

  let utcTime= moment(value, 'YYYY-MM-DD HH:mm:ss A').format('HH:mm:ss')  
  //  console.log('time' + value.format('HH:mm A'))
    this.setState({
      debateTime: utcTime 
    })
    console.log(this.state);
  }


  handleJoin = async () => {
     
    var isValid= await this.formValidation();

    //console.log(' isValid '+ isValid);

    if(isValid === true){
      
      //console.log(this.state);
      
      Cookies.set('channel', this.state.channel)
      Cookies.set('baseMode', this.state.baseMode)
      Cookies.set('transcode', this.state.transcode)
      Cookies.set('attendeeMode', this.state.attendeeMode)
      Cookies.set('videoProfile', this.state.videoProfile)
      
      var local_debate_time = this.state.filter+' '+ this.state.debateTime;

      //console.log('debateTime' + this.state.debateTime );

      //console.log('ccccc' + local_debate_time);

      // let utc = moment(local_debate_time, 'YYYY-MM-DD hh:mm:ss A').utc().format('YYYY-MM-DD hh:mm:ss a')
      // let utc_time=utc.split(' ');
      // let utcTime = moment(utc_time[1]+' '+utc_time[2], "h:mm:ss A").format("HH:mm:ss");
      console.log('dataddpayload debateDate'+this.state.debateDate);

      var payload= {
        'debate_duration':this.state.debateDuration,
        'topic':this.state.debateTopic,
        'debate_date':this.state.debateDate,
        'debate_time':this.state.debateTime,
        'userId':loginUser,
        'debate_with':this.state.user,
        'timezone':this.state.timezone
      } 

     
      console.log('payload' + payload);

      await axios.post(API_BASE_URL+'debate',payload).then(async function (response) {
        if(response.data.status=='200'){

          console.log(payload)
          globalSocket.emit( 'create_debate', payload )
          toast.success('Debate has been created.', {autoClose:3000});
          setTimeout(function(){ 
            window.location.hash = "pending-debates"
          }, 1000);
        }else{
          toast.error(response.data.message, {autoClose:3000});
        }
      }).catch(function (error) {
        console.log(error);
        toast.error(error , {autoClose:3000});
      });
    }


  }
  render() {
    const {allDebates, allUsers} = this.state;
    var payload= {
      'debate_duration':this.state.debateDuration,
      'topic':this.state.debateTopic,
      'debate_date':this.state.debateDate,
      'debate_time':this.state.debateTime,
      'userId':loginUser,
      'debate_with':this.state.user,
      'timezone':this.state.timezone
    } 

    console.log(payload)
    console.log('allDebates')
    //console.log('allDebates');
    
    return (
      <>
      <Header/>
      <section class="debate_outer">
        <div class="container custom_container">
          <div class="row">
            <div class="col-md-12">
              <div class="set_debate_main">              
                <div class="debate_title">Set Up A Debate</div>
                <a class="create_debate_btn1" href="#/set-up-new-debate">Create Debate</a>
              </div>
              <div class="debatetab_outer">
                <div class="debate_left">
                <Sidebar 
                    upcoming={this.state.upcoming}
                    pending={this.state.pending}
                  />
                </div>
                <div class="debate_right upcoming_debate_outer">
                  <div class="mt-2 tab-content">
                     
                    <div class="" id=""> 
                      <div class="tab_inner">
                        {/*<div class="share_div"><a href="#"><i class="fas fa-share"></i>Share</a></div>*/}
                        <div class="tab_inputs">
                          <label class="custom_label">Who do you want to debate with ?</label> 
                          {/*<input class="custom_input" type="text" placeholder="" name="" onChange={e => this.setState({ debateDuration: e.target.value })}/>*/}
                          <div style={{color:'red'}}>{this.state.userErr}</div>

                          <select class="custom_input" value={this.state.user} onChange={e => this.setState({ user: e.target.value })} >
                            
                            <option value="">Select User</option>
                              {
                                  this.state.allUsers.map((row,i) =>(  

                                     (loginUser != row.id ) ? (
                            <option value={row.id}> {row.name} -  {row.email}</option> ) : ( '' ) ))
                              }  
                          </select>


                          <label class="custom_label">Topic</label>
                          <div style={{color:'red'}}>{this.state.debateTopicErr}</div>
                          <input class="custom_input" type="text" placeholder="" name="" onChange={e => this.setState({ debateTopic: e.target.value })}/>

                          <div class="row">
                          <div class="col-md-6">
                          <label class="custom_label">Date</label>
                          <div style={{color:'red'}}>{this.state.debateDateErr}</div>
                          <div class="date_input">
                            {/*<input class="custom_input" type="date" placeholder="" name="" onChange={e => this.setState({ debateDate: e.target.value })}/>*/}
                            <DatePicker
                                className="custom_input"
                                value={ moment(this.state.debateDate).format("MM-DD-YYYY") }  
                                // selected={ this.state.debateDate } 
                                onChange={ this.handleChange }
                                name="startDate"
                                minDate={moment().toDate()}
                                dateFormat="MM-DD-YYYY"

                            /> 
                            {/*<i class="fal fa-calendar"></i>*/}
                          </div> 
                          </div>
                          <div class="col-md-6">
                          <label class="custom_label">Time</label>
                          <div style={{color:'red'}}>{this.state.debateTimeErr}</div>
                          <div class="date_input">
                            {/*<input class="custom_input" type="time" placeholder="" name=""onChange={e => this.setState({ debateTime: e.target.value })}/>*/}
                            <TimePicker
                              style={{ width: 100 }}
                              showSecond={false}
                              defaultValue={moment()}
                              className="custom_input"
                              onChange={this.handleValueChange}
                              onAmPmChange={this.handleValueChange} 
                              name="debateTime"
                              format={format}
                              use12Hours
                            />
                          </div>
                          </div>
                          </div>
                          <div class="row">
                          <div class="col-md-6">
                          <label class="custom_label">Debate Duration</label>
                          <div style={{color:'red'}}>{this.state.debateDurationErr}</div>
                          {/*<input class="custom_input" type="text" placeholder="" name="" onChange={e => this.setState({ debateDuration: e.target.value })}/>*/}


                          <select class="custom_input" value={this.state.debateDuration} onChange={e => this.setState({ debateDuration: e.target.value })} >
                            <option value="">Select Duration</option>
                            <option value="10">10 Min</option>
                            <option value="20">20 Min</option>
                            <option value="30">30 Min</option>
                            <option value="40">40 Min</option>
                            <option value="50">50 Min</option>
                            <option value="60">60 Min</option>
                          </select>
                          </div>
                         <div class="col-md-6">
                          <label class="custom_label">Time-Zone</label> 
                          {/*<input class="custom_input" type="text" placeholder="" name="" onChange={e => this.setState({ debateDuration: e.target.value })}/>*/}
                          <div style={{color:'red'}}>{this.state.timezoneErr}</div>

                          <select class="custom_input" value={this.state.timezone} onChange={e => this.setState({ timezone: e.target.value })} >
                            <option value="">Select Timezone</option>
                            <option value="Asia/Kolkata"> (GMT 5.5) - Asia/Kolkata</option>
                            {/*<option value="Pacific/Honolulu">Hawaii Standard Time</option>*/}
                            <option value="America/Adak">Hawaii-Aleutian Daylight Time</option>
                            <option value="America/Anchorage">Alaska Daylight Time</option>
                            <option value="America/Los_Angeles">Pacific Daylight Time</option>
                            {/* <option value="Mountain Standard Time">Mountain Standard Time</option> */}
                            <option value="America/Edmonton">Mountain Daylight Time</option>
                            <option value="America/Chicago">Central Daylight Time</option>
                            <option value="America/Kentucky/Monticello">Eastern Daylight Time</option>
                            
                              {
                                  this.state.allTimeZone.map((row,i) =>(  
                                    <option value={row.timezone}>  (GMT {row.gmt_offset}) -  {row.timezone}</option>
                                  ))
                              }  
                              
                          </select>
                          </div>
                          </div>
                          <a class="submit_btn" onClick={this.handleJoin} disabled={!this.state.joinBtn}>Submit</a>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="messages" role="tabpanel">
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <NotificationContainer/>
      <Footer/>
      </>
    )
  }
}

  