import React from 'react'
import * as Cookies from 'js-cookie'

import '../../assets/fonts/css/icons.css'
import Validator from '../../utils/Validator'
import { RESOLUTION_ARR } from '../../utils/Settings'
import { API_BASE_URL } from "../constants/AppConstants";
const axios = require('axios').default;
const loginUsername= localStorage.getItem('loginUsername');
const loginUserProfile= localStorage.getItem('loginUserProfile');
class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      joinBtn: false,
      channel: '', 
      baseMode: '',
      transcode: '',
      attendeeMode: '',
      videoProfile: '',

    }
  }

   
  componentDidMount() {
     
    var token= localStorage.getItem('accessToken'); 
    var is_admin= localStorage.getItem('is_admin');
    console.log(token)
    if(token!==null && is_admin != 'yes' ){ 
      if(token=="null" || is_admin != 'yes'  ){
        // alert(window.location.hash);
        let url = window.location.hash;
        url = url.slice(0, url.lastIndexOf('/'));
        // url = url.slice(0, url.lastIndexOf('/'));
        if(url!="#/debate/start"){
          // alert(url)
          // http://localhost:3000/#/debate/start/FB6F7E-7F6B-18B35F
          window.location.hash = "login"
          //alert(localStorage.getItem('accessToken'));

        }
      } 
    } 
  }
 
  // logout(){
  logout = async () => {
    // alert('j');
    let data = await axios.post(API_BASE_URL+'twitter/users/logout');
    localStorage.setItem('accessToken',null); 
    localStorage.setItem('is_admin',null); 
    window.location.hash = "adminlogin"; 
  }
  render() {
    return (
    <div>
      { <header class="headerCol">
        <div class="container custom_container">
          <div class="row align-items-center">
            <div class="col-md-6">
              <div class="header_left">
                <a class="logo" href="#/dashboard-stats"><img src="assets/images/logo_w.png" alt=""/></a>
              </div>
            </div>
            <div class="col-md-6">
              <div class="header_right">

                <span class="profile_span"><img src={loginUserProfile} alt=""/><p>{loginUsername}</p></span>
                <span class="logout"><a href="javascript:void(0)" onClick={this.logout}>Logout</a></span>
              </div>

              
            </div>
          </div>
        </div>
      </header>}
      </div>
    )
  }
} 

export default Header