import React, { Component } from "react";
import * as Cookies from 'js-cookie'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import '../../assets/fonts/css/icons.css'
import Validator from '../../utils/Validator'
import { RESOLUTION_ARR } from '../../utils/Settings'
import Header from '../common/Header'
import Footer from '../common/Footer'
import Sidebar from '../common/Sidebar'
import { API_BASE_URL } from "../constants/AppConstants";
import TimePicker from 'react-time-picker';
import DatePicker from 'react-date-picker';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();
var moment = require('moment-timezone');
const axios = require('axios').default;
const loginUser= localStorage.getItem('loginUser')


export default class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      joinBtn: false,
      channel: '',
      baseMode: '',
      transcode: '',
      attendeeMode: '',
      videoProfile: '',
      debateDuration:'',
      debateTopic:'',
      debateDate:'',
      email:'', 
      twitterHandle:'',
      allDebates:[], 
      upcomingDebates:[],
      debateInvitations:[],
      debate_id :this.props.match.params.id,
      lat:'',
      long:'',shedule:'',
      timzon:'',
      is_ended:false,
      btn:'Notify me',
      col_md_12:'debate_left'
    }
    // this.debate_id = this.initialState;


    // this.allDebates = this.allDebates.bind(this);
    //this.allDebates = this.allDebates.bind(this);
  }
  

   
  
  componentDidMount() {

    if(loginUser){
    
      navigator.geolocation.getCurrentPosition( (position)=> {
        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);
        this.setState({
          lat:position.coords.latitude,
          long:position.coords.longitude,
        })
        
      });
    }


    this.getSingleDebate(this.state.debate_id);
  }
 
  getSingleDebate = async (id) => {
    let data = await axios.get(API_BASE_URL+'debates/'+id);
    let timezone = await axios.post(API_BASE_URL+'get-timezone/',{'lat':this.state.lat,'long':this.state.long});
     
    console.log(data.data.data)
    this.setState({
      allDebates: data.data.data 
    }); 

    var shedule= moment.utc(this.state.allDebates.utc_date_time).tz(this.state.allDebates.timezone).format('hh:mm A') 

    var timzon= this.state.allDebates.timezone=="America/Kentucky/Monticello"?"Eastern Daylight Time":this.state.allDebates.timezone
    //alert(shedule)
    var response=data.data.data;
    this.setState({
    shedule, timzon
    })
    const testDate=moment.tz(timezone.data.timezone).toDate();
    const curDate=moment.tz(testDate,timezone.data.timezone).format('YYYY-MM-DD');
    var currentTime = moment.tz(timezone.data.timezone).format('HH:mm:ss'); 
    const currentUtcDate=moment.utc().format("YYYY-MM-DD")
    

    var utcdattime=response.utc_date_time;
    var tt= utcdattime.split(' ');

    //alert(tt[0])
    if(currentUtcDate!=tt[0]){
      
      alert('You can not join this debate this at this moment');
      window.location.hash = "upcoming-debates";

    }else{  


        const curDate=moment.utc().format("YYYY-MM-DD")
        var currentTime = moment.utc().format("hh:mm:ss A") 

        let utc= moment(response.utc_date_time, 'YYYY-MM-DD hh:mm:ss A').format('YYYY-MM-DD hh:mm:ss a')
        let utc_time= utc.split(' ');
        let utcTime = moment(utc_time[1]+' '+utc_time[2], "h:mm:ss A").subtract(15, 'minutes').format("HH:mm:ss") 

        let utc_cure= moment(curDate+' '+currentTime, 'YYYY-MM-DD hh:mm:ss A').format('YYYY-MM-DD hh:mm:ss a')
        let utc_time_cur=utc_cure.split(' ');
        currentTime = moment(utc_time_cur[1]+' '+utc_time_cur[2], "h:mm:ss A").format("HH:mm:ss")
     
        //var date = moment.tz(timezone.data.timezone).add( 900, 'seconds').format('HH:mm:ss'); 
        var date2 = moment(response.utc_date_time,"HH:mm:ss").format('HH:mm:ss'); 
       
        //let utcTime = moment(utc_time[1]+' '+utc_time[2], "h:mm:ss A").format("HH:mm:ss") 
        //alert(utcTime)
        //alert(currentTime)
        if(currentTime> utcTime ){   
           
          this.setState({
            btn:"Ended" ,
            is_ended:true ,
            col_md_12:"col-md-12"
          })
        }

    }
 
  }
   
  

  inviteDebate = async () => {

    this.setState({
      btn:'Please wait..'
    })
    var id= this.state.debate_id
    var email= this.state.email;
    var twitterHandle= this.state.twitterHandle;
    let payload={'debate_id':id,'email':email,lat:this.state.lat,long:this.state.long};

    let data = await axios.post(API_BASE_URL+'debate/invite',payload);
    console.log(data)
    toast.success(data.data.message, {autoClose:3000})
    this.setState({
      btn:'Notify me'
    })
    setTimeout(() => {
      window.location.reload();
    }, 3000);
    
  }

  handleJoin = async () => {

   

    console.log(this.state)
    Cookies.set('channel', this.state.channel)
    Cookies.set('baseMode', this.state.baseMode)
    Cookies.set('transcode', this.state.transcode)
    Cookies.set('attendeeMode', this.state.attendeeMode)
    Cookies.set('videoProfile', this.state.videoProfile)
    var payload= {
      'debate_duration':this.state.debateDuration,
      'topic':this.state.debateTopic,
      'debate_date':this.state.debateDate,
      'debate_time':this.state.debateTime,
      'userId':1
    } 
    await axios.post(API_BASE_URL+'debate',payload).then(function (response) {
      //this.createNotification('success')
      alert('done');
      NotificationManager.success('', 'Debate has been created.');
    }).catch(function (error) {
      console.log(error);
    });
    //window.location.hash = "meeting"
  }
  render() {
    const {allDebates} = this.state;
    console.log(this.state.allDebates)
    console.log('allDebates')
    return (
      //const { id } = this.props.match.params;
      <>
      <Header/>
       <section class="debate_outer">
        <div class="container custom_container">
          <div class="row">
            <div class="col-md-12">
              <div class="debate_title">Debate Topic : {this.state.allDebates.topic}</div>
              <div class="debatetab_outer debate_topic_outer">
                <div class={this.state.col_md_12}>
                  <div class="new_deal">
                    <div class="green_title">{this.state.allDebates.topic}</div>
                    <p class="deal_p"><i class="far fa-calendar"></i> {moment(this.state.allDebates.debate_date).format("LL")}</p>
                    <p class="deal_p"><i class="fal fa-clock"></i> 
                    {this.state.shedule} @ {this.state.timzon}
                    </p>
                    <br/>
                    <br/>
                    <h3>Watch debate here:</h3>
                      <a  href={this.state.allDebates.debate_public_link}>
                        <div class="green_title">
                          {this.state.allDebates.debate_public_link}
                        </div>
                      </a>
                     
                  </div>
                </div>

                <div class="debate_right debate_topic_right">
                  {this.state.is_ended==false && 
                  <div>
                  <div class="login_title">Enter your email to get a reminder on the day of the debate !</div>
                  <label class="custom_label">Email</label>
                  <div class="date_input">
                  <input class="custom_input" type="text" placeholder="Email" value={this.email} onChange={e => this.setState({ email: e.target.value })} name=""/>
                  <i class="fal fa-envelope"></i>

                  {/*<label class="custom_label">Twitter handle</label>
                  <div class="date_input">
                  <input class="custom_input" type="text" placeholder="Twitter handle" value={this.twitterHandle} onChange={e => this.setState({ twitterHandle: e.target.value })} name=""/>
                  <i class="fal fa-envelope"></i>*/}


                  <input class="custom_input" type="button" onClick={this.inviteDebate} value={this.state.btn} name="invite"/>
                  </div>
                  </div>
                  }

                   

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <NotificationContainer/>
      <Footer/>
      </>
    )
  }
}

