import React, { Component } from "react";
import { merge } from "lodash";
import AgoraRTC from "agora-rtc-sdk";
import { HashRouter as Router, Route , useParams,useLocation } from 'react-router-dom'
import { API_BASE_URL,BASE_URL , SOCKET_URL } from "../../pages/constants/AppConstants";
import { AGORA_APP_ID } from "../../agora.config";
import "./canvas.css";
import "../../assets/fonts/css/icons.css";
import queryString from 'query-string';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import $ from 'jquery';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
 
import io from "socket.io-client"; 
import { useHistory } from "react-router-dom";
 import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
var moment = require('moment-timezone');
const axios = require('axios').default;
const loginUser= localStorage.getItem('loginUser')
const myTimeZone= localStorage.getItem('myTimeZone')
const twitterHandle= localStorage.getItem('twitterHandle')

let liveCount=[];
const socketUrl = SOCKET_URL;
var globalSocket;
toast.configure();


const tile_canvas = {
  "1": ["span 12/span 24"],
  "2": ["span 12/span 12/13/25", "span 12/span 12/13/13"],
  "3": ["span 12/span 12","span 12/span 6", "span 6/span 4"],
  "4": [
    "span 6/span 12",
    "span 6/span 12",
    "span 6/span 12",
    "span 6/span 12/7/13",
  ],
  "5": [
    "span 3/span 4/13/9",
    "span 3/span 4/13/13",
    "span 3/span 4/13/17",
    "span 3/span 4/13/21",
    "span 9/span 16/10/21",
  ],
  "6": [
    "span 3/span 4/13/7",
    "span 3/span 4/13/11",
    "span 3/span 4/13/15",
    "span 3/span 4/13/19",
    "span 3/span 4/13/23",
    "span 9/span 16/10/21",
  ],
  "7": [
    "span 3/span 4/13/5",
    "span 3/span 4/13/9",
    "span 3/span 4/13/13",
    "span 3/span 4/13/17",
    "span 3/span 4/13/21",
    "span 3/span 4/13/25",
    "span 9/span 16/10/21",
  ],
};

/**
 * @prop appId uid
 * @prop transcode attendeeMode videoProfile channel baseMode debateID
 */
const USER_ID = Math.floor(Math.random() * 1000000001);
const APP_ID = AGORA_APP_ID;
class AgoraCanvas extends React.Component {

  localsStream = AgoraRTC.createStream({
    streamID: USER_ID,
    audio: true,
    video: true,
    screen: false
  });

  constructor(props) {
    super(props);
    this.client = {};
    this.localStream = {};
    this.shareClient = {};
    this.shareStream = {};
    
    this.state = {
      endpoint: 'http://localhost:3001/',
      live_users:0,
      displayMode: "tile",
      streamList: [],
      audienceStreamList: [],
      remoteStreamList:[],
      remoteStreams: [],
      localStreamArr: [],
      remoteStreamArr: [],
      remoteStreamArrObj:[],
      shareScreenArr: [],
      readyState: false,
      stateSharing: false,
      organizer:"",
      control_enabled_to:"",
      show_remote_image:"deal_video hide",
      introduction_1:0,
      introduction_2:0,
      argument_1:0,
      argument_2:0,
      discussion:0,  
      final_thoughts_1:0,
      final_thoughts_2:0,
      time: {}, 
      time_wait:{},
      seconds: 0,
      seconds_wait:0,
      debate_detail:{},
      remote_col:"col-md-6 thumbnail_cols",
      remote_div_inner :'video_deal_thumbnaila deal_video',
      enableShareScreen:"debate_left",
      show_challenger:true,
      reload_page:false,
      show_debater:false,
      video_icon:"fal fa-video",
      mike_icon:"fal fa-microphone",
      share_icon:"fal fa-share-square",
      debate_id:this.props.debateID,
      invitee : this.props.invitee,
      orgId : this.props.orgId,
      debate_control:1,
      currentActiveSession: [],
      showScreen:true,
      oponentProfilePic:'',
      oponent:'',
      hiddenvalue:"asdas",
      profilePic:'',
      cameraclassshow:"deal_video cameraclassshow", 
      cameraclasshide:"deal_video cameraclasshide",
      debateEnded:false,
      debate_utc_date_time:0,
      currentActive:'',
      resourceId:'',
      sid:'',
      channel:'',
      sendingUid:'',
      active_local:'',
      active_remote:'',
      challangerTwitter:'',
      debaterTwitter:'',
      muteStatus:'unmute',
      videomuteStatus:'unmute',
      showRemoteMute:false,
      remote_image:"",
      isAudience:(this.props.attendeeMode=='audience')?true:false,
      show_challenger_id:"",
      show_debater_id:"",
      debater_id:"",
      challenger_id:""
    };

    // const search = this.props.location.search;
    // const name = new URLSearchParams(search).get("name");
    
    //alert(this.props.isRealAudience)
    this.timer = 0;
    this.waiting_timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.startWaitingTimer = this.startWaitingTimer.bind(this);
    this.waitingcountDown = this.waitingcountDown.bind(this);
    this.toggleAccess=this.toggleAccess.bind(this);
  }    
  // simulateClick(e) {
  //   setTimeout(function() {

  //     //e.click()
  //   }, 2000);
  // }

 
  secondsToTime(secs){
    let hours = Math.floor(secs / (60 * 60));
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);
    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    if(seconds < 10 ) {
      seconds = '0' + seconds;
    } 
    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }

  startTimer() {
      if (this.timer == 0 && this.state.seconds > 0) {
        this.timer = setInterval(this.countDown, 1000);

      }else{

      }
  }

  startWaitingTimer() {
      if (this.waiting_timer == 0 && this.state.seconds_wait > 0) {
        this.waiting_timer = setInterval(this.waitingcountDown, 1000);
      }else{

      }
  }

  waitingcountDown() {
    // Remove one second, set state so a re-render happens.
    
    let seconds_wait = this.state.seconds_wait - 1;
    this.setState({
      time_wait: this.secondsToTime(seconds_wait),
      seconds_wait: seconds_wait,
    });

    if (this.props.onChange) {
      this.props.onChange(this.state.time_wait);
    }
    
    // Check if we're at zero.
    if (seconds_wait <= 0 ) { 

      if(this.state.reload_page==false){

        this.setState({reload_page:true})
        // alert('Debate is going to be start')

        toast.success('Debate has been started.', {autoClose:3000});
      }
      //window.location.reload();
      this.implimentDebateRules(this.props.debateID)
    }
  }

  async toggleAccess(e){
      // toggleAccess = async (e) => {

      // alert()
      //alert(this.state.current_rule);
      if(this.state.stateSharing==true){
        toast.warning('Please close screen sharing.', {autoClose:3000});
        return;
      }

      if(this.localStream.isAudioOn()==false){
        toast.warning('Please unmute your mic first.', {autoClose:3000});
        return;
      }
      



      //alert(this.state.control_enabled_to)
      if( this.state.debate_detail.userId ==  loginUser){

          var payload= {
            'debate_control':this.state.debate_detail.debate_with,
          }
        
            this.setState({
            debate_control:this.state.debate_detail.debate_with,
      

          })
      }else{
        
        var payload= {
          'debate_control':this.state.debate_detail.userId,
        }
        
        this.setState({
          debate_control:this.state.debate_detail.userId,
        })

      }
        

      await globalSocket.emit( 'subtract_time', {current_rule:this.state.current_rule,seconds:this.state.seconds} )
      // await axios.put(API_BASE_URL+'debates/'+this.state.debate_detail.id, payload).then(function (response) {
      //   console.log(response);


      // });

     
  };
  async countDown() {
      // Remove one second, set state so a re-render happens.
    
      let seconds = this.state.seconds - 1;
      this.setState({
        time: this.secondsToTime(seconds),
        seconds: seconds,
      });

      if (this.props.onChangeState) {
        // alert(this.state.currentActive)
        this.props.onChangeState(this.state.currentActive);
      }
      // alert(seconds)
      // Check if we're at zero.
      if (seconds <= 0 ) { 
        //clearInterval(this.timer);
        
        if(this.state.control_enabled_to==this.state.organizer){
          //alert(this.state.debate_detail.id);
          //let dtime=utcTime;
          //var date = moment(dtime,"HH:mm:ss").add(this.state.introduction, 'seconds').format('HH:mm:ss');
          // this.startTimer()
        }
        //let currentTime = moment().format('HH:mm:ss'); 
        //const testDate=moment.tz(myTimeZone).toDate();
        //const curDate=moment.tz(testDate,myTimeZone).format('YYYY-MM-DD');

        const testDate=moment.tz(myTimeZone).toDate();
        const curDate=moment.tz(testDate,myTimeZone).format('YYYY-MM-DD'); 
        let currentTime = moment.utc().format("hh:mm:ss");
        const currentUtcDate=moment.utc().format("YYYY-MM-DD")

          
        // let currentTime = moment.tz(myTimeZone).format('HH:mm:ss'); 
        let utc= moment(this.state.debate_detail.utc_date_time, 'YYYY-MM-DD hh:mm:ss A').format('YYYY-MM-DD hh:mm:ss a')
        let utc_time=utc.split(' ');
        let utcTime = moment(utc_time[1]+' '+utc_time[2], "h:mm:ss A").format("HH:mm:ss")  

        var utc_currentTime = moment.utc().format("hh:mm:ss A") 
        let utc_cure= moment(currentUtcDate+' '+utc_currentTime, 'YYYY-MM-DD hh:mm:ss A').format('YYYY-MM-DD hh:mm:ss a')
        let utc_time_cur=utc_cure.split(' ');
        utc_currentTime = moment(utc_time_cur[1]+' '+utc_time_cur[2], "h:mm:ss A").format("HH:mm:ss")

        //alert(utc_currentTime)
        var intro1 = moment(utcTime,"HH:mm:ss").add(this.state.introduction_1, 'seconds').format('HH:mm:ss');
        if(utc_currentTime>=intro1){
          var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2);
          var intro2 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
          
          if(utc_currentTime>=intro2){
            var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1);
            var argu1 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
            // alert('hi');
            if(utc_currentTime>argu1){
              var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1)+parseInt(this.state.argument_2);
              var argu2 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
              
              if(utc_currentTime>argu2){

                var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1)+parseInt(this.state.argument_2)+parseInt(this.state.discussion);
                var discussion = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                if(utc_currentTime>discussion){

                  var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1)+parseInt(this.state.argument_2)+parseInt(this.state.discussion)+parseInt(this.state.final_thoughts_1);
                  var final_thoughts_1 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                  if(utc_currentTime>final_thoughts_1){

                    var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1)+parseInt(this.state.argument_2)+parseInt(this.state.discussion)+parseInt(this.state.final_thoughts_1)+parseInt(this.state.final_thoughts_2);
                    var final_thoughts_2 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                    if(utc_currentTime>final_thoughts_2){

                    this.setState({debateEnded:true})
                      // window.location.hash = "upcoming-debates";
                      var stoped= await this.stopRecordingForceFUlly()
                      //alert('hiii')
                      globalSocket.emit( 'forcefully_end', {debate_id:this.props.debateID,msg:'Debater has been left',user:loginUser} ) 
                      setTimeout(function() {

                        //alert('redirect')
                        window.location.assign(BASE_URL)
                      }, 1000);
                      if(stoped==true){
                        
                      }
                    }else{

                      this.setState({
                        show_challenger:false,
                        show_debater:true,
                        control_enabled_to:this.state.debate_detail.debate_with,
                        current_rule:'final_thoughts_2'
                      });
                      await globalSocket.emit( 'change_control', {debate_id:this.state.debate_detail.id,debate_control:this.state.control_enabled_to} )
                      
                      var startTime = moment(utc_currentTime, 'hh:mm:ss');
                      var endTime = moment(final_thoughts_2, 'hh:mm:ss');
                      var secondsDiff = endTime.diff(startTime, 'seconds');
                      this.setState({seconds:secondsDiff,currentActive:'final_thoughts_2'});
                      if(this.state.stateSharing==true){
                        this.shareClient && this.shareClient.unpublish(this.shareStream);
                      }
                      this.startTimer()
                      if (this.props.onChangeState) {
                        this.props.onChangeState(this.state.currentActive);
                      }
                      setTimeout( () => { 

                        if(this.state.control_enabled_to!=loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                          if(this.localStream.isAudioOn()==true){
                            this.handleMic();
                          }
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                         
                      }, 2000);
                    }
                     
                  }else{

                    this.setState({
                      show_challenger:true,
                      show_debater:false,
                      control_enabled_to:this.state.debate_detail.userId,
                      current_rule:'final_thoughts_1',
                    });
                    await globalSocket.emit( 'change_control', {debate_id:this.state.debate_detail.id,debate_control:this.state.control_enabled_to} )
                  
                    var startTime = moment(utc_currentTime, 'hh:mm:ss');
                    var endTime = moment(final_thoughts_1, 'hh:mm:ss');
                    var secondsDiff = endTime.diff(startTime, 'seconds');
                    this.setState({seconds:secondsDiff,currentActive:'final_thoughts_1'});
                    if(this.state.stateSharing==true){
                      this.shareClient && this.shareClient.unpublish(this.shareStream);
                    }
                    this.startTimer()
                    if (this.props.onChangeState) {
                      this.props.onChangeState(this.state.currentActive);
                    }
                    setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                          if(this.localStream.isAudioOn()==true){
                            this.handleMic();
                          }
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                        // if(this.state.control_enabled_to !== loginUser){
                        //   if(this.localStream.isAudioOn() == true){
                        //     this.localStream.disableAudio()
                        //   }
                        // }else{
                        //   if(this.localStream.isAudioOn() == false){
                        //     this.localStream.enableAudio()
                        //   }
                        // }
                      }, 2000);
                  }  
                }else{

                  this.setState({
                    show_challenger:true,
                    show_debater:true,
                    control_enabled_to:"",
                    current_rule:'discussion',
                  });
                  // await globalSocket.emit( 'change_control', {debate_id:this.state.debate_detail.id,debate_control:this.state.control_enabled_to} )
                  var startTime = moment(utc_currentTime, 'hh:mm:ss');
                  var endTime = moment(discussion, 'hh:mm:ss');
                  var secondsDiff = endTime.diff(startTime, 'seconds');
                  // alert(secondsDiff);
                  this.setState({seconds:secondsDiff,currentActive:'discussion'});
                  if(this.state.stateSharing==true){
                    this.shareClient && this.shareClient.unpublish(this.shareStream);
                  }
                  this.startTimer()
                  if (this.props.onChangeState) {
                    this.props.onChangeState(this.state.currentActive);
                  }
                  setTimeout( () => { 

                        // if(loginUser==this.state.debate_detail.debate_with){
                          
                        //   console.log(this.localStream.isAudioOn())
                        //   console.log('this.localStream.isAudioOn()')
                           
                        //   if(this.localStream.isAudioOn()==false){ 
                        //     this.handleMic(); 
                        //   }
                        // }
                        if(this.localStream.isAudioOn()==false){ 
                          this.handleMic(); 
                        }
                        // if(loginUser==this.state.debate_detail.userId){ 
                        //   this.handleMic(); 
                        // }

                        
                      
                      }, 2000);
                } 
              }else{

                this.setState({
                  show_challenger:false,
                  show_debater:true,
                  control_enabled_to:this.state.debate_detail.debate_with,
                  current_rule:'argu2'
                });
                await globalSocket.emit( 'change_control', {debate_id:this.state.debate_detail.id,debate_control:this.state.control_enabled_to} )
              
                var startTime = moment(utc_currentTime, 'hh:mm:ss');
                var endTime = moment(argu2, 'hh:mm:ss');
                var secondsDiff = endTime.diff(startTime, 'seconds'); 
                this.setState({seconds:secondsDiff,currentActive:'argu2'});
                if(this.state.stateSharing==true){
                  this.shareClient && this.shareClient.unpublish(this.shareStream);
                }
                this.startTimer()
                if (this.props.onChangeState) {
                  this.props.onChangeState(this.state.currentActive);
                }
                setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                          if(this.localStream.isAudioOn()==true){
                            this.handleMic();
                          }
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                        // if(this.state.control_enabled_to !== loginUser){
                        //   if(this.localStream.isAudioOn() == true){
                        //     this.localStream.disableAudio()
                        //   }
                        // }else{
                        //   if(this.localStream.isAudioOn() == false){
                        //     this.localStream.enableAudio()
                        //   }
                        // }
                      }, 2000);
                // alert(secondsDiff);
              }
            }else{ 
              this.setState({
                show_challenger:true,
                show_debater:false,
                control_enabled_to:this.state.debate_detail.userId,
                current_rule:'argu1'
              });
              await globalSocket.emit( 'change_control', {debate_id:this.state.debate_detail.id,debate_control:this.state.control_enabled_to} )
            
              var startTime = moment(utc_currentTime, 'hh:mm:ss');
              var endTime = moment(argu1, 'hh:mm:ss');
              var secondsDiff = endTime.diff(startTime, 'seconds'); 
              this.setState({seconds:secondsDiff,currentActive:'argu1'});
              if(this.state.stateSharing==true){
                this.shareClient && this.shareClient.unpublish(this.shareStream);
              }
              this.startTimer()
              if (this.props.onChangeState) {
                this.props.onChangeState(this.state.currentActive);
              }
              setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                          if(this.localStream.isAudioOn()==true){
                            this.handleMic();
                          }
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                        // if(this.state.control_enabled_to !== loginUser){
                        //   if(this.localStream.isAudioOn() == true){
                        //     this.localStream.disableAudio()
                        //   }
                        // }else{
                        //   if(this.localStream.isAudioOn() == false){
                        //     this.localStream.enableAudio()
                        //   }
                        // }
                      }, 2000);
            }
          }else{
            this.setState({
              show_challenger:false,
              show_debater:true,
              control_enabled_to:this.state.debate_detail.debate_with,
              current_rule:'intro2'
            });
            await globalSocket.emit( 'change_control', {debate_id:this.state.debate_detail.id,debate_control:this.state.control_enabled_to} )
          
            var startTime = moment(utc_currentTime, 'hh:mm:ss');
            var endTime = moment(intro2, 'hh:mm:ss');
            var secondsDiff = endTime.diff(startTime, 'seconds');
            
            this.setState({seconds:secondsDiff,currentActive:'intro2'});
            if(this.state.stateSharing==true){
              this.shareClient && this.shareClient.unpublish(this.shareStream);
            }
            this.startTimer()
            if (this.props.onChangeState) {
              this.props.onChangeState(this.state.currentActive);
            }

            setTimeout( () => { 
                   console.log('this.state.control_enable_to',this.state.control_enabled_to)
                        if(this.state.control_enabled_to!=loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                          if(this.localStream.isAudioOn()==true){
                            this.handleMic();
                          }
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }

                        // if(this.state.control_enabled_to !== loginUser){
                        //   if(this.localStream.isAudioOn() == true){
                        //     this.localStream.disableAudio()
                        //   }
                        // }else{
                        //   if(this.localStream.isAudioOn() == false){
                        //     this.localStream.enableAudio()
                        //   }
                        // }
                      }, 2000);
            
          }

        }else{
          this.setState({
            show_challenger:true,
            show_debater:false,
            control_enabled_to:this.state.debate_detail.userId,
            current_rule:'intro1',
            
          });
          await globalSocket.emit( 'change_control', {debate_id:this.state.debate_detail.id,debate_control:this.state.control_enabled_to} )
        
          var startTime = moment(utc_currentTime, 'hh:mm:ss');
          var endTime = moment(intro1, 'hh:mm:ss');
          var secondsDiff = endTime.diff(startTime, 'seconds'); 
          this.setState({seconds:secondsDiff,currentActive:'intro1'});
          if (this.props.onChangeState) {
            this.props.onChangeState(this.state.currentActive);
          }
            
          // if(this.state.control_enabled_to !== loginUser){
          //   if(this.localStream.isAudioOn() == true){
          //     this.localStream.disableAudio()
          //   }
          // }else{
          //   if(this.localStream.isAudioOn() == false){
          //     this.localStream.enableAudio()
          //   }
          // }
          
          
          this.startTimer()
          if(this.state.control_enabled_to!=loginUser){
            this.handleMic();
          }
         
          
        }
      }
  }

  
  async implimentDebateRules(id) {
    let res = await axios.get(API_BASE_URL+'debate/public/'+id);
    let response=res.data.data;
    this.setState({
      debate_utc_date_time:response.utc_date_time,
      debater_id:res.data.debater_id,
      challenger_id:res.data.challenger_id,
      live_users:response.live_users,
    })
    //alert(response.live_users);
    //this.setState({live_users:0})
    let currentTime = moment.tz(myTimeZone).format('HH:mm:ss'); 
    
    // this.setState({ debate_control: response.userId });
    
    // const intervalId = setInterval(() => { 
    //   // 'this' is now the window instead of the component? Even though arrow
    //   this.load();
    //   if( response.debate_control == loginUser )
    //   {       
    //     this.setState({ debate_control: res.data.data.debate_control }); 
    //   }
    // }, 5000)


    const curDate=moment.utc().format("YYYY-MM-DD")
    let utc= moment(response.utc_date_time, 'YYYY-MM-DD hh:mm:ss').format('YYYY-MM-DD hh:mm:ss')
    let utc_time=utc.split(' ');
    // alert(utc)
    let utcTime = moment(utc_time[1]+' '+utc_time[2], "h:mm:ss").format("HH:mm:ss") 
    
    // alert(utcTime)

    var utc_currentTime = moment.utc().format("hh:mm:ss A") 
    let utc_cure= moment(curDate+' '+utc_currentTime, 'YYYY-MM-DD hh:mm:ss').format('YYYY-MM-DD hh:mm:ss')
    let utc_time_cur=utc_cure.split(' ');
    utc_currentTime = moment(utc_time_cur[1]+' '+utc_time_cur[2], "h:mm:ss").format("HH:mm:ss")

    var startTime = moment(utc_currentTime, 'hh:mm:ss');
    var endTime = moment(utcTime, 'hh:mm:ss');
    // alert(startTime)
    // alert(endTime)
    var secondsDiff = endTime.diff(startTime, 'seconds');
    //  alert(secondsDiff)
    if(secondsDiff<=0){
      if(response){
          if(this.props.attendeeMode=='video'){
            axios.post(API_BASE_URL+ 'debate-joined',{debate_id:this.props.debateID,loginUser})
          }
        this.setState({ organizer: response.userId });
        this.setState({ debater: response.debate_with });
        this.setState({ debate_detail: response });
        this.setState({ debate_control: response.debate_control });
         
 
        const testDate=moment.tz(myTimeZone).toDate();
      
        const curDate=moment.tz(testDate,myTimeZone).format('YYYY-MM-DD'); 
        let currentTime = moment.utc().format("hh:mm:ss");
    
        const currentUtcDate=moment.utc().format("YYYY-MM-DD")
        

        let utc= moment(response.utc_date_time, 'YYYY-MM-DD hh:mm:ss A').format('YYYY-MM-DD hh:mm:ss a')
        
        let utc_time=utc.split(' ');
      
        let utcTime = moment(utc_time[1]+' '+utc_time[2], "h:mm:ss A").format("HH:mm:ss")  
         
        var utc_currentTime = moment.utc().format("hh:mm:ss A") 
        
        let utc_cure= moment(currentUtcDate+' '+utc_currentTime, 'YYYY-MM-DD hh:mm:ss A').format('YYYY-MM-DD hh:mm:ss a')
        let utc_time_cur=utc_cure.split(' ');
        utc_currentTime = moment(utc_time_cur[1]+' '+utc_time_cur[2], "h:mm:ss A").format("HH:mm:ss")
        

        if(currentUtcDate==response.debate_date){
         
          if(utc_currentTime>=utcTime){
            if(localStorage.getItem('debate_rules')==null){
              var getRules=[]
            }else{


              var getRules = JSON.parse(localStorage.getItem('debate_rules'));
              if(getRules.debate_id==this.props.debateID){
              
                getRules = JSON.parse(localStorage.getItem('debate_rules'));

              }else{
                var getRules=[]
              }
            }
            if(response.debate_duration=='10'){
              
              

              this.setState({
                introduction_1:(getRules.intro1)?getRules.intro1:30,
                introduction_2:(getRules.intro_2)?getRules.intro_2:30,
                argument_1:(getRules.argu1)?getRules.argu1:180,
                argument_2:(getRules.argu2)?getRules.argu2:180,
                discussion:(getRules.discussion)?getRules.discussion:180,
                final_thoughts_1:(getRules.final_thoughts_1)?getRules.final_thoughts_1:0,
                final_thoughts_2:(getRules.final_thoughts_2)?getRules.final_thoughts_2:0,
                control_enabled_to:response.userId
              }) 
  
              var intro1 = moment(utcTime,"HH:mm:ss").add(this.state.introduction_1, 'seconds').format('HH:mm:ss'); 
              if(utc_currentTime>intro1){
                var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2);
                var intro2 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                if(utc_currentTime>intro2){
                  var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1);
                  var argu1 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                  if(utc_currentTime>argu1){
                    var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1)+parseInt(this.state.argument_2);
                    var argu2 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                    if(utc_currentTime>argu2){
                      var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1)+parseInt(this.state.argument_2)+parseInt(this.state.discussion);
                      var discussion = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                      
                      if(utc_currentTime>discussion){

                        var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1)+parseInt(this.state.argument_2)+parseInt(this.state.discussion)+parseInt(this.state.final_thoughts_1);
                        var final_thoughts_1 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                        if(utc_currentTime>final_thoughts_1){

                          var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1)+parseInt(this.state.argument_2)+parseInt(this.state.discussion)+parseInt(this.state.final_thoughts_1)+parseInt(this.state.final_thoughts_2);
                          var final_thoughts_2 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                          if(utc_currentTime>final_thoughts_2){

                            alert('debate ended');
                            window.location.hash = "upcoming-debates";
                          }else{
                           
                            this.setState({
                              show_challenger:false,
                              show_debater:true,
                              control_enabled_to:this.state.debate_detail.debate_with,
                              current_rule:"final_thoughts_2"
                            });
                            var startTime = moment(utc_currentTime, 'hh:mm:ss');
                            var endTime = moment(final_thoughts_2, 'hh:mm:ss');
                            var secondsDiff = endTime.diff(startTime, 'seconds');
                            this.setState({seconds:secondsDiff,currentActive:'final_thoughts_2'});
                            this.startTimer()
                            setTimeout( () => { 
                              if(this.state.control_enabled_to!=loginUser){
                                this.handleMic();
                              }
                              if(this.state.control_enabled_to==loginUser){
                                if(this.localStream.isAudioOn()==false){
                                  this.handleMic();
                                }
                              } 
                              if(this.state.control_enabled_to==""){
                                this.localStream.enableAudio();
                              }
                            }, 5000);
                          }
                           
                        }else{

                          this.setState({
                            show_challenger:true,
                            show_debater:false,
                            control_enabled_to:this.state.debate_detail.userId,
                            current_rule:"final_thoughts_1"
                          });
                          var startTime = moment(utc_currentTime, 'hh:mm:ss');
                          var endTime = moment(final_thoughts_1, 'hh:mm:ss');
                          var secondsDiff = endTime.diff(startTime, 'seconds');
                          this.setState({seconds:secondsDiff,currentActive:'final_thoughts_1'});
                          this.startTimer()
                          setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          this.handleMic();
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                      }, 5000);
                        }  
                      }else{

                        this.setState({
                          show_challenger:true,
                          show_debater:true,
                          control_enabled_to:"",
                          current_rule:"discussion"
                        });
                        var startTime = moment(utc_currentTime, 'hh:mm:ss');
                        var endTime = moment(discussion, 'hh:mm:ss');
                        var secondsDiff = endTime.diff(startTime, 'seconds');
                        // alert(secondsDiff);
                        this.setState({seconds:secondsDiff,currentActive:'discussion'});
                        this.startTimer()
                        setTimeout( () => { 
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                      }, 5000);


                      } 
                    }else{

                      this.setState({
                        show_challenger:false,
                        show_debater:true,
                        control_enabled_to:this.state.debate_detail.debate_with,
                        current_rule:"argu2"
                      });
                      var startTime = moment(utc_currentTime, 'hh:mm:ss');
                      var endTime = moment(argu2, 'hh:mm:ss');
                      var secondsDiff = endTime.diff(startTime, 'seconds');
                      // alert(secondsDiff);
                      this.setState({seconds:secondsDiff,currentActive:'argu2'});
                      this.startTimer()
                      setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          this.handleMic();
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                      }, 5000);
                    } 

                  }else{
                    // alert('arhument1')
                    this.setState({
                      show_challenger:true,
                      show_debater:false,
                      control_enabled_to:this.state.debate_detail.userId,
                      current_rule:"argu1"
                    });
                    var startTime = moment(utc_currentTime, 'hh:mm:ss');
                    var endTime = moment(argu1, 'hh:mm:ss');
                    var secondsDiff = endTime.diff(startTime, 'seconds');
                    // alert(secondsDiff);
                    this.setState({seconds:secondsDiff,currentActive:'argu1'});
                    this.startTimer()
                    setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          this.handleMic();
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                      }, 5000);
                  }
                }else{
                  this.setState({
                    show_challenger:false,
                    show_debater:true,
                    control_enabled_to:this.state.debate_detail.debate_with,
                    current_rule:"intro2"
                  });
                  var startTime = moment(utc_currentTime, 'hh:mm:ss');
                  var endTime = moment(intro2, 'hh:mm:ss');
                  var secondsDiff = endTime.diff(startTime, 'seconds');
                  this.setState({seconds:secondsDiff,currentActive:'intro2'});
                  this.startTimer()
                  setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          this.handleMic();
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                      }, 5000);
                }

              }else{

                this.setState({
                  show_challenger:true,
                  show_debater:false,
                  control_enabled_to:this.state.debate_detail.userId,
                  current_rule:"intro1"
                });
                var startTime = moment(utc_currentTime, 'hh:mm:ss');
                var endTime = moment(intro1, 'hh:mm:ss');
                var secondsDiff = endTime.diff(startTime, 'seconds'); 
                this.setState({seconds:secondsDiff,currentActive:'intro1'});
                this.startTimer()
                setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          this.handleMic();
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                      }, 5000);
              }
            }else if(response.debate_duration=='20'){
              this.setState({
                introduction_1:(getRules.intro1)?getRules.intro1:30,
                introduction_2:(getRules.intro_2)?getRules.intro_2:30,
                argument_1:(getRules.argu1)?getRules.argu1:300,
                argument_2:(getRules.argu2)?getRules.argu2:300,
                discussion:(getRules.discussion)?getRules.discussion:300,
                final_thoughts_1:(getRules.final_thoughts_1)?getRules.final_thoughts_1:120,
                final_thoughts_2:(getRules.final_thoughts_2)?getRules.final_thoughts_2:120,
                control_enabled_to:response.userId
              })
              var intro1 = moment(utcTime,"HH:mm:ss").add(this.state.introduction_1, 'seconds').format('HH:mm:ss'); 
              if(utc_currentTime>intro1){
                var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2);
                var intro2 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                if(utc_currentTime>intro2){
                  var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1);
                  var argu1 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                  if(utc_currentTime>argu1){
                    var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1)+parseInt(this.state.argument_2);
                    var argu2 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                    if(utc_currentTime>argu2){
                      var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1)+parseInt(this.state.argument_2)+parseInt(this.state.discussion);
                      var discussion = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                      
                      if(utc_currentTime>discussion){

                        var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1)+parseInt(this.state.argument_2)+parseInt(this.state.discussion)+parseInt(this.state.final_thoughts_1);
                        var final_thoughts_1 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                        if(utc_currentTime>final_thoughts_1){

                          var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1)+parseInt(this.state.argument_2)+parseInt(this.state.discussion)+parseInt(this.state.final_thoughts_1)+parseInt(this.state.final_thoughts_2);
                          var final_thoughts_2 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                          if(utc_currentTime>final_thoughts_2){

                           // alert('debate ended');
                            // window.location.hash = "upcoming-debates";
                          }else{
                           
                            this.setState({
                              show_challenger:false,
                              show_debater:true,
                              control_enabled_to:this.state.debate_detail.debate_with,
                              current_rule:"final_thoughts_2"
                            });
                            var startTime = moment(utc_currentTime, 'hh:mm:ss');
                            var endTime = moment(final_thoughts_2, 'hh:mm:ss');
                            var secondsDiff = endTime.diff(startTime, 'seconds');
                            this.setState({seconds:secondsDiff,currentActive:'final_thoughts_2'});
                            this.startTimer()
                            setTimeout( () => { 
                              if(this.state.control_enabled_to!=loginUser){
                                this.handleMic();
                              }
                              if(this.state.control_enabled_to==loginUser){
                                if(this.localStream.isAudioOn()==false){
                                  this.handleMic();
                                }
                              } 
                              if(this.state.control_enabled_to==""){
                                this.localStream.enableAudio();
                              }
                            }, 5000);
                          }
                           
                        }else{

                          this.setState({
                            show_challenger:true,
                            show_debater:false,
                            control_enabled_to:this.state.debate_detail.userId,
                            current_rule:"final_thoughts_1"
                          });
                          var startTime = moment(utc_currentTime, 'hh:mm:ss');
                          var endTime = moment(final_thoughts_1, 'hh:mm:ss');
                          var secondsDiff = endTime.diff(startTime, 'seconds');
                          this.setState({seconds:secondsDiff,currentActive:'final_thoughts_1'});
                          this.startTimer()
                          setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          this.handleMic();
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                      }, 5000);
                        }  
                      }else{

                        this.setState({
                          show_challenger:true,
                          show_debater:true,
                          control_enabled_to:"",
                          current_rule:"discussion"
                        });
                        var startTime = moment(utc_currentTime, 'hh:mm:ss');
                        var endTime = moment(discussion, 'hh:mm:ss');
                        var secondsDiff = endTime.diff(startTime, 'seconds');
                        // alert(secondsDiff);
                        this.setState({seconds:secondsDiff,currentActive:'discussion'});
                        this.startTimer()
                        setTimeout( () => { 
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                      }, 5000);


                      } 
                    }else{

                      this.setState({
                        show_challenger:false,
                        show_debater:true,
                        control_enabled_to:this.state.debate_detail.debate_with,
                        current_rule:"argu2"
                      });
                      var startTime = moment(utc_currentTime, 'hh:mm:ss');
                      var endTime = moment(argu2, 'hh:mm:ss');
                      var secondsDiff = endTime.diff(startTime, 'seconds');
                      // alert(secondsDiff);
                      this.setState({seconds:secondsDiff,currentActive:'argu2'});
                      this.startTimer()
                      setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          this.handleMic();
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                      }, 5000);
                    } 

                  }else{
                    // alert('arhument1')
                    this.setState({
                      show_challenger:true,
                      show_debater:false,
                      control_enabled_to:this.state.debate_detail.userId,
                      current_rule:"argu1"
                    });
                    var startTime = moment(utc_currentTime, 'hh:mm:ss');
                    var endTime = moment(argu1, 'hh:mm:ss');
                    var secondsDiff = endTime.diff(startTime, 'seconds');
                    // alert(secondsDiff);
                    this.setState({seconds:secondsDiff,currentActive:'argu1'});
                    this.startTimer()
                    setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          this.handleMic();
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                      }, 5000);
                  }
                }else{
                  this.setState({
                    show_challenger:false,
                    show_debater:true,
                    control_enabled_to:this.state.debate_detail.debate_with,
                    current_rule:"intro2"
                  });
                  var startTime = moment(utc_currentTime, 'hh:mm:ss');
                  var endTime = moment(intro2, 'hh:mm:ss');
                  var secondsDiff = endTime.diff(startTime, 'seconds');
                  this.setState({seconds:secondsDiff,currentActive:'intro2'});
                  this.startTimer()
                  setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          this.handleMic();
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                      }, 5000);
                }

              }else{

                this.setState({
                  show_challenger:true,
                  show_debater:false,
                  control_enabled_to:this.state.debate_detail.userId,
                  current_rule:"intro1"
                });
                var startTime = moment(utc_currentTime, 'hh:mm:ss');
                var endTime = moment(intro1, 'hh:mm:ss');
                var secondsDiff = endTime.diff(startTime, 'seconds'); 
                this.setState({seconds:secondsDiff,currentActive:'intro1'});
                this.startTimer()
                setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          this.handleMic();
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                      }, 5000);
              }
            }else if(response.debate_duration=='30'){
              this.setState({
                introduction_1:(getRules.intro1)?getRules.intro1:90,
                introduction_2:(getRules.intro_2)?getRules.intro_2:90,
                argument_1:(getRules.argu1)?getRules.argu1:480,
                argument_2:(getRules.argu2)?getRules.argu2:480,
                discussion:(getRules.discussion)?getRules.discussion:480,
                final_thoughts_1:(getRules.final_thoughts_1)?getRules.final_thoughts_1:90,
                final_thoughts_2:(getRules.final_thoughts_2)?getRules.final_thoughts_2:90,
                control_enabled_to:response.userId
              })
              var intro1 = moment(utcTime,"HH:mm:ss").add(this.state.introduction_1, 'seconds').format('HH:mm:ss'); 
              if(utc_currentTime>intro1){
                var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2);
                var intro2 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                if(utc_currentTime>intro2){
                  var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1);
                  var argu1 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                  if(utc_currentTime>argu1){
                    var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1)+parseInt(this.state.argument_2);
                    var argu2 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                    if(utc_currentTime>argu2){
                      var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1)+parseInt(this.state.argument_2)+parseInt(this.state.discussion);
                      var discussion = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                      
                      if(utc_currentTime>discussion){

                        var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1)+parseInt(this.state.argument_2)+parseInt(this.state.discussion)+parseInt(this.state.final_thoughts_1);
                        var final_thoughts_1 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                        if(utc_currentTime>final_thoughts_1){

                          var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1)+parseInt(this.state.argument_2)+parseInt(this.state.discussion)+parseInt(this.state.final_thoughts_1)+parseInt(this.state.final_thoughts_2);
                          var final_thoughts_2 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                          if(utc_currentTime>final_thoughts_2){

                           // alert('debate ended');
                            // window.location.hash = "upcoming-debates";
                          }else{
                           
                            this.setState({
                              show_challenger:false,
                              show_debater:true,
                              control_enabled_to:this.state.debate_detail.debate_with,
                              current_rule:"final_thoughts_2"
                            });
                            var startTime = moment(utc_currentTime, 'hh:mm:ss');
                            var endTime = moment(final_thoughts_2, 'hh:mm:ss');
                            var secondsDiff = endTime.diff(startTime, 'seconds');
                            this.setState({seconds:secondsDiff,currentActive:'final_thoughts_2'});
                            this.startTimer()
                            setTimeout( () => { 
                              if(this.state.control_enabled_to!=loginUser){
                                this.handleMic();
                              }
                              if(this.state.control_enabled_to==loginUser){
                                if(this.localStream.isAudioOn()==false){
                                  this.handleMic();
                                }
                              } 
                              if(this.state.control_enabled_to==""){
                                this.localStream.enableAudio();
                              }
                            }, 5000);
                          }
                           
                        }else{

                          this.setState({
                            show_challenger:true,
                            show_debater:false,
                            control_enabled_to:this.state.debate_detail.userId,
                            current_rule:"final_thoughts_1"
                          });
                          var startTime = moment(utc_currentTime, 'hh:mm:ss');
                          var endTime = moment(final_thoughts_1, 'hh:mm:ss');
                          var secondsDiff = endTime.diff(startTime, 'seconds');
                          this.setState({seconds:secondsDiff,currentActive:'final_thoughts_1'});
                          this.startTimer()
                          setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          this.handleMic();
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                      }, 5000);
                        }  
                      }else{

                        this.setState({
                          show_challenger:true,
                          show_debater:true,
                          control_enabled_to:"",
                          current_rule:"discussion"
                        });
                        var startTime = moment(utc_currentTime, 'hh:mm:ss');
                        var endTime = moment(discussion, 'hh:mm:ss');
                        var secondsDiff = endTime.diff(startTime, 'seconds');
                        // alert(secondsDiff);
                        this.setState({seconds:secondsDiff,currentActive:'discussion'});
                        this.startTimer()
                        setTimeout( () => { 
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                      }, 5000);


                      } 
                    }else{

                      this.setState({
                        show_challenger:false,
                        show_debater:true,
                        control_enabled_to:this.state.debate_detail.debate_with,
                        current_rule:"argu2"
                      });
                      var startTime = moment(utc_currentTime, 'hh:mm:ss');
                      var endTime = moment(argu2, 'hh:mm:ss');
                      var secondsDiff = endTime.diff(startTime, 'seconds');
                      // alert(secondsDiff);
                      this.setState({seconds:secondsDiff,currentActive:'argu2'});
                      this.startTimer()
                      setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          this.handleMic();
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                      }, 5000);
                    } 

                  }else{
                    // alert('arhument1')
                    this.setState({
                      show_challenger:true,
                      show_debater:false,
                      control_enabled_to:this.state.debate_detail.userId,
                      current_rule:"argu1"
                    });
                    var startTime = moment(utc_currentTime, 'hh:mm:ss');
                    var endTime = moment(argu1, 'hh:mm:ss');
                    var secondsDiff = endTime.diff(startTime, 'seconds');
                    // alert(secondsDiff);
                    this.setState({seconds:secondsDiff,currentActive:'argu1'});
                    this.startTimer()
                    setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          this.handleMic();
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                      }, 5000);
                  }
                }else{
                  this.setState({
                    show_challenger:false,
                    show_debater:true,
                    control_enabled_to:this.state.debate_detail.debate_with,
                    current_rule:"intro2"
                  });
                  var startTime = moment(utc_currentTime, 'hh:mm:ss');
                  var endTime = moment(intro2, 'hh:mm:ss');
                  var secondsDiff = endTime.diff(startTime, 'seconds');
                  this.setState({seconds:secondsDiff,currentActive:'intro2'});
                  this.startTimer()
                  setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          this.handleMic();
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                      }, 5000);
                }

              }else{

                this.setState({
                  show_challenger:true,
                  show_debater:false,
                  control_enabled_to:this.state.debate_detail.userId,
                  current_rule:"intro1"
                });
                var startTime = moment(utc_currentTime, 'hh:mm:ss');
                var endTime = moment(intro1, 'hh:mm:ss');
                var secondsDiff = endTime.diff(startTime, 'seconds'); 
                this.setState({seconds:secondsDiff,currentActive:'intro1'});
                this.startTimer()
                setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          this.handleMic();
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                      }, 5000);
              }
            }else if(response.debate_duration=='40'){
              this.setState({
                introduction_1:(getRules.intro1)?getRules.intro1:150,
                introduction_2:(getRules.intro_2)?getRules.intro_2:150,
                argument_1:(getRules.argu1)?getRules.argu1:600,
                argument_2:(getRules.argu2)?getRules.argu2:600,
                discussion:(getRules.discussion)?getRules.discussion:600,
                final_thoughts_1:(getRules.final_thoughts_1)?getRules.final_thoughts_1:150,
                final_thoughts_2:(getRules.final_thoughts_2)?getRules.final_thoughts_2:150,
                control_enabled_to:response.userId
              })
              var intro1 = moment(utcTime,"HH:mm:ss").add(this.state.introduction_1, 'seconds').format('HH:mm:ss'); 
              if(utc_currentTime>intro1){
                var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2);
                var intro2 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                if(utc_currentTime>intro2){
                  var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1);
                  var argu1 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                  if(utc_currentTime>argu1){
                    var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1)+parseInt(this.state.argument_2);
                    var argu2 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                    if(utc_currentTime>argu2){
                      var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1)+parseInt(this.state.argument_2)+parseInt(this.state.discussion);
                      var discussion = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                      
                      if(utc_currentTime>discussion){

                        var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1)+parseInt(this.state.argument_2)+parseInt(this.state.discussion)+parseInt(this.state.final_thoughts_1);
                        var final_thoughts_1 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                        if(utc_currentTime>final_thoughts_1){

                          var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1)+parseInt(this.state.argument_2)+parseInt(this.state.discussion)+parseInt(this.state.final_thoughts_1)+parseInt(this.state.final_thoughts_2);
                          var final_thoughts_2 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                          if(utc_currentTime>final_thoughts_2){

                           // alert('debate ended');
                            // window.location.hash = "upcoming-debates";
                          }else{
                           
                            this.setState({
                              show_challenger:false,
                              show_debater:true,
                              control_enabled_to:this.state.debate_detail.debate_with,
                              current_rule:"final_thoughts_2"
                            });
                            var startTime = moment(utc_currentTime, 'hh:mm:ss');
                            var endTime = moment(final_thoughts_2, 'hh:mm:ss');
                            var secondsDiff = endTime.diff(startTime, 'seconds');
                            this.setState({seconds:secondsDiff,currentActive:'final_thoughts_2'});
                            this.startTimer()
                            setTimeout( () => { 
                              if(this.state.control_enabled_to!=loginUser){
                                this.handleMic();
                              }
                              if(this.state.control_enabled_to==loginUser){
                                if(this.localStream.isAudioOn()==false){
                                  this.handleMic();
                                }
                              } 
                              if(this.state.control_enabled_to==""){
                                this.localStream.enableAudio();
                              }
                            }, 5000);
                          }
                           
                        }else{

                          this.setState({
                            show_challenger:true,
                            show_debater:false,
                            control_enabled_to:this.state.debate_detail.userId,
                            current_rule:"final_thoughts_1"
                          });
                          var startTime = moment(utc_currentTime, 'hh:mm:ss');
                          var endTime = moment(final_thoughts_1, 'hh:mm:ss');
                          var secondsDiff = endTime.diff(startTime, 'seconds');
                          this.setState({seconds:secondsDiff,currentActive:'final_thoughts_1'});
                          this.startTimer()
                          setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          this.handleMic();
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                      }, 5000);
                        }  
                      }else{

                        this.setState({
                          show_challenger:true,
                          show_debater:true,
                          control_enabled_to:"",
                          current_rule:"discussion"
                        });
                        var startTime = moment(utc_currentTime, 'hh:mm:ss');
                        var endTime = moment(discussion, 'hh:mm:ss');
                        var secondsDiff = endTime.diff(startTime, 'seconds');
                        // alert(secondsDiff);
                        this.setState({seconds:secondsDiff,currentActive:'discussion'});
                        this.startTimer()
                        setTimeout( () => { 
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                      }, 5000);


                      } 
                    }else{

                      this.setState({
                        show_challenger:false,
                        show_debater:true,
                        control_enabled_to:this.state.debate_detail.debate_with,
                        current_rule:"argu2"
                      });
                      var startTime = moment(utc_currentTime, 'hh:mm:ss');
                      var endTime = moment(argu2, 'hh:mm:ss');
                      var secondsDiff = endTime.diff(startTime, 'seconds');
                      // alert(secondsDiff);
                      this.setState({seconds:secondsDiff,currentActive:'argu2'});
                      this.startTimer()
                      setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          this.handleMic();
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                      }, 5000);
                    } 

                  }else{
                    // alert('arhument1')
                    this.setState({
                      show_challenger:true,
                      show_debater:false,
                      control_enabled_to:this.state.debate_detail.userId,
                      current_rule:"argu1"
                    });
                    var startTime = moment(utc_currentTime, 'hh:mm:ss');
                    var endTime = moment(argu1, 'hh:mm:ss');
                    var secondsDiff = endTime.diff(startTime, 'seconds');
                    // alert(secondsDiff);
                    this.setState({seconds:secondsDiff,currentActive:'argu1'});
                    this.startTimer()
                    setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          this.handleMic();
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                      }, 5000);
                  }
                }else{
                  this.setState({
                    show_challenger:false,
                    show_debater:true,
                    control_enabled_to:this.state.debate_detail.debate_with,
                    current_rule:"intro2"
                  });
                  var startTime = moment(utc_currentTime, 'hh:mm:ss');
                  var endTime = moment(intro2, 'hh:mm:ss');
                  var secondsDiff = endTime.diff(startTime, 'seconds');
                  this.setState({seconds:secondsDiff,currentActive:'intro2'});
                  this.startTimer()
                  setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          this.handleMic();
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                      }, 5000);
                }

              }else{

                this.setState({
                  show_challenger:true,
                  show_debater:false,
                  control_enabled_to:this.state.debate_detail.userId,
                  current_rule:"intro1"
                });
                var startTime = moment(utc_currentTime, 'hh:mm:ss');
                var endTime = moment(intro1, 'hh:mm:ss');
                var secondsDiff = endTime.diff(startTime, 'seconds'); 
                this.setState({seconds:secondsDiff,currentActive:'intro1'});
                this.startTimer()
                setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          this.handleMic();
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                      }, 5000);
              }            
            }else if(response.debate_duration=='50'){
              this.setState({
                introduction_1:(getRules.intro1)?getRules.intro1:150,
                introduction_2:(getRules.intro_2)?getRules.intro_2:150,
                argument_1:(getRules.argu1)?getRules.argu1:900,
                argument_2:(getRules.argu2)?getRules.argu2:900,
                discussion:(getRules.discussion)?getRules.discussion:900,
                final_thoughts_1:(getRules.final_thoughts_1)?getRules.final_thoughts_1:300,
                final_thoughts_2:(getRules.final_thoughts_2)?getRules.final_thoughts_2:300,
                control_enabled_to:response.userId
              })
              var intro1 = moment(utcTime,"HH:mm:ss").add(this.state.introduction_1, 'seconds').format('HH:mm:ss'); 
              if(utc_currentTime>intro1){
                var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2);
                var intro2 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                if(utc_currentTime>intro2){
                  var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1);
                  var argu1 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                  if(utc_currentTime>argu1){
                    var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1)+parseInt(this.state.argument_2);
                    var argu2 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                    if(utc_currentTime>argu2){
                      var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1)+parseInt(this.state.argument_2)+parseInt(this.state.discussion);
                      var discussion = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                      
                      if(utc_currentTime>discussion){

                        var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1)+parseInt(this.state.argument_2)+parseInt(this.state.discussion)+parseInt(this.state.final_thoughts_1);
                        var final_thoughts_1 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                        if(utc_currentTime>final_thoughts_1){

                          var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1)+parseInt(this.state.argument_2)+parseInt(this.state.discussion)+parseInt(this.state.final_thoughts_1)+parseInt(this.state.final_thoughts_2);
                          var final_thoughts_2 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                          if(utc_currentTime>final_thoughts_2){

                           // alert('debate ended');
                            // window.location.hash = "upcoming-debates";
                          }else{
                           
                            this.setState({
                              show_challenger:false,
                              show_debater:true,
                              control_enabled_to:this.state.debate_detail.debate_with,
                              current_rule:"final_thoughts_2"
                            });
                            var startTime = moment(utc_currentTime, 'hh:mm:ss');
                            var endTime = moment(final_thoughts_2, 'hh:mm:ss');
                            var secondsDiff = endTime.diff(startTime, 'seconds');
                            this.setState({seconds:secondsDiff,currentActive:'final_thoughts_2'});
                            this.startTimer()
                            setTimeout( () => { 
                              if(this.state.control_enabled_to!=loginUser){
                                this.handleMic();
                              }
                              if(this.state.control_enabled_to==loginUser){
                                if(this.localStream.isAudioOn()==false){
                                  this.handleMic();
                                }
                              } 
                              if(this.state.control_enabled_to==""){
                                this.localStream.enableAudio();
                              }
                            }, 5000);
                          }
                           
                        }else{

                          this.setState({
                            show_challenger:true,
                            show_debater:false,
                            control_enabled_to:this.state.debate_detail.userId,
                            current_rule:"final_thoughts_1"
                          });
                          var startTime = moment(utc_currentTime, 'hh:mm:ss');
                          var endTime = moment(final_thoughts_1, 'hh:mm:ss');
                          var secondsDiff = endTime.diff(startTime, 'seconds');
                          this.setState({seconds:secondsDiff,currentActive:'final_thoughts_1'});
                          this.startTimer()
                          setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          this.handleMic();
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                      }, 5000);
                        }  
                      }else{

                        this.setState({
                          show_challenger:true,
                          show_debater:true,
                          control_enabled_to:"",
                          current_rule:"discussion"
                        });
                        var startTime = moment(utc_currentTime, 'hh:mm:ss');
                        var endTime = moment(discussion, 'hh:mm:ss');
                        var secondsDiff = endTime.diff(startTime, 'seconds');
                        // alert(secondsDiff);
                        this.setState({seconds:secondsDiff,currentActive:'discussion'});
                        this.startTimer()
                        setTimeout( () => { 
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                      }, 5000);


                      } 
                    }else{

                      this.setState({
                        show_challenger:false,
                        show_debater:true,
                        control_enabled_to:this.state.debate_detail.debate_with,
                        current_rule:"argu2"
                      });
                      var startTime = moment(utc_currentTime, 'hh:mm:ss');
                      var endTime = moment(argu2, 'hh:mm:ss');
                      var secondsDiff = endTime.diff(startTime, 'seconds');
                      // alert(secondsDiff);
                      this.setState({seconds:secondsDiff,currentActive:'argu2'});
                      this.startTimer()
                      setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          this.handleMic();
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                      }, 5000);
                    } 

                  }else{
                    // alert('arhument1')
                    this.setState({
                      show_challenger:true,
                      show_debater:false,
                      control_enabled_to:this.state.debate_detail.userId,
                      current_rule:"argu1"
                    });
                    var startTime = moment(utc_currentTime, 'hh:mm:ss');
                    var endTime = moment(argu1, 'hh:mm:ss');
                    var secondsDiff = endTime.diff(startTime, 'seconds');
                    // alert(secondsDiff);
                    this.setState({seconds:secondsDiff,currentActive:'argu1'});
                    this.startTimer()
                    setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          this.handleMic();
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                      }, 5000);
                  }
                }else{
                  this.setState({
                    show_challenger:false,
                    show_debater:true,
                    control_enabled_to:this.state.debate_detail.debate_with,
                    current_rule:"intro2"
                  });
                  var startTime = moment(utc_currentTime, 'hh:mm:ss');
                  var endTime = moment(intro2, 'hh:mm:ss');
                  var secondsDiff = endTime.diff(startTime, 'seconds');
                  this.setState({seconds:secondsDiff,currentActive:'intro2'});
                  this.startTimer()
                  setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          this.handleMic();
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                      }, 5000);
                }

              }else{

                this.setState({
                  show_challenger:true,
                  show_debater:false,
                  control_enabled_to:this.state.debate_detail.userId,
                  current_rule:"intro1"
                });
                var startTime = moment(utc_currentTime, 'hh:mm:ss');
                var endTime = moment(intro1, 'hh:mm:ss');
                var secondsDiff = endTime.diff(startTime, 'seconds'); 
                this.setState({seconds:secondsDiff,currentActive:'intro1'});
                this.startTimer()
                setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          this.handleMic();
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                      }, 5000);
              }
            }else if(response.debate_duration=='60'){
              this.setState({
                introduction_1:(getRules.intro1)?getRules.intro1:150,
                introduction_2:(getRules.intro_2)?getRules.intro_2:150,
                argument_1:(getRules.argu1)?getRules.argu1:900,
                argument_2:(getRules.argu2)?getRules.argu2:900,
                discussion:(getRules.discussion)?getRules.discussion:900,
                final_thoughts_1:(getRules.final_thoughts_1)?getRules.final_thoughts_1:300,
                final_thoughts_2:(getRules.final_thoughts_2)?getRules.final_thoughts_2:300,
                control_enabled_to:response.userId
              })

              

              
              var intro1 = moment(utcTime,"HH:mm:ss").add(this.state.introduction_1, 'seconds').format('HH:mm:ss'); 
              if(utc_currentTime>intro1){
                var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2);
                var intro2 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                if(utc_currentTime>intro2){
                  var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1);
                  var argu1 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                  if(utc_currentTime>argu1){
                    var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1)+parseInt(this.state.argument_2);
                    var argu2 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                    if(utc_currentTime>argu2){
                      var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1)+parseInt(this.state.argument_2)+parseInt(this.state.discussion);
                      var discussion = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                      
                      if(utc_currentTime>discussion){

                        var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1)+parseInt(this.state.argument_2)+parseInt(this.state.discussion)+parseInt(this.state.final_thoughts_1);
                        var final_thoughts_1 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                        if(utc_currentTime>final_thoughts_1){

                          var add= parseInt(this.state.introduction_1)+parseInt(this.state.introduction_2)+parseInt(this.state.argument_1)+parseInt(this.state.argument_2)+parseInt(this.state.discussion)+parseInt(this.state.final_thoughts_1)+parseInt(this.state.final_thoughts_2);
                          var final_thoughts_2 = moment(utcTime,"HH:mm:ss").add(add, 'seconds').format('HH:mm:ss');
                          if(utc_currentTime>final_thoughts_2){

                           // alert('debate ended');
                            // window.location.hash = "upcoming-debates";
                          }else{
                           
                            this.setState({
                              show_challenger:false,
                              show_debater:true,
                              control_enabled_to:this.state.debate_detail.debate_with,
                              current_rule:"final_thoughts_2"
                            });
                            var startTime = moment(utc_currentTime, 'hh:mm:ss');
                            var endTime = moment(final_thoughts_2, 'hh:mm:ss');
                            var secondsDiff = endTime.diff(startTime, 'seconds');
                            this.setState({seconds:secondsDiff,currentActive:'final_thoughts_2'});
                            this.startTimer()
                            setTimeout( () => { 
                              if(this.state.control_enabled_to!=loginUser){
                                this.handleMic();
                              }
                              if(this.state.control_enabled_to==loginUser){
                                if(this.localStream.isAudioOn()==false){
                                  this.handleMic();
                                }
                              } 
                              if(this.state.control_enabled_to==""){
                                this.localStream.enableAudio();
                              }
                            }, 5000);
                          }
                           
                        }else{

                          this.setState({
                            show_challenger:true,
                            show_debater:false,
                            control_enabled_to:this.state.debate_detail.userId,
                            current_rule:"final_thoughts_1"
                          });
                          var startTime = moment(utc_currentTime, 'hh:mm:ss');
                          var endTime = moment(final_thoughts_1, 'hh:mm:ss');
                          var secondsDiff = endTime.diff(startTime, 'seconds');
                          this.setState({seconds:secondsDiff,currentActive:'final_thoughts_1'});
                          this.startTimer()
                          setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          this.handleMic();
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                      }, 5000);
                        }  
                      }else{

                        this.setState({
                          show_challenger:true,
                          show_debater:true,
                          control_enabled_to:"",
                          current_rule:"discussion"
                        });
                        var startTime = moment(utc_currentTime, 'hh:mm:ss');
                        var endTime = moment(discussion, 'hh:mm:ss');
                        var secondsDiff = endTime.diff(startTime, 'seconds');
                        // alert(secondsDiff);
                        this.setState({seconds:secondsDiff,currentActive:'discussion'});
                        this.startTimer()
                        setTimeout( () => { 
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                      }, 5000);


                      } 
                    }else{

                      this.setState({
                        show_challenger:false,
                        show_debater:true,
                        control_enabled_to:this.state.debate_detail.debate_with,
                        current_rule:"argu2"
                      });
                      var startTime = moment(utc_currentTime, 'hh:mm:ss');
                      var endTime = moment(argu2, 'hh:mm:ss');
                      var secondsDiff = endTime.diff(startTime, 'seconds');
                      // alert(secondsDiff);
                      this.setState({seconds:secondsDiff,currentActive:'argu2'});
                      this.startTimer()
                      setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          this.handleMic();
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                      }, 5000);
                    } 

                  }else{
                    // alert('arhument1')
                    this.setState({
                      show_challenger:true,
                      show_debater:false,
                      control_enabled_to:this.state.debate_detail.userId,
                      current_rule:"argu1"
                    });
                    var startTime = moment(utc_currentTime, 'hh:mm:ss');
                    var endTime = moment(argu1, 'hh:mm:ss');
                    var secondsDiff = endTime.diff(startTime, 'seconds');
                    // alert(secondsDiff);
                    this.setState({seconds:secondsDiff,currentActive:'argu1'});
                    this.startTimer()
                    setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          this.handleMic();
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                      }, 5000);
                  }
                }else{
                  this.setState({
                    show_challenger:false,
                    show_debater:true,
                    control_enabled_to:this.state.debate_detail.debate_with,
                    current_rule:"intro2"
                  });
                  var startTime = moment(utc_currentTime, 'hh:mm:ss');
                  var endTime = moment(intro2, 'hh:mm:ss');
                  var secondsDiff = endTime.diff(startTime, 'seconds');
                  this.setState({seconds:secondsDiff,currentActive:'intro2'});
                  this.startTimer()
                  setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          this.handleMic();
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                      }, 5000);
                }

              }else{

                this.setState({
                  show_challenger:true,
                  show_debater:false,
                  control_enabled_to:this.state.debate_detail.userId,
                  current_rule:"intro1"
                });
                var startTime = moment(utc_currentTime, 'hh:mm:ss');
                var endTime = moment(intro1, 'hh:mm:ss');
                var secondsDiff = endTime.diff(startTime, 'seconds'); 
                this.setState({seconds:secondsDiff,currentActive:'intro1'});
                this.startTimer()
                setTimeout( () => { 
                        if(this.state.control_enabled_to!=loginUser){
                          this.handleMic();
                        }
                        if(this.state.control_enabled_to==loginUser){
                          if(this.localStream.isAudioOn()==false){
                            this.handleMic();
                          }
                        } 
                        if(this.state.control_enabled_to==""){
                          this.localStream.enableAudio();
                        }
                      }, 5000);
              }
                
            }
          }
        }
        if(response.userId==loginUser){
          
        }else{
          //this.setState({ organizer: response.userId });
        }
      }

      // alert(this.state.control_enabled_to)
    }else{
        this.setState({seconds_wait:secondsDiff});
        this.startWaitingTimer();
    }
  }
  componentWillMount() {

    
    //let names= (new URLSearchParams(window.location.search)).get("name")
    // alert(names)
    //alert(JSON.stringify(window.location.search))
    // const location = useLocation();
    // const queryData = new queryString.parse(location.search);
    //alert(queryData.name)
    // var s= this.props.match.params.de;
    // alert(s)
    if(loginUser!==null){

    this.getUserProfile(loginUser);

    //get another user profile
    
    }
 
    if(myTimeZone!=null){
      
      //console.log(myTimeZone)
      moment().tz(myTimeZone).format();

    }
    // if(loginUser==null || loginUser=="undefined"){
    //   this.props.attendeeMode="audience"
    //   alert(this.props.attendeeMode);
    // }
    let $ = this.props;
    
    console.log("$",$)
    
    // init AgoraRTC local client
    // const debateID= this.props.match.params.debateID;
    // alert($.debateID);
    
    const rules =  this.implimentDebateRules($.debateID);
    // console.log($);
    // console.log('$asd');
    
    this.client = AgoraRTC.createClient({ mode: $.transcode });
    this.client.init($.appId, () => {
      console.log("AgoraRTC client initialized");
  
      this.subscribeStreamEvents(false);
    
     
      this.client.join($.appId, $.channel, $.uid, (uid) => {
       
        this.setState({
          uid : uid,
          currentActiveSession: [this.state.currentActiveSession].concat(uid),
          channel:$.channel,
          sendingUid:uid
        });
       
        //const items = this.state.currentActiveSession.filter(item => item !== uid);
        //this.setState({ currentActiveSession: items });
          
        console.log("User " + uid + " join channel successfully");
        console.log("At " + new Date().toLocaleTimeString());
        // create local stream
        // It is not recommended to setState in function addStream
        // alert(uid)
        this.localStream = this.streamInit(uid, $.attendeeMode, $.videoProfile)
        console.log(this.localStream)
        this.localStream.init( () => {
            
          if ($.attendeeMode !== "audience") {
            
              this.addStream(this.localStream, true);

              let strm=this.localStream;
                this.setState({
                  localStreamArr: [strm].concat(this.state.localStreamArr),
                });
              // this.setState({localStreamArr:this.localStream})
              this.client.publish(this.localStream, (err) => {
                console.log("Publish local stream error: " + err);
              });
            }else{
 
              // this.addAudienceStream(this.localStream, true);

              // let strm=this.localStream;
              //   this.setState({
              //     localStreamArr: [strm].concat(this.state.localStreamArr),
              //   });
              // // this.setState({localStreamArr:this.localStream})
              // this.client.publish(this.localStream, (err) => {
              //   console.log("Publish local stream error: " + err);
              // }); 
            }
            

            this.setState({ readyState: true });
          },
          (err) => {
            console.log("getUserMedia failed", err);
            this.setState({ readyState: true });
          }
        );
        //start recording

        //alert('hiii');
        if(this.props.attendeeMode=="video"){
           this.startRecording($.channel,uid)
        }
      });
    });
    //start recording
  }
  getUserProfile(id){
    
    axios.get(API_BASE_URL+'users/'+id)
    .then(res => {     
      
     this.state.profilePic=res.data.profile_image
    //  if(this.state.debate_detail.userId==res.data.id){
    //    this.setState({challangerTwitter:res.data.email})
    //  }
    //  else{
    //    this.setState({debaterTwitter:res.data.email})
    //  }
        // this.setState({ debate_control: res.data.data.debate_control }); 
     
    });
  }

startRecording(chennel,uid){
  
  //alert(this.props.channel)
  var alldata=this.props;
  setTimeout(() => {

    //alert(loginUser)
    //acquire resourse id 
    axios.post(API_BASE_URL+'acquire',{ channel:this.props.channel, uid:loginUser}).then(res=>{
        
      
      this.setState({
        resourceId:res.data.resourceId
      })
   
      axios.post(API_BASE_URL+'start-recording',{
        resourceId:res.data.resourceId,
        channel:this.props.channel,
        uid:loginUser,
        url:window.location.href
      }).then(start=>{
        
        setTimeout(() => {
          axios.post(API_BASE_URL+'update-layout',{ channel:this.props.channel, uid:loginUser});
        }, 5000);
        this.setState({sid:start.data.sid}) 
      }).catch(err=>{
        console.log(err)
      })
        
    })
  }, 10000);
} 
  //https://s3.amazonaws.com/debate-consumer-recording/383739f55143c0ea3bfda0893716e312_747ED7-4916-25A140_0.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIARIX53FCH2TYU7H6S%2F20211020%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20211020T122834Z&X-Amz-Expires=900&X-Amz-Signature=c792161005b6f944c858fa94092018e5feee6fc2b267f897cf4c4eb018ca85c4&X-Amz-SignedHeaders=host
async stopRecording(){
  // alert("in a stop recording")
  // alert(this.props.attendeeMode)
  if(this.props.attendeeMode=="video"){

    let no = this.state.streamList.length;

    //alert(no)
    if(no==1){
      //alert('num '+ no)
      var payload={
        rid:this.state.resourceId,
        sid:this.state.sid,
        channel:this.props.channel,
        uid:loginUser,
      }
      var res = axios.post(API_BASE_URL+'stop-recording',payload);
      return {status:true};
      setTimeout( () => { 
      },2000)
    }else{
      return {status:true};
    }    
  }
}

async stopRecordingForceFUlly(){
  if(this.props.attendeeMode=="video"){

    let no = this.state.streamList.length;

    //alert(no)
    var payload={
      rid:this.state.resourceId,
      sid:this.state.sid,
      channel:this.props.channel,
      uid:loginUser,
    }
    var res = axios.post(API_BASE_URL+'stop-recording',payload);
    //alert('return')
    return {status:true};
    setTimeout( () => { 
    },2000)
       
  }
}
  load() {
     
     axios.get(API_BASE_URL+'debates/'+this.state.debate_detail.id)
    .then(res => {     
      
      this.state.oponent=res.data.data.debate_with
      this.setState({ control_enabled_to : res.data.data.debate_control }); 
      if( res.data.data.debate_control == loginUser )
      {       
        if(res.data.data.isCompleted==1){
          // alert(res.data.data.isCompleted)
          this.setState({debateEnded : true })
        }
      }
      
      // compare if the data has new values
      // if it has then update the state i.e. this.setState({ resultState: res.data });
      // otherwise do nothing
    });
  }

  componentDidMount() { 


    // window.addEventListener("beforeunload", (ev) => 
    // {  
    //     ev.preventDefault();
    //     return ev.returnValue = 'Are you sure you want to close?';
    // });
    setTimeout( () => { 

      if ( ( $('#remoteStream_div').is(':empty')   ) && ( $('#localStream_div').is(':empty') ) ) {
        if(this.props.attendeeMode=='audience'){

          setInterval(function(){ 
            // alert('All users have left.');
            //alert("Hello"); 
          }, 5000);
          //window.location.assign(BASE_URL)
        }
        
      }

      if(this.state.control_enabled_to!=loginUser){
        
        // alert(loginUser)
        // this.handleMic();
      }
    }, 5000);
       // alert(this.props.attendeeMode)
    const loadServer = localStorage.getItem('loadServer')
    // alert(loadServer)
    if(loadServer=='null' || loadServer=='undefined'){
      window.location.reload();
      // alert('d');
      localStorage.setItem('loadServer','loaded'); 
    }
    setTimeout( () => {
      //
      this.setState({
        hiddenvalue:"bbghf"
      })
    }, 15000);

    this.initSocket()
  }

  initSocket = async () => {
      let socket = io.connect( socketUrl, {query: 'debateid='+this.props.debateID+'&userId='+loginUser} )
      globalSocket=socket;
      this.setState({ socket })
      socket.on('connect', () => console.log( 'Connected')) 


      if(this.props.attendeeMode=='audience'){

        //alert('Joined successfully')
        if(this.props.isRealAudience==true){

          socket.emit( 'watch_debate', {debate_id:this.props.debateID} ) 
        }
      }

      socket.emit('setTwitterHandle', {twitterHandle:twitterHandle,loginUser,debate_id:this.props.debateID} ) 
      // socket.disconnect({debate_id:this.props.debateID});
     

      socket.on('watch_debate',  (data) => { 

        //alert('hello' + data.live_count);

        if(data.debate_id==this.props.debateID){
          
          if(this.state.live_users!=data.live_count){
            if(data.live_count>0){

              this.setState({live_users:data.live_count})
              let no = this.state.streamList.length;
              var attend=parseInt(data.live_count)-no;
              if(attend>=0){

              //this.setState({live_users:attend})
              }else{

                //this.setState({live_users:0})
              }
              console.log("data.live_count",data.live_count)
            }else{
              this.setState({live_users:0})
            }
          }
        }
        //console.log(data)
      });

      socket.on('debater_leaved',  (data) => { 

        //alert('hello' + data.live_count);

        if(data.user!=loginUser){
          
           toast.warning('Debater has been left.', {autoClose:900000});
        }
        //console.log(data)
      });

      socket.on('forcefully_end',  (data) => { 

        //alert('hello' + data.live_count);
        //alert('rd')
        setTimeout(function() {

          window.location.assign(BASE_URL)
        }, 1000);
        //console.log(data)
      });

      

      socket.on('setTwitterHandle',  (data) => { 
        //alert(data.live_count)
        
        if(twitterHandle!=data.opponent){
        
          if(this.state.setTwitterHandle!=data.opponent && parseInt(data.opponentid) !== parseInt(loginUser)){
            this.setState({setTwitterHandle:data.opponent})
          }
        }

        socket.on('mute_control',(data)=>{
          if(parseInt(data.sendTo)===parseInt(loginUser)){
            if(data.action == 'mute'){
              this.setState({showRemoteMute:true})
            }
            else{
              this.setState({showRemoteMute:false})
            }
          }
        })
        socket.on('mute_camera',(data)=>{

          //console.log('wtf--' + JSON.stringify(data))
          // {
          //   profile:this.state.profilePic,
          //   actionBy:loginUser,
          //   debate_id:this.state.debate_detail.id,
          //   sendTo:sendTo,
          //   action:this.state.muteStatus
          // }
          //alert('hii')
          if(parseInt(data.sendTo)===parseInt(loginUser)){
              //alert(JSON.stringify(data.profile));
              //alert(data.action)
              if(data.action == 'mute'){
                //alert('hii ')
                this.setState({remote_div_inner :"hide deal_video", show_remote_image:"deal_video",remote_image:data.profile})
                 
              }else{

                let s = this.state.remoteStreamArr.length;
                if(s==1){

                  this.setState({remote_div_inner:"video_deal_thumbnaila deal_video",show_remote_image:"deal_video hide"})
                }else if(s==2){
                  this.setState({remote_div_inner:"video_deal_thumbnaila",show_remote_image:"deal_video hide"})

                }
                // this.setState({showRemoteMute:false})
                
                //$('#remoteStream_div').css('display','none');
                //$('<img class="deal_video cameraclassshow" src='+data.profile+'" id="ss">').insertAfter("#remoteStream_div");

              }
          }

        })
        //console.log(data)
      });
      socket.on('change_control',(data)=>{
        
        console.log(data.debate_control);
        //alert('data => '+ JSON.stringify(data));
        // alert('data.debateID => '+ data.debateID);
        // alert('props.debateID => '+ this.props.debateID);

        if(this.props.debateID==data.debateID){


          // alert('data.debate_control')
          // alert(data.debate_control)
          this.setState({
            control_enabled_to:data.debate_control
          })
        }
      
      })
      
      socket.on('subtract_time',(data)=>{
      
        

        var getAllRules = localStorage.getItem('debate_rules');

        //console.log(getAllRules)
        //alert('hii');


        if(getAllRules!=null){
          
          getAllRules = JSON.parse(getAllRules); 
          
          if(getAllRules.debate_id!=this.props.debateID){
            var ruleObj={
              "debate_id":this.state.debate_detail.public_id,
              "intro1":this.state.introduction_1,
              "intro_2":this.state.introduction_2,
              "argu1":this.state.argument_1,
              "argu2":this.state.argument_2,
              "discussion":this.state.discussion,
              "final_thoughts_1":this.state.final_thoughts_1,
              "final_thoughts_2":this.state.final_thoughts_2
            }

            localStorage.setItem('debate_rules',JSON.stringify(ruleObj))
          }
        }else{

          var ruleObj={
            "debate_id":this.state.debate_detail.public_id,
            "intro1":this.state.introduction_1,
            "intro_2":this.state.introduction_2,
            "argu1":this.state.argument_1,
            "argu2":this.state.argument_2,
            "discussion":this.state.discussion,
            "final_thoughts_1":this.state.final_thoughts_1,
            "final_thoughts_2":this.state.final_thoughts_2
          }

          localStorage.setItem('debate_rules',JSON.stringify(ruleObj))
        }


        if(data.current_rule=='intro1'){
          let intro1_sec=this.state.introduction_1;
          // alert(intro1_sec)
          
          this.setState({
            control_enabled_to:this.state.debate_detail.userId,
            show_challenger:true,
            show_debater:false,
            introduction_1:this.state.introduction_1-this.state.seconds,
            seconds:1,
          })

          var getRules = JSON.parse(localStorage.getItem('debate_rules')); 
          getRules.intro1=this.state.introduction_1-this.state.seconds;
          localStorage.setItem('debate_rules',JSON.stringify(getRules))
          //alert(getRules.debate_id)
          //alert(this.state.seconds)
          this.startTimer()
          // if()
           
        }else if(data.current_rule=='intro2'){
          let intro1_sec=this.state.introduction_2;
       
          this.setState({
            control_enabled_to:this.state.debate_detail.debate_with,
            show_challenger:false,
            show_debater:true,
            introduction_2:this.state.introduction_2-this.state.seconds,
            seconds:1,
          })
          var getRules = JSON.parse(localStorage.getItem('debate_rules')); 
          getRules.intro_2=this.state.introduction_2-this.state.seconds;
          localStorage.setItem('debate_rules',JSON.stringify(getRules))
          //alert(getRules.debate_id)
          this.startTimer()
          //alert(this.state.seconds)
          // alert(intro1_sec)
        }else if(data.current_rule=='argu1'){
          let intro1_sec=this.state.argument_1;
          //alert(intro1_sec-this.state.seconds)
      
          this.setState({
            control_enabled_to:this.state.debate_detail.userId,
            show_challenger:false,
            show_debater:true,
            argument_1:this.state.argument_1-this.state.seconds,
            seconds:1,
          })
          var getRules = JSON.parse(localStorage.getItem('debate_rules')); 
          getRules.argu1=this.state.argument_1-this.state.seconds;
          localStorage.setItem('debate_rules',JSON.stringify(getRules))
          //alert(getRules.debate_id)
          //alert(this.state.seconds)
          this.startTimer()
        }else if(data.current_rule=='argu2'){
          let intro1_sec=this.state.argument_2;
          this.setState({
            control_enabled_to:this.state.debate_detail.debate_with,
            show_challenger:true,
            show_debater:false,
            seconds:1,
            argument_2:this.state.argument_2-this.state.seconds
          })
          var getRules = JSON.parse(localStorage.getItem('debate_rules')); 
          getRules.argu2=this.state.argument_2-this.state.seconds;
          localStorage.setItem('debate_rules',JSON.stringify(getRules))
          //alert(this.state.seconds)
          this.startTimer()
        }else if(data.current_rule=='discussion'){
          let intro1_sec=this.state.discussion;
  
          this.setState({
            control_enabled_to:"",
            show_challenger:true,
            show_debater:true,
            seconds:1,
            discussion:this.state.discussion-this.state.seconds
          }) 
          
          var getRules = JSON.parse(localStorage.getItem('debate_rules')); 
          getRules.discussion=this.state.discussion-this.state.seconds;
          localStorage.setItem('debate_rules',JSON.stringify(getRules))
          
          this.startTimer()
        }else if(data.current_rule=='final_thoughts_1'){
          let intro1_sec=this.state.final_thoughts_1;
  
          this.setState({
            control_enabled_to:this.state.debate_detail.userId,
            show_challenger:true,
            seconds:1,
            final_thoughts_1:this.state.final_thoughts_1-this.state.seconds
          }) 
          
          var getRules = JSON.parse(localStorage.getItem('debate_rules')); 
          getRules.final_thoughts_1=this.state.final_thoughts_1-this.state.seconds;
          localStorage.setItem('debate_rules',JSON.stringify(getRules))
          
          this.startTimer()
        }else if(data.current_rule=='final_thoughts_2'){
          let intro1_sec=this.state.final_thoughts_2;
     
  
          this.setState({
            control_enabled_to:this.state.debate_detail.debate_with,
            show_challenger:true,
            show_debater:false,
            seconds:1,
            final_thoughts_2:this.state.final_thoughts_2-this.state.seconds
          }) 
          
          var getRules = JSON.parse(localStorage.getItem('debate_rules')); 
          getRules.final_thoughts_2=this.state.final_thoughts_2-this.state.seconds;
          localStorage.setItem('debate_rules',JSON.stringify(getRules))

          this.startTimer()
        }
        //alert(this.state.control_enabled_to)
        globalSocket.emit( 'change_control', {debateID:this.props.debateID,debate_id:this.state.debate_detail.id,debate_control:this.state.control_enabled_to} )
      })
  }
  showLiveUsers=async(data)=>{
  }
  componentDidUpdate() {
  
    //let socket = io( socketUrl )
    
    if(this.state.debateEnded == false){
    setTimeout(
      function() { 
        if(this.state.debateEnded == false){
          //this.load();
        }
          
         // this.setState({ position: 1 });
      }
      .bind(this),
      3000
    );


 


    // rerendering
    // console.log('hi');
    // const rules =  this.implimentDebateRules($.debateID);

    let canvas = document.querySelector("#ag-canvas");
    // pip mode (can only use when less than 4 people in channel)
    if (this.state.displayMode === "pip") {
      let no = this.state.streamList.length;
      if (no > 4) {
        this.setState({ displayMode: "tile" });
        return;
      }


      console.log(this.state.streamList);
      console.log('this.state.streamList');
      this.state.streamList.map((item, index) => {
        let id = item.getId();
        let dom = document.querySelector("#ag-item-" + id);
        if (!dom) {

          dom = document.createElement("section");
          dom.setAttribute("id", "ag-item-" + id);
          dom.setAttribute("class", "ag-item");
          //alert(this.state.stateSharing+"===update")
          if(this.state.stateSharing==true){

          }else{
            canvas.appendChild(dom);
            item.play("ag-item-" + id);
          }
        }
        if (index === no - 1) {
          //dom.setAttribute("style", `grid-area: span 12/span 24/13/25`);
        } else {
          // dom.setAttribute(
          //   "style",
          //   `grid-area: span 3/span 4/${4 + 3 * index}/25;
          //   z-index:1;width:calc(100% - 20px);height:calc(100% - 20px)`
          // );
        }

        item.player.resize && item.player.resize();
      });
    }
    // tile mode
    else if (this.state.displayMode === "tile") {
      // console.log(this.state.streamList);
      // console.log('this.state.streamList');

      let liveAudience = this.state.audienceStreamList.length;
      if(liveAudience>0){
        //alert(liveAudience)
      }  
      let no = this.state.streamList.length;
      // alert(no);
      if(this.state.isAudience==false){
        console.log(no)
        console.log('login')
        if(no>0){
   
           

          this.state.localStreamArr.map((item, index) => {
             
            let id = item.getId();
            let dom = document.querySelector("#ag-item-" + id); 
            item.play("localStream_div");
            //alert(id)
            // item.player.resize && item.player.resize();
          });
   
          let s = this.state.remoteStreamArr.length;
          let srr=this.state.remoteStreamArr;
           console.log(s)
           console.log(srr)

          // var res =  axios.get(API_BASE_URL+'debates/'+this.state.debate_detail.id);
          
          // var response=res.data;
          console.log(this.state.remoteStreamArrObj)
          console.log("remoteStreamArr has 121212")
           console.log(this.state.control_enabled_to)
          if(s==1){
            if(this.state.remote_col!=="col-md-6 thumbnail_cols"){ 
              this.setState({
                remote_col:"col-md-6 thumbnail_cols",
                remote_div_inner :'video_deal_thumbnaila deal_video',
                enableShareScreen:"debate_left"
              })
            }

            if(this.state.show_remote_image=="deal_video"){
              
              if(this.state.remote_div_inner!="deal_video hide"){
                this.setState({
                  remote_div_inner :'deal_video hide',
                  enableShareScreen:"debate_left"
                })
              } 
            }
          }

          // :"video_deal_thumbnaila"
          if(this.state.show_remote_image=="deal_video"){
            if(this.state.remote_div_inner!="video_deal_thumbnaila hide"){

              // this.setState({
              //   remote_div_inner:"video_deal_thumbnaila hide"
              // })
            }
          }
          this.state.remoteStreamArr.map((item, index) => {
             // alert('remo')
            let id = item.getId();
            // let dom = document.querySelector("#ag-item-" + id);
            let dom = document.querySelector("#player_" + id);
            if (!dom) { 
              //dom = document.createElement("section");
              //dom.setAttribute("id", "ag-item-" + id);
              //dom.setAttribute("class", "ag-item"); 
            }

            // alert(this.state.remoteStreamArrObj[index].user)  
            var isEmpty = document.getElementById('remoteStream_div').innerHTML === "";
          
            if(isEmpty==true){   
              
              //if(id==)
              item.play("remoteStream_div",{audio: false, video: false}); 
            
            }else{
              var shareActive = document.getElementById('shareStream_div').innerHTML === "";
          
              if(shareActive==true){    
               
                item.play("shareStream_div");  
                
              }else{
                if(this.state.remote_col!=="col-md-2 thumbnail_cols"){ 
                  this.setState({
                    remote_col:"col-md-2 thumbnail_cols",
                    remote_div_inner:"video_deal_thumbnaila",
                    enableShareScreen:"debate_left active"
                  })

                  if(this.state.show_remote_image=="deal_video"){
                    this.setState({ 
                      remote_div_inner:"video_deal_thumbnaila hide"
                    })
                  }
                }
              }
            }  
                 
            // item.player.resize && item.player.resize();
          });
   
        }
      }else{
        console.log(this.state.remoteStreamArrObj)
        // alert(this.state.remoteStreamArr.length)
        console.log(no)
        console.log('aud')
        if(no>0){
   
           

          // this.state.localStreamArr.map((item, index) => {
             
          //   let id = item.getId();
          //   let dom = document.querySelector("#ag-item-" + id); 
          //   item.play("localStream_div");
          //   // item.player.resize && item.player.resize();
          // });
   
          let s = this.state.remoteStreamArr.length;
          let srr=this.state.remoteStreamArr;
           console.log(s)
           console.log(srr)

          // var res =  axios.get(API_BASE_URL+'debates/'+this.state.debate_detail.id);
          
          // var response=res.data;
          console.log(this.state.remoteStreamArr)
          console.log('this.state.remoteStreamArr')
         //  console.log("remoteStreamArr has 121212"+ response)
          console.log(this.state.remoteStreamArrObj)
          if(s==2){
            if(this.state.remote_col!=="col-md-6 thumbnail_cols"){ 
              this.setState({
                remote_col:"col-md-6 thumbnail_cols",
                remote_div_inner :'video_deal_thumbnaila deal_video',
                enableShareScreen:"debate_left"
              })
            }
          }
          this.state.remoteStreamArr.map(async(item, index) => {
             // alert('remo')
            //alert(this.state.remoteStreamArr[index].getId())
            let id = item.getId();
            // alert(id)
            // let dom = document.querySelector("#ag-item-" + id);
            let dom = document.querySelector("#player_" + id);
            if (!dom) { 
              //dom = document.createElement("section");
              //dom.setAttribute("id", "ag-item-" + id);
              //dom.setAttribute("class", "ag-item"); 
            }  



            
            var isEmpty = document.getElementById('localStream_div').innerHTML === "";
          
            // alert('chellenger uid ' + this.state.debate_detail.challenger_uid)
            // alert('debater_id uid ' + this.state.debate_detail.debater_uid)
            // alert(id)
            if(isEmpty==true){   
              //alert(this.state.remoteStreamArrObj[index].user)
              // if(this.state.debater_id==""){
              //   this.setState({
              //     debater_id:this.state.remoteStreamArrObj[index].user
              //   })
              // }
              console.log('its empty')
              if(id==this.state.debate_detail.challenger_uid){
                console.log('id matched')
                if(this.state.show_challenger_id==""){
                  this.setState({
                    show_challenger_id:this.state.challenger_id
                  })
                }
                item.play("localStream_div",{audio: false, video: false}); 
                //alert('challenger_id is left')
              }else{
                let res = await axios.get(API_BASE_URL+'debate/public/'+this.props.debateID);
                let response=res.data.data;
                this.setState({debate_detail:response})
              }
              if(id==this.state.debate_detail.debater_uid){
                if(this.state.show_debater_id==""){
                  this.setState({
                    show_debater_id:this.state.debater_id
                  })
                }
                item.play("remoteStream_div"); 
              }else{
                let res = await axios.get(API_BASE_URL+'debate/public/'+this.props.debateID);
                let response=res.data.data;
                this.setState({debate_detail:response})
              }
            
            }else{

              var remoteActive = document.getElementById('remoteStream_div').innerHTML === "";
          
              if(remoteActive==true){    
               
                // if(this.state.challenger_id==""){
                //   this.setState({
                //     challenger_id:this.state.remoteStreamArrObj[index].user
                //   })
                // }

                if(id==this.state.debate_detail.challenger_uid){
                  if(this.state.show_challenger_id==""){
                    this.setState({
                      show_challenger_id:this.state.challenger_id
                    })
                  }
                  item.play("localStream_div",{audio: false, video: false});
                  //alert('challenger_id is left')
                }else{
                  let res = await axios.get(API_BASE_URL+'debate/public/'+this.props.debateID);
                  let response=res.data.data;
                  this.setState({debate_detail:response})
                }
                if(id==this.state.debate_detail.debater_uid){
                  if(this.state.show_debater_id==""){
                    this.setState({
                      show_debater_id:this.state.debater_id
                    })
                  }
                  item.play("remoteStream_div");  
                }else{
                  let res = await axios.get(API_BASE_URL+'debate/public/'+this.props.debateID);
                  let response=res.data.data;
                  this.setState({debate_detail:response})
                }
                
              }else{

                
                var shareActive = document.getElementById('shareStream_div').innerHTML === "";
          
                if(shareActive==true){    
                 
                  item.play("shareStream_div");  
                  
                }else{
                  if(this.state.remote_col!=="col-md-2 thumbnail_cols"){ 
                    this.setState({
                      remote_col:"col-md-2 thumbnail_cols",
                      remote_div_inner:"video_deal_thumbnaila",
                      enableShareScreen:"debate_left active"
                    })
                  }
                }
              }
            }  
                 
            // item.player.resize && item.player.resize();
          });
   
        }
      }
    }
    // screen share mode (tbd)
    else if (this.state.displayMode === "share") {
    }


    // let no = this.state.remoteStreamList.length;
    // this.state.remoteStreamList.map((item, index) => {
    //   console.log(item)
    //   console.log('item')
    //   let id = item.getId();
    //   let dom = document.querySelector("#ag-item-" + id);
    //   if (!dom) {
    //     //alert('hhhh')
    //     dom = document.createElement("section");
    //     dom.setAttribute("id", "ag-item-" + id);
    //     dom.setAttribute("class", "ag-item");
    //     canvas.appendChild(dom);
    //     item.play("ag-item-" + id);
    //   }
    //   dom.setAttribute("style", `grid-area: ${tile_canvas[no][index]}`);
    //   item.player.resize && item.player.resize();
    // });
  }
    
  }

  componentWillUnmount() {
    // alert('hiii')
    this.client && this.client.unpublish(this.localStream);
    this.localStream && this.localStream.close();
    if(this.state.stateSharing) {
      this.shareClient && this.shareClient.unpublish(this.shareStream);
      this.shareStream && this.shareStream.close();
    }
    this.client &&
      this.client.leave(() => {
          console.log("Client succeed to leave.");
        },
        () => {
          console.log("Client failed to leave.");
        }
      );
  }

  streamInit = (uid, attendeeMode, videoProfile, config) => {
    
    // alert('uid');
    // alert(uid);
    // alert('uid');
    if(this.state.debate_detail.userId==loginUser){
      axios.post(API_BASE_URL+'update-uid',{loginUser:loginUser,uid:uid,action:'challenger',debate_id:this.props.debateID})
    }

    if(this.state.debate_detail.debate_with==loginUser){
      axios.post(API_BASE_URL+'update-uid',{loginUser:loginUser,uid:uid,action:'debater',debate_id:this.props.debateID})
    }
    console.log(attendeeMode)
    console.log("videoPrifile",videoProfile)
    let defaultConfig = {
      streamID: uid,
      audio: true,
      video: true,
      screen: false,
    };

    switch (attendeeMode) {
      case "audio-only":
        defaultConfig.video = false;
        break;
      case "audience":
        defaultConfig.video = false;
        defaultConfig.audio = false;
        break;
      default:
      case "video":
        break;
    }

    let stream = AgoraRTC.createStream(merge(defaultConfig, config));
    stream.setVideoProfile(videoProfile);
    return stream;

  };
  onRemoteClientAdded = (evt) => {
    let stream = evt.stream;
    // alert('hii2')

    console.log("Got stream-subscribed event");
    console.log(new Date().toLocaleTimeString());
    console.log("Subscribe remote stream successfully: " + stream.getId());
    console.log(evt);
    // this.state.remoteStreams[stream.getId()].play(
    //   "agora_remote"
    // );
    // alert('hiii')
    // let strm=this.localStream;
    this.setState({
      remoteStreamArr: [stream].concat(this.state.remoteStreamArr),
    });
    //alert(twitterHandle)
    var ob={
      video:stream,
      user:localStorage.getItem('twitterHandle')
    }


    this.setState({
      remoteStreamArrObj: this.state.remoteStreamArrObj.concat([ob]),
    });
    //console.log
    // this.setState({remoteStreamArr:stream});
    this.addStream(stream);
  }

  onRemoteSharedScreen = (evt) => {
    let stream = evt.stream;
    // alert('sharing')
    console.log("Got stream-subscribed event");
    console.log(new Date().toLocaleTimeString());
    console.log("Subscribe remote stream successfully: " + stream.getId());
    console.log(evt);
     
    this.setState({
      shareScreenArr: [stream].concat(this.state.shareScreenArr),
    });
    this.addStream(stream);
  }

  subscribeStreamEvents = (status=false) => {
    let rt = this;
    rt.client.on("stream-added", function (evt) {
      //alert('hii')
      let stream = evt.stream;     
      console.log("New stream added: " + stream.getId());
      console.log("At " + new Date().toLocaleTimeString());
      console.log("Subscribe ", stream);
      rt.client.subscribe(stream, function (err) {
        console.log("Subscribe stream failed", err);
      });
    });

    rt.client.on("peer-leave", function (evt) {

      let stream = evt.stream;

      //alert( 'UID' + stream.getId() );
      console.log("Peer has left: " + evt.uid);
      console.log(new Date().toLocaleTimeString());
      console.log(evt);
      //alert('fuck left');
      rt.removeStream(evt.uid);
    });

    if(status==false){
      // alert('joined')
      rt.client.on("stream-subscribed", this.onRemoteClientAdded);

    }else if(status==true){
      rt.client.on("stream-subscribed", this.onRemoteSharedScreen);
    }

    rt.client.on("stream-removed", function (evt) {
      let stream = evt.stream;
      console.log("Stream removed: " + stream.getId());
      console.log(new Date().toLocaleTimeString());
      console.log(evt);
      rt.removeStream(stream.getId());
    });
  };

  removeStream = (uid) => {

    //alert('hii')
    //toast.warning('Debater has been left.', {autoClose:3000});
    this.state.streamList.map((item, index) => {
      if (item.getId() === uid) {
        item.close();
        let element = document.querySelector("#ag-item-" + uid);
        if (element) {
          element.parentNode.removeChild(element);
        }
        let tempList = [...this.state.streamList];
        tempList.splice(index, 1);
        this.setState({
          streamList: tempList,
        });

         

      }
    });
     // alert(uid)

    this.state.remoteStreamArr.map((item, index) => {
      // alert(item.getId())
      // alert(uid)
      // alert('uid end')
      if (item.getId() === uid) {
        item.close();
        let element = document.querySelector("#player_" + uid);
        console.log(element);
        console.log("element rewmo");
        //alert('removed')
        if (element) {
          element.parentNode.removeChild(element);
        }
         

        let tempList1 = [...this.state.remoteStreamArr];
        tempList1.splice(index, 1);
        this.setState({
          remoteStreamArr: tempList1,
        });

      }
    });
  };

  addStream = (stream, push = false) => {
    let repeatition = this.state.streamList.some((item) => {
      return item.getId() === stream.getId();
    });
    if (repeatition) {
      return;
    }
    if (push) {
      this.setState({
        streamList: this.state.streamList.concat([stream]),
      });
    } else {
      this.setState({
        streamList: [stream].concat(this.state.streamList),
      });
    }
  };
  addAudienceStream= (stream, push = false) => {
    let repeatition = this.state.audienceStreamList.some((item) => {
      return item.getId() === stream.getId();
    });
    if (repeatition) {
      return;
    }
    if (push) {
      this.setState({
        audienceStreamList: this.state.audienceStreamList.concat([stream]),
      });
    } else {
      this.setState({
        audienceStreamList: [stream].concat(this.state.audienceStreamList),
      });
    }
  };
  addRemoteStream = (stream, push = false) => {
    let repeatition = this.state.remoteStreamList.some((item) => {
      return item.getId() === stream.getId();
    });
    if (repeatition) {
      return;
    }
    if (push) {
      this.setState({
        remoteStreamList: this.state.remoteStreamList.concat([stream]),
      });
    } else {
      this.setState({
        remoteStreamList: [stream].concat(this.state.remoteStreamList),
      });
    }
  };

  handleCamera = async (e) => {
    e.currentTarget.classList.toggle("off");
    if(this.state.video_icon=="fal fa-video-slash"){
      this.setState({
       video_icon: "fal fa-video",
       //active_remote:""
      })
    }else{

      this.setState({
       video_icon: "fal fa-video-slash",
       //active_remote:"hide"
      })
    }
 
    let sendTo='';
    

    if(parseInt(loginUser)===parseInt(this.state.debate_detail.userId))
    {
      sendTo=this.state.debate_detail.debate_with
    }
    if(parseInt(loginUser)===parseInt(this.state.debate_detail.debate_with))
    {
      sendTo=this.state.debate_detail.userId
    }
     
      

    if(this.state.videomuteStatus == 'unmute'){
        
       await this.setState({videomuteStatus:'mute'})
      
    }else{
     await this.setState({videomuteStatus:'unmute'})
    }
    this.localStream.isVideoOn() ? this.localStream.disableVideo() : this.localStream.enableVideo();
    
    this.toggleScreenShow()
    globalSocket.emit('mute_camera',{profile:this.state.profilePic,actionBy:loginUser,debate_id:this.state.debate_detail.id,sendTo:sendTo,action:this.state.videomuteStatus})
  
  };

  toggleScreenShow(){
    // this.state.showScreen=!this.state.showScreen
    if(this.state.cameraclasshide=="deal_video cameraclasshide"){
      
      this.setState({
        cameraclasshide:"deal_video cameraclassshow",  //display block
        cameraclassshow:"deal_video cameraclasshide",   //display none
      })
      
    }else{
      this.setState({
        cameraclassshow:"deal_video cameraclassshow",  
        cameraclasshide:"deal_video cameraclasshide"
      })
    }
    this.setState({showScreen : !this.state.showScreen})
  }

  handleMic = async (e) => {
    //e.currentTarget.classList.toggle("off");
    console.log('hancle camera clicked')
    if(this.props.attendeeMode!='audience'){

      if(this.state.mike_icon=="fal fa-microphone-slash"){
        this.setState({
         mike_icon: "fal fa-microphone",
        
        })
      }else{
        this.setState({
         mike_icon: "fal fa-microphone-slash",
       

        })
      }
      this.localStream.isAudioOn()? this.localStream.disableAudio() : this.localStream.enableAudio();
      
      
      if(this.state.muteStatus == 'unmute'){
        
         await this.setState({muteStatus:'mute'})
        
      }
      else{
       await this.setState({muteStatus:'unmute'})
      }
      let sendTo='';
      

      if(parseInt(loginUser)===parseInt(this.state.debate_detail.userId))
      {
        sendTo=this.state.debate_detail.debate_with
      }
      if(parseInt(loginUser)===parseInt(this.state.debate_detail.debate_with))
      {
        sendTo=this.state.debate_detail.userId
      }
       
      globalSocket.emit('mute_control',{actionBy:loginUser,debate_id:this.state.debate_detail.id,sendTo:sendTo,action:this.state.muteStatus})
    } 
    
  };

  switchDisplay = (e) => {
    if (
      e.currentTarget.classList.contains("disabled") ||
      this.state.streamList.length <= 1
    ) {
      return;
    }
    if (this.state.displayMode === "pip") {
      this.setState({ displayMode: "tile" });
    } else if (this.state.displayMode === "tile") {
      this.setState({ displayMode: "tile" });
    } else if (this.state.displayMode === "share") {
      // do nothing or alert, tbd
    } else {
      console.error("Display Mode can only be tile/pip/share");
    }
  };

  hideRemote = (e) => {
    if (
      e.currentTarget.classList.contains("disabled") ||
      this.state.streamList.length <= 1
    ) {
      return;
    }
    let list;
    let id = this.state.streamList[this.state.streamList.length - 1].getId();
    list = Array.from(
      document.querySelectorAll(`.ag-item:not(#ag-item-${id})`)
    );
    list.map((item) => {
      if (item.style.display !== "none") {
        item.style.display = "none";
      } else {
        item.style.display = "block";
      }
    });
  };

  handleExit = async (e) => {

    Swal.fire({
      title: 'Do you want to exit?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Yes', 
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // if(e.currentTarget.classList.contains("disabled")) {
        //     return;
        // }

         let accessToken=localStorage.getItem('accessToken')
         if(accessToken !== undefined && accessToken !=='' && accessToken !==null){
          var isuploaded= await this.stopRecording();
          //alert('Uploaded '+ isuploaded)
          if(isuploaded){
            
            try {
              
                globalSocket.emit( 'debater_leaved', {debate_id:this.props.debateID,msg:'Debater has been left',user:loginUser} ) 
                this.client && this.client.unpublish(this.localStream);
                this.localStream && this.localStream.close();
                if (this.state.stateSharing) {
                  this.shareClient && this.shareClient.unpublish(this.shareStream);
                  this.shareStream && this.shareStream.close();
                }
                this.client &&
                  this.client.leave(
                    () => {
                      console.log("Client succeed to leave.");
                    },
                    () => {
                      console.log("Client failed to leave.");
                    }
                  );
            } finally {
              
              this.setState({ readyState: false });
              this.client = null;
              this.localStream = null;
             
              // redirect to index
  
              setTimeout(function() {
           
                window.location.assign(BASE_URL)
              }, 2000);
              // window.location.hash = "upcoming-debates";
              // window.location.assign(BASE_URL+"upcoming-debates")
              // this.props.history.push("#/upcoming-debates")
            }
  
        
          }
        } else if (result.isDenied) {
          // Swal.fire('Changes are not saved', '', 'info')
        }
         }else{
          
          setTimeout(function() {
          
            window.location.assign(BASE_URL)
          }, 1500);
         }
       
    })

    //return;
    // const history = useHistory();
      
  };

  sharingScreen = (e) => {
    
    if (this.state.stateSharing) {
    
      //alert('remove screen');
      this.setState({
        remote_col:"col-md-6 thumbnail_cols", 
        share_icon:"fal fa-share-square"
      })

      if(this.state.show_remote_image=="deal_video hide"){
        this.setState({
          remote_div_inner :'video_deal_thumbnaila deal_video',
          enableShareScreen:"debate_left"
        })
      }else{

      }
      this.shareClient && this.shareClient.unpublish(this.shareStream);
      //this.shareStream && this.shareStream.close();
      this.state.stateSharing = false;
      // alert(this.state.share_icon)
    } else {
      
      this.setState({
       share_icon: "fas fa-window-close"     
      })
      this.state.stateSharing = true;
      // alert(this.state.stateSharing+"===sahre")
      
      let $ = this.props;
      // init AgoraRTC local client
      this.shareClient = AgoraRTC.createClient({ mode: $.transcode });
      this.shareClient.init($.appId, () => {
        console.log("AgoraRTC client initialized");
        this.subscribeStreamEvents(true);
        this.shareClient.join($.appId, $.channel, $.uid, (uid) => {
          this.state.uid = uid;
          console.log("User " + uid + " join channel successfully");
          console.log("At " + new Date().toLocaleTimeString());
          // create local stream
          // It is not recommended to setState in function addStream
          this.shareStream = this.streamInitSharing(
            uid,
            $.attendeeMode,
            $.videoProfile
          );
          this.shareStream.init(
            () => {
              if ($.attendeeMode !== "audience") {
                this.addStream(this.shareStream, true);
                let strm=this.shareStream;
                this.setState({
                  shareScreenArr: [strm].concat(this.state.shareScreenArr),
                });
                // this.setState({shareScreenArr:this.shareStream})
                this.shareClient.publish(this.shareStream, (err) => {
                  console.log("Publish local stream error: " + err);
                });
              }
              this.setState({ readyState: true });
            },
            (err) => {
              console.log("getUserMedia failed", err);
              this.setState({ readyState: true });
            }
          );
        });
      });
    }
  };

  streamInitSharing = (uid, attendeeMode, videoProfile, config) => {
    let defaultConfig = {
      streamID: uid,
      audio: false,
      video: false,
      screen: true,
    };

    switch (attendeeMode) {
      case "audio-only":
        defaultConfig.video = false;
        break;
      case "audience":
        defaultConfig.video = false;
        defaultConfig.audio = false;
        break;
      default:
      case "video":
        break;
    }

    let stream = AgoraRTC.createStream(merge(defaultConfig, config));
    stream.setVideoProfile(videoProfile);
    return stream;
  };


  
  render() {
    var token = localStorage.getItem('accessToken');
    const style = {
      display: "grid",
      gridGap: "10px",
      alignItems: "center",
      justifyItems: "center",
      gridTemplateRows: "repeat(12, auto)",
      gridTemplateColumns: "repeat(24, auto)",
    };
    const videoControlBtn =
      this.props.attendeeMode === "video" ? (
        <span
          onClick={this.handleCamera}
          className="ag-btn videoControlBtn"
          title="Enable/Disable Video"
        >
          <i className="ag-icon ag-icon-camera"></i>
          <i className="ag-icon ag-icon-camera-off"></i>
        </span>
      ) : (
        ""
      );

    const audioControlBtn =
      this.props.attendeeMode !== "audience" ? (
        <span
          onClick={this.handleMic}
          className="ag-btn audioControlBtn"
          title="Enable/Disable Audio"
        >
          <i className="ag-icon ag-icon-mic"></i>
          <i className="ag-icon ag-icon-mic-off"></i>
        </span>
      ) : (
        ""
      );

    const switchDisplayBtn = (
      <span
        onClick={this.switchDisplay}
        className="ag-btn displayModeBtn"
        title="Switch Display Mode"
      >
        <i className="fa fa-tv ag-icon ag-icon-switch-display"></i>
      </span>
    );
    const hideRemoteBtn = (
      <span
        className={
          this.state.streamList.length > 4 || this.state.displayMode !== "pip"
            ? "ag-btn disableRemoteBtn disabled"
            : "ag-btn disableRemoteBtn"
        }
        onClick={this.hideRemote}
        title="Hide Remote Stream"
      >
        <i className="ag-icon ag-icon-remove-pip"></i>
      </span>
    );
    const exitBtn = (
      <span
        onClick={this.handleExit}
        className={
          this.state.readyState ? "ag-btn exitBtn" : "ag-btn exitBtn disabled"
        }
        title="Exit"
      >
        <i className="ag-icon ag-icon-leave"></i>
      </span>
    );

    const timer = (
      <span style={{color:"white"}}>  04:00 </span>
    );

    return (
      <div>
      {(this.state.isAudience==false)?(
        <div class="">
          <div class={this.state.enableShareScreen}>
            <div class="row" id="ag-canvas">
              <div class="col-md-12">
                
                <div class="row">
                 
                  <div class="col-md-6">
                  <div class="timer_left">
                   
                  
                    {this.state.show_challenger==true && this.state.debate_detail.userId==loginUser  ? (
                      <span class="time_span 33"><i class="fas fa-circle"></i>{(this.state.time.s>0)?this.state.time.m:""}:{(this.state.time.s>0)?this.state.time.s:""}</span>
                    ):""}

                    {this.state.show_debater==true && this.state.debate_detail.debate_with==loginUser  ?(
                      <span class="time_span 44"><i class="fas fa-circle"></i>{(this.state.time.s>0)?this.state.time.m:""}:{(this.state.time.s>0)?this.state.time.s:""}</span>
                    ):""}
                  </div>
                  </div>
                  <div class="col-md-6 timer_col">
                    <div class="timer_right">
                    
                    {this.state.show_challenger==true && this.state.debate_detail.debate_with==loginUser ?(
                    <div class="timer_text ag-btn-group active">
                      <span class="time_span 11"><i class="fas fa-circle"></i>{(this.state.time.s>0)?this.state.time.m:""}:{(this.state.time.s>0)?this.state.time.s:""}</span>
                    </div>
                  ):""}
   
                  {this.state.show_debater==true && this.state.debate_detail.userId==loginUser  ?(
   
                    <div class="timer_text ag-btn-group active">
                      <span class="time_span 22"><i class="fas fa-circle"></i>{(this.state.time.s>0)?this.state.time.m:""}:{(this.state.time.s>0)?this.state.time.s:""}</span>
                    </div>
                  ):""}
                  </div>
                  </div>
                </div>
   
                <div class="row">
   
            <div class="col-md-4 video_col">
        
            <div className={this.state.show_challenger==true && this.state.control_enabled_to==loginUser ?this.state.active_local:""} style={{border: parseInt(this.state.control_enabled_to) === parseInt(loginUser) ? "solid green" : ""}} >
                   
                <div className={this.state.cameraclassshow} id="localStream_div"></div>
                <img class={this.state.cameraclasshide} src={this.state.profilePic} id="ss" />

            
              </div>
              <div className="get_center">{twitterHandle}</div>

              
            </div>
   
                  <div class="col-md-6 share_col thumbnail_col">
                    <div class="video_deal_thumbnail" id="shareStream_div"> 
                    </div>
                  </div>
                  <div class={this.state.remote_col}>
                  
                    <div className={this.state.show_challenger==false && this.state.control_enabled_to==loginUser?this.state.active_remote:""} style={{border: (parseInt(this.state.control_enabled_to) !== parseInt(loginUser) && this.state.control_enabled_to!="") ? "solid green" : ""}}>
                      <div class={this.state.remote_div_inner} id="remoteStream_div"> 
                        
                      </div>
                      <img class={this.state.show_remote_image} src={this.state.remote_image} id="ss" />
                    </div>
                    <div className="get_center">
                      {this.state.showRemoteMute == true ?(<i className="fal fa-microphone-slash" style={{color:'red'}}></i>) : ''}
                      {this.state.setTwitterHandle}
                    </div>
                     
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <input type="hidden" value={this.state.hiddenvalue}/>
                    <ul class="deal_ul"> 
                    
                      {this.props.attendeeMode !== "audience" && this.state.control_enabled_to==loginUser && token != 'null' ? (<li><a href="javascript:void(0)" onClick={this.handleCamera}><i class={this.state.video_icon}></i></a></li>):""}
                      {this.props.attendeeMode !== "audience" && this.state.control_enabled_to==loginUser  && token != 'null' ? (<li><a href="javascript:void(0)" ref={this.simulateClick}  onClick={this.handleMic}><i class={this.state.mike_icon}></i></a></li>):""}
                      {this.props.attendeeMode !== "audience" && this.state.control_enabled_to==loginUser && token != 'null' ? (<li className="shareacreen_hide"><a href="javascript:void(0)" onClick={this.sharingScreen}><i class={this.state.share_icon}></i></a></li>):""}

                      {this.props.attendeeMode !== "audience" && this.state.control_enabled_to=="" && token != 'null' ? (<li><a href="javascript:void(0)" onClick={this.handleCamera}><i class={this.state.video_icon}></i></a></li>):""}
                      {this.props.attendeeMode !== "audience" && this.state.control_enabled_to=="" && token != 'null' ? (<li><a href="javascript:void(0)" onClick={this.handleMic}><i class={this.state.mike_icon}></i></a></li>):""}
                      {this.props.attendeeMode !== "audience" && this.state.control_enabled_to=="" && token != 'null' ? (<li className="shareacreen_hide"><a href="javascript:void(0)" onClick={this.sharingScreen}><i class={this.state.share_icon}></i></a></li>):""}
                      
                      <li><a href="javascript:void(0)" id="total_audience"> {this.state.live_users} <i class="fal fa-eye"></i></a></li>

                       
                      {this.props.attendeeMode !== "audience" && this.state.control_enabled_to==loginUser && token != 'null' ? (<li><a href="javascript:void(0)" onClick={this.toggleAccess}><i class="fal fa-sync-alt"></i></a></li>):""}

                      {this.props.attendeeMode !== "audience" ? (<li><a class="end_call" href="javascript:void(0)" onClick={this.handleExit}><i class="fal fa-phone-alt"></i></a></li>):""} 
                      
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div> 
           
        </div>
        ):(
          <div class="">
            <div class={this.state.enableShareScreen}>
              <div class="row" id="ag-canvas">
                <div class="col-md-12">
                  
                  <div class="row">
                   
                    <div class="col-md-6">
                    <div class="timer_left">
                     
                    {this.state.show_challenger==true && this.state.debate_detail.debate_with==loginUser ?(
                      <div class="timer_text ag-btn-group active hide">
                        <span class="time_span 11"><i class="fas fa-circle"></i>{this.state.time.m}:{this.state.time.s}</span>
                      </div>
                    ):""}
     
                    {this.state.show_debater==true && this.state.debate_detail.userId==loginUser  ?(
     
                      <div class="timer_text ag-btn-group active hide">
                        <span class="time_span 22"><i class="fas fa-circle"></i>{this.state.time.m}:{this.state.time.s}</span>
                      </div>
                    ):""}
                      
                    </div>
                    </div>
                    <div class="col-md-6 timer_col">
                      <div class="timer_right">
                      
                      {this.state.show_challenger==true && this.state.debate_detail.userId==loginUser  ? (
                        <span class="time_span 33"><i class="fas fa-circle"></i>{this.state.time.m}:{this.state.time.s}</span>
                      ):""}

                      {this.state.show_debater==true && this.state.debate_detail.debate_with==loginUser  ?(
                        <span class="time_span 44"><i class="fas fa-circle"></i>{this.state.time.m}:{this.state.time.s}</span>
                      ):""}
                    </div>
                    </div>
                  </div>
     
                  <div class="row">
     
              <div class="col-md-4 video_col">
          
              <div className={this.state.show_challenger==true && this.state.control_enabled_to==loginUser ?this.state.active_local:""} >
                     
                  <div className={this.state.cameraclassshow} id="localStream_div"></div>
                  <img class={this.state.cameraclasshide} src={this.state.profilePic} id="ss" />

              
                </div>
                <div className="get_center">
                  {/* {this.state.debater_id} */}
                  {loginUser !==null && loginUser !==undefined ? (<span>{this.state.show_challenger_id}</span>) : (<span>{this.state.show_challenger_id}</span>) }
                  </div>

                
              </div>
     
                    

                           
                    <div class="col-md-6 share_col thumbnail_col">
                      <div class="video_deal_thumbnail" id="shareStream_div"> 
                      </div>
                    </div>
                    <div class={this.state.remote_col}>
                    
                      <div className={this.state.show_challenger==false && this.state.control_enabled_to==loginUser?this.state.active_remote:""}>
                        <div class={this.state.remote_div_inner} id="remoteStream_div">
                         
                        </div>
                      </div>
                      <div className="get_center">
                        {this.state.showRemoteMute == true ?(<i className="fal fa-microphone-slash" style={{color:'red'}}></i>) : ''}
                        
                        {loginUser !==null && loginUser !==undefined ? (<span>{this.state.show_debater_id}</span>) : (<span>{this.state.show_debater_id}</span>) }
                      </div>
                       
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
              
                      <ul class="deal_ul"> 
                      
                        {this.props.attendeeMode !== "audience" && this.state.control_enabled_to==loginUser && token != 'null' ? (<li><a href="javascript:void(0)" onClick={this.handleCamera}><i class={this.state.video_icon}></i></a></li>):""}
                        {this.props.attendeeMode !== "audience" && this.state.control_enabled_to==loginUser  && token != 'null' ? (<li><a href="javascript:void(0)" ref={this.simulateClick}  onClick={this.handleMic}><i class={this.state.mike_icon}></i></a></li>):""}
                        {this.props.attendeeMode !== "audience" && this.state.control_enabled_to==loginUser && token != 'null' ? (<li><a href="javascript:void(0)" onClick={this.sharingScreen}><i class={this.state.share_icon}></i></a></li>):""}

                        {this.props.attendeeMode !== "audience" && this.state.control_enabled_to=="" && token != 'null' ? (<li><a href="javascript:void(0)" onClick={this.handleCamera}><i class={this.state.video_icon}></i></a></li>):""}
                        {this.props.attendeeMode !== "audience" && this.state.control_enabled_to=="" && token != 'null' ? (<li><a href="javascript:void(0)" onClick={this.handleMic}><i class={this.state.mike_icon}></i></a></li>):""}
                        {this.props.attendeeMode !== "audience" && this.state.control_enabled_to=="" && token != 'null' ? (<li><a href="javascript:void(0)" onClick={this.sharingScreen}><i class={this.state.share_icon}></i></a></li>):""}
                        
                        <li><a href="javascript:void(0)" id="total_audience"> {this.state.live_users} <i class="fal fa-eye"></i></a></li>

                         
                        {this.props.attendeeMode !== "audience" && this.state.control_enabled_to==loginUser && token != 'null' ? (<li><a href="javascript:void(0)" onClick={this.toggleAccess}><i class="fal fa-sync-alt"></i></a></li>):""}

                        <li><a class="end_call" href="javascript:void(0)" onClick={this.handleExit}><i class="fal fa-phone-alt"></i></a></li> 
                        
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
             
          </div>
        )
      }
      </div>
    );
  }
}

export default AgoraCanvas;
