import React, { Component,useRef, Button } from "react";
import * as Cookies from 'js-cookie'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import moment from "moment";
import DatePicker from "react-datepicker";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import '../../assets/fonts/css/icons.css';
import ReactDOM from 'react-dom';
import 'rc-time-picker/assets/index.css';
import "react-datepicker/dist/react-datepicker.css";
import '../../assets/fonts/css/black-dashboard.css';
import '../../assets/fonts/css/demo.css';
import '../../assets/fonts/css/bootstrap.min.css';
import '../../assets/fonts/css/style.css';
import Validator from '../../utils/Validator';
import { RESOLUTION_ARR } from '../../utils/Settings';
import Header from '../common/DashboardHeader';
import Footer from '../common/DashboardFooter';
import Sidebar from '../common/DashboardSidebar';
import { API_BASE_URL } from "../constants/AppConstants";
import TimePicker from 'react-time-picker';
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import DataTable, { createTheme } from 'react-data-table-component-with-filter';
toast.configure();


// import DatePicker from 'react-date-picker';
const axios = require('axios').default;
const loginUser= localStorage.getItem('loginUser')


const format = 'hh:mm a';

export default class Users extends Component {
  constructor(props) {
    super(props)
    this.state = {
      joinBtn: false,
      channel: '',
      baseMode: '',
      transcode: '',
      attendeeMode: '',
      videoProfile: '',
      debateDuration:'',
      userName:'',
      userEmail:'',
      userStatus:'',
      filter:'',
      allDebates:[],
      debateData:[], 
      upcomingDebates:[],
      copied:false,
      copyBtn:"Copy",
      debateInvitations:[],
      show: false,
      allUsers:[],
      debateDuration:'',
      startDate:new Date(),
      debateTime:'', 
      timezone:'',
      debateDurationErr:'',
      userNameErr:'',
      userEmailErr:'',
      userStatusErr:'',
      userErr:'', 
      user:'', 
      timezoneErr:''

    }
  } 

  handleChange = async (date) => {
    
    var newdate=moment(date.toISOString()).format();
    var debate_date=newdate.split('T');

    this.setState({
      debateDate: date,
      filter: debate_date[0]
    })
  }

  
  componentDidMount() {
    this.getDebates();
 
  }
 
  getDebates = async () => {
    let response = await axios.get(API_BASE_URL+'users/');
    
    console.log(response.data.data);
    console.log('response.data.data');
    this.setState({
      allDebates: response.data.data 
    }); 

   let users = await axios.get(API_BASE_URL+'users');
   
   console.log(users.data.data);
   // console.log('data.data[0]');
    this.setState({
      allUsers: users.data.data
    }); 

  }

  deleteDebate = async (id) => {
   
   // preventDefault();
   let data = await axios.delete(API_BASE_URL+'users/'+id, {'id':id});
   //NotificationManager.warning('', 'You have deleted the user..'); 
   toast.success('You have deleted the user.', {autoClose:3000});
   console.log(this.state);
   this.getDebates();
 }

  cancelDebate=async(id)=>{
    let data = await axios.delete(API_BASE_URL+'debate',{'debate_id':id,'user_id':1});
    //NotificationManager.warning('', 'You have cancelled the debate invitation..');

    toast.success('You have cancelled the debate invitation..', {autoClose:3000});
    console.log(this.state); 
    this.getDebates();
  }
  inviteDebate=async(id)=>{
    Cookies.set('debate_id', id);
    // alert(id);
    setTimeout(function(){ 
        //window.location.hash = "my-created-debates"
        window.location.hash = "#/debate/invite/"+id;
    }, 1000);

  }
  filter=async()=>{
    // alert(this.state.debateDate)
    // var s=date.split('T');
    let response = await axios.post(API_BASE_URL+'debate/filter',{'debate_date':this.state.filter,userId:loginUser,'type':'pending'});
    this.setState({
      allDebates: response.data.data 
    });

  }


  handleClose = async (date) => {

   this.setState({
     show: false
   })
 }

  handleShow = async (id) => { 

   let data = await axios.get(API_BASE_URL+'users/'+id, {'user_id':1});
   console.log(data.data);
   this.setState({
      show: true,
      userId: data.data.id,
      userData: data.data,
      userName: data.data.name,
      userEmail: data.data.secondary_email,
      userStatus: data.data.login_access
    })
  }

  formValidation = (e) => {
       
   let isValid = true;
   
   
   if(this.state.userName==='' || this.state.userName===undefined){ 
    var isValidName = false;
       this.setState({
        userNameErr: "Please enter the Name"
       });
       
   }else{
    var isValidName= true;
       this.setState({
        userNameErr: ""
       });
   }

   if(this.state.userStatus==='' || this.state.userStatus===undefined){ 
    var isValidStatus= false;
       this.setState({
        userStatusErr: "Please Select the status"
       });
   }else{
    var isValidStatus= true;
     this.setState({
      userStatusErr: ""
     });
   }

   if(isValidStatus  && isValidStatus ){
    return true
   }
   return false;
}

handleUpdate = async () => {
  
   var isValid= await this.formValidation();

   if(isValid===true){
     
     console.log(this.state);
   
    
   
     var payload= {

       'name':this.state.userName,
       // 'email':this.state.userEmail,
       'secondary_email':this.state.userEmail,
       'login_access':this.state.userStatus,
       'id':this.state.id,
     } 
      console.log(payload);

     // return;
     
     await axios.put(API_BASE_URL+'users/'+this.state.userId, payload).then(function (response) {
       //this.createNotification('success')
       console.log(response);
       if(response.data.status=='200'){         
        
         toast.success('User has been Updated.', {autoClose:3000});

       }else{

         //NotificationManager.warning('', response.data.message);

         toast.success(response.data.message, {autoClose:10000});
        
       }

     }).catch(function (error) {
       console.log(error);
     });
   }
   this.getDebates();
   if(isValid===true){
      
    this.setState({
     show: false
   });
    }
   
 }

 onChange = async (value) => {
   // function onChange(value) {
    // console.log(value.format('HH:mm A'))
     this.setState({
       debateTime: value
     })
     console.log(this.state);
   }




  render() {
    const {allDebates, allUsers} = this.state;
    console.log(this.state.allDebates)
    console.log('allDebates')

    const columns = [
      {
        name: 'Profile image',
        cell: row => <div><img src={row.profile_image} /></div>,          
       
        
      },
      {
            name: 'Name',
            selector: row => row.name,
            sortable: false,
            filterable: true,
      },
      {
         name: 'Email',
         selector: row => row.secondary_email,
         sortable: false,
         filterable: true,
      },
      {
         name: 'Status',
         selector: row => row.login_access,
         sortable: false,
         filterable: true,
      },
      {
         name: 'Action',      
         cell: row => <div>
            <a  onClick={ () => { this.handleShow(row.id) } } ><i class="fa fa-edit"></i></a>&nbsp;&nbsp;&nbsp;
            <a  onClick={ () => { if (window.confirm('Are you sure you wish to delete this item?')){ this.deleteDebate(row.id) } } } ><i class="fa fa-trash"></i></a>
            </div>,
       },
   ];

    return (
      <>
      <div class="wrapper">
        <Sidebar/>
        <Header/>
        <div class="main-panel">    
            <nav class="navbar navbar-expand-lg navbar-absolute navbar-transparent">
               <div class="container-fluid">
                  <div class="navbar-wrapper">
                     <div class="navbar-toggle d-inline">
                        <button type="button" class="navbar-toggler">
                        <span class="navbar-toggler-bar bar1"></span>
                        <span class="navbar-toggler-bar bar2"></span>
                        <span class="navbar-toggler-bar bar3"></span>
                        </button>
                     </div>
                 
                  </div>
               </div>
            </nav>
            <div class="modal modal-search fade" id="searchModal" tabindex="-1" role="dialog" aria-labelledby="searchModal" aria-hidden="true">
               <div class="modal-dialog" role="document">
                  <div class="modal-content">
                     <div class="modal-header">
                        <input type="text" class="form-control" id="inlineFormInputGroup" placeholder="SEARCH" />
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <i class="tim-icons icon-simple-remove"></i>
                        </button>
                     </div>
                     </div>               
               </div>
            </div>          
            <div class="content">
               <div class="row">
                  <div class="col-12">
                     <div class="dasbord_heading member_outer">
                        <h3>User Listing</h3>
                        {/* <div class="btn_outer"><button class="btn">Add Listing</button></div> */}
                     </div>
                  </div>
               </div>
               <div class="row custom_row">
                  <div class="col-md-12 datatable">

              

                  <DataTable
                        columns={columns}
                        data={this.state.allUsers}
                        pagination={true}
                        sortable={true}
                        className="rwd-table rwd-table2 shadow"

                  />

                  </div>
               </div>
            </div>
         
            
         </div>

    <Modal className="mainmodel" show={this.state.show} size="md" onHide={this.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
         <div class="tab_inputs">           

            <label class="custom_label">Name</label>
            <div style={{color:'red'}}>{this.state.userNameErr}</div>
            <input class="custom_input" type="text" value={this.state.userName} placeholder="" name="" onChange={e => this.setState({ userName: e.target.value })}/>

            <div class="row">
            <div class="col-md-12">
            <label class="custom_label">Email</label>
            <div style={{color:'red'}}>{this.state.userEmailErr}</div>
            <input class="custom_input" type="text" value={this.state.userEmail} placeholder="" name="" onChange={e => this.setState({ userEmail: e.target.value })}/>
            </div>
            
            </div>
            <div class="row">
            <div class="col-md-12">
            <label class="custom_label">Status</label>
            <div style={{color:'red'}}>{this.state.debateDurationErr}</div>
            {/*<input class="custom_input" type="text" placeholder="" name="" onChange={e => this.setState({ debateDuration: e.target.value })}/>*/}


            <select class="custom_input" value={this.state.userStatus} onChange={e => this.setState({ userStatus: e.target.value })} >
               <option value="">Select Status</option>
               <option value="pending">Pending</option>
               <option value="active">Active</option>
               <option value="blocked">Blocked</option>
               <option value="deleted">Deleted</option>
            </select>
            </div>
            
            </div>
            <a class="submit_btn" onClick={this.handleUpdate} disabled={!this.state.joinBtn}>Submit</a>
            </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>


        <NotificationContainer/>
        <Footer/>
      </div>
     
      </>
    )
  }
}

  