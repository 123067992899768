import React from 'react'
import * as Cookies from 'js-cookie'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { API_BASE_URL, BASE_URL } from "../constants/AppConstants";
import { withRouter } from 'react-router';
import '../../assets/fonts/css/icons.css'
import Validator from '../../utils/Validator'
import { RESOLUTION_ARR } from '../../utils/Settings'
// import TwitterLogin from "react-twitter-login";
import TwitterLogin from 'react-twitter-auth/lib/react-twitter-auth-component.js';
import queryString from 'query-string';


const  API_KEY= "CULeo4agzjLQ62wyIYSrDcRvS";
const  SECRET_KEY="w1XpAuBIGfdtXPuhpF9sotvRwSQmX02QsaPSy7nKE6GvSKj7Fl";
// const  API_KEY= "PyHxgJuyORZqhDiuKAne8LcxT";
// const  SECRET_KEY="RBqOgWJfflgk2GLGmKtHFnHituqvf3vROPfAqzOPpfKficIrI9";
const axios = require('axios').default;
class GetStarted extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      joinBtn: false,
      channel: '',
      baseMode: '',
      transcode: '',
      attendeeMode: '',
      videoProfile: '',
      userEmail:"",
      emailErr:"",
      showEmail: false,
      button:'Get Started',
      isActive:true,
      errorMessage:true,

    } 
  } 
  componentDidMount() {
    const {oauth_token, oauth_verifier} = queryString.parse(window.location.search);    
    var token= localStorage.getItem('accessToken');
    console.log(token)
    if(token!==null){
      if(token=="null"){
        window.location.hash = "get-started"
        //alert(localStorage.getItem('accessToken'));
      }else{

        setTimeout(function(){ 
          //window.location.hash = "upcoming-debates"
          //history.push('/blank');
          //window.location.reload();
        }, 100);
      }
    }
    // && token!=='undefined'
  }
 
  
  // async submitEmail() {
  submitEmail = async () => {
    // alert(this.state.userEmail);
    // console.log(this.state);
    if(this.state.userEmail=="" || this.state.userEmail==undefined){
      NotificationManager.success('', 'Please enter your email.');
      return;
    }
    const twitterHandle= localStorage.getItem('twitterHandle');
    let response= await axios.post(API_BASE_URL+'users/set-email',{twitterHandle:twitterHandle, email:this.state.userEmail});
    if(response){

      if(response.data.status=='200'){
        console.log(response.data.accessToken);
        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('loginUser', response.data.data.id);
        localStorage.setItem('loginUsername', response.data.data.name);
        localStorage.setItem('loginUserProfile', response.data.data.profile_image);
        NotificationManager.success('', 'Signed In successfully.');

         
        setTimeout(function() {
          window.location.assign(BASE_URL)
        }, 100);
        // this.props.history.push(BASE_URL+'#/upcoming-debates')
        this.setState({
          showEmail:true
        })
        
      }else{
        
        this.setState({
          isActive:false,
          errorMessage:"Dear @"+response.data.data.email+", your twitter handled is not approved by the admin. Please contact to the admin or wait for sometime"
        })
        //alert('You can not login');
        return NotificationManager.warning('', 'Access Denied...');
              
      }
    }
  }
 
 
  render() {
    const customHeader = {};
    customHeader['Test'] = 'test-header';
    const {showEmail} = this.state;
    return(
       <section class="section_space login_outer">
        <div class="container login_container">
          <div class="row align-items-center">
            <div class="col-md-6 login_left_col">
              <div class="login_left">
                <a class="logo" href="#"><img src="assets/images/logo.png" alt=""/></a>
                <img class="login-img" src="assets/images/login.png" alt=""/>
              </div>
            </div>
            <div class="col-md-6 login_col">
              <div class="login-right">
                <p style= {{color:"red"}}>{this.state.isActive==false? this.state.errorMessage:""}</p>
                <div class="login_title">Debate The Greatest Minds of &nbsp;Today</div>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <div class="login_title">Sign Into Rally Debates</div> 
                
                  <label class="custom_label">Enter you email</label>
                  <div style={{color:'red'}}>{this.state.emailErr}</div>
                  <div class="date_input">
                    <input class="custom_input" type="text" placeholder="" value={this.state.userEmail} name="" onChange={e => this.setState({ userEmail: e.target.value })}/> 
                  </div> 
                <a class="signin_btn" href="javascript:void(0)" onClick={this.submitEmail}><i class="fab fa-twitter"></i>{this.state.button}</a>
                <p class="copyright_text">Copyright © By Rally Debates 2021. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
        <NotificationContainer/>
      </section>
    )
  }
}

 

export default GetStarted