import React from 'react'
import * as Cookies from 'js-cookie'

import '../../assets/fonts/css/icons.css'
import Validator from '../../utils/Validator'
import { RESOLUTION_ARR } from '../../utils/Settings'
 


class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      joinBtn: false,
      channel: '',
      baseMode: '',
      transcode: '',
      attendeeMode: '',
      videoProfile: '',

    }
  }

  render() {
    return (
      <section class="footer_outer">
        <div class="container custom_container">
          <div class="row">
            <div class="col-md-12">
              <p class="copyright_text"></p>
            </div>
          </div>
        </div>
        
      </section>

      
    )
  }
}

 

export default Footer