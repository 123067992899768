import React from 'react'
import * as Cookies from 'js-cookie'

import '../../assets/fonts/css/icons.css'
import Validator from '../../utils/Validator'
import { RESOLUTION_ARR } from '../../utils/Settings'
 
import { useLocation, Link } from "react-router-dom";

class Sidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      joinBtn: false,
      channel: '',
      baseMode: '',
      transcode: '',
      attendeeMode: '',
      videoProfile: '',

    }

  }

   
 
  componentDidMount() {
    console.log(window.location.hash);
    // this.getDebates();
    // this.upcomingDebates();
    // this.debateInvitations();
 
  }
  render() {
    return ( 
      <div class="list-group" id="myList_1" role="tablist">
        <a className= {window.location.hash == '#/upcoming-debates' ? 'active list-group-item list-group-item-action' : 'list-group-item list-group-item-action'} href="/#/upcoming-debates" role="tab">Upcoming Debates <span class="notify">({this.props.upcoming})</span></a>
        <a className={window.location.hash == '#/pending-debates' ?  'active list-group-item list-group-item-action' : 'list-group-item list-group-item-action'}  href="/#/pending-debates" role="tab">Pending Debates <span class="notify">({this.props.pending})</span></a>
        {/*<a className={window.location.hash == '#/debate-invitations' ?  'active list-group-item list-group-item-action' : 'list-group-item list-group-item-action'}  href="/#/debate-invitations" role="tab">Debate Invitations</a>*/}
        {/*<a class={window.location.hash == '#/set-up-new-debate' ?  'active list-group-item list-group-item-action' : 'list-group-item list-group-item-action'}  href="#/set-up-new-debate" role="tab">Create Debate</a>*/}
        {/*<a class="list-group-item list-group-item-action"  href="#messages" role="tab">Cancel Debate</a>*/}
      </div>

    )
  }
}



export default Sidebar