import React, { Component } from "react";
import * as Cookies from 'js-cookie'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import moment from "moment";
import '../../assets/fonts/css/icons.css'
import Validator from '../../utils/Validator'
import { RESOLUTION_ARR } from '../../utils/Settings'
import Header from '../common/Header'
import Footer from '../common/Footer'
import Sidebar from '../common/Sidebar'
import { API_BASE_URL } from "../constants/AppConstants";
import TimePicker from 'react-time-picker';
import DatePicker from 'react-date-picker';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const axios = require('axios').default;
const loginUser= localStorage.getItem('loginUser');

toast.configure();

export default class DebateInvitations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      joinBtn: false,
      channel: '',
      baseMode: '',
      transcode: '',
      attendeeMode: '',
      videoProfile: '',
      debateDuration:'',
      debateTopic:'',
      debateDate:'',
      debateTime:'', 
      allDebates:[], 
      upcomingDebates:[],
      debateInvitations:[],

    }
    //this.state = this.initialState;


    // this.allDebates = this.allDebates.bind(this);
    //this.allDebates = this.allDebates.bind(this);
  }
  

   
  
  componentDidMount() {
 
    this.debateInvitations();
 
  }
 
 

  debateInvitations = async () => {
    let response = await axios.get(API_BASE_URL+'debate/invitations/'+loginUser);
   
    console.log(response.data.data);
    this.setState({
      debateInvitations: response.data.data 
    }); 
  } 

  acceptDebate = async (invitation_id,id) => {
    // alert(id);
    // preventDefault();
    let data = await axios.post(API_BASE_URL+'debate/accept',{'debate_id':id,'user_id':loginUser,invitation_id:invitation_id});
   
    //NotificationManager.success('Success', 'Thanks for accepting the debate invitation..');
    toast.success('Thanks for accepting the debate invitation.', {autoClose:3000});

    this.debateInvitations();
    console.log(this.state);
  }
  declineDebate = async (id) => {
    // alert(id);
    // preventDefault();
    let data = await axios.post(API_BASE_URL+'debate/decline',{'debate_id':id,'user_id':1});
    //NotificationManager.warning('', 'You have declined the debate invitation..');
    toast.warning('You have declined the debate invitation.', {autoClose:10000});
    console.log(this.state);
    this.debateInvitations();
  }
  
  createNotification = (type) => {
    return () => {
      switch (type) {
        case 'info':
          NotificationManager.info('Info message');
          break;
        case 'success':
          NotificationManager.success('Success message', 'Title here');
          break;
        case 'warning':
          NotificationManager.warning('Warning message', 'Close after 3000ms', 3000);
          break;
        case 'error':
          NotificationManager.error('Error message', 'Click me!', 5000, () => {
            alert('callback');
          });
          break;
      }
    };
  };


  handleJoin = async () => {

    //alert('jiii');
    // if (!this.state.joinBtn) {
    //   return
    // }

    console.log(this.state)
    Cookies.set('channel', this.state.channel)
    Cookies.set('baseMode', this.state.baseMode)
    Cookies.set('transcode', this.state.transcode)
    Cookies.set('attendeeMode', this.state.attendeeMode)
    Cookies.set('videoProfile', this.state.videoProfile)
    var payload= {
      'debate_duration':this.state.debateDuration,
      'topic':this.state.debateTopic,
      'debate_date':this.state.debateDate,
      'debate_time':this.state.debateTime,
      'userId':1
    } 
    await axios.post(API_BASE_URL+'debate',payload).then(function (response) {
      //this.createNotification('success')
      // alert('done');
      NotificationManager.success('', 'Debate has been created.');
    }).catch(function (error) {
      console.log(error);
    });
    //window.location.hash = "meeting"
  }
  render() {
    const {debateInvitations} = this.state;
    // console.log(this.state.allDebates)
    console.log('allDebates')
    return (
      <>
      <Header/>
      <section class="debate_outer">
        <div class="container custom_container">
          <div class="row">
            <div class="col-md-12">
              <div class="set_debate_main">              
                <div class="debate_title">Set Up A Debate</div>
                <a class="create_debate_btn1" href="#/set-up-new-debate">Create Debate</a>
              </div>
              <div class="debatetab_outer">
                <div class="debate_left">
                  <Sidebar/>
                </div>
                <div class="debate_right upcoming_debate_outer">
                  <div class="mt-2 tab-content">
                    <div class="tab-pane active" id="home" role="tabpanel">
                      <div class="tab_inner upcoming_debate">
                        <div class="share_div filter_outer">
                          <span class="filter_span">Filter</span>
                          <div class="date_input">
                          <input class="custom_input" type="date" placeholder="" name=""/>
                          <i class="fal fa-calendar"></i>
                          </div>
                        </div>
                        <ul class="upcoming_ul">
                           
                          {
                              this.state.debateInvitations.map((row,i) =>(   
                                <li key={i} className={ row.isAccepted == '0'? 'invite':''}>
                                  <div class="upcoming_date">{moment(row.debate_detail.debate_date).format("LL")} || {moment(row.debate_detail.debate_time, "HH:mm:ss").format("hh:mm A") }</div>
                                  <div class="upcoming_li_inner">
                                     <div class="upcoming_li_left">
                                      <img src="assets/images/profile.png" alt=""/>
                                      <div class="desc_div">
                                        <div class="name_title">Inviter name</div>
                                        <p>@Inviter name</p>
                                      </div>
                                     </div>
                                     <div class="upcoming_li_right">
                                      <p><span>Topic:</span> {row.topic}</p>
                                      <p><span>Video Link:</span> {row.debate_detail.debate_public_link}</p>
                                      <p><span>Public Link:</span> {row.debate_detail.debate_public_link}</p>
                                      { row.isAccepted == '0'? (<a class="submit_btn" href="javascript:void(0)" onClick={e=>this.acceptDebate(row.id,row.debate_detail.id)}>Accept Challenge</a>):''}
                                      { row.isAccepted == '0' ? (<a class="submit_btn decline_btn" href="javascript:void(0)" onClick={e=>this.declineDebate(row.debate_detail.id)}>Decline</a>):""}
                                     </div>
                                  </div>
                                </li> 
                              ))
                         }  
                        </ul>
                      </div>
                    </div>
                    <div class="tab-pane" id="profile" role="tabpanel">
                      <div class="tab_inner">
                        <div class="share_div"><a href="#"><i class="fas fa-share"></i>Share</a></div>
                        <div class="tab_inputs">
                          <label class="custom_label">Topic</label>
                          <input class="custom_input" type="text" placeholder="" name="" onChange={e => this.setState({ debateTopic: e.target.value })}/>
                          
                          <label class="custom_label">Date</label>
                          <div class="date_input">
                            <input class="custom_input" type="date" placeholder="" name="" onChange={e => this.setState({ debateDate: e.target.value })}/>
                            <i class="fal fa-calendar"></i>
                          </div>
                          {/*<DatePicker
                            onChange={e => this.setState({ debateTime: e.target.value })}
                            value={this.state.debateTime}
                          />*/}
                          <label class="custom_label">Time</label>
                          <div class="date_input">
                            <input class="custom_input" type="time" placeholder="" name=""onChange={e => this.setState({ debateTime: e.target.value })}/>
                            <i class="fal fa-calendar"></i>
                          </div>
                          {/*<TimePicker
                            onChange={e => this.setState({ debateDuration: e.target.value })}
                            value={this.state.debateDuration}
                          />*/}
                          <label class="custom_label">Debate Duration</label>
                          <input class="custom_input" type="text" placeholder="" name="" onChange={e => this.setState({ debateDuration: e.target.value })}/>
                          

                          <a class="submit_btn" onClick={this.handleJoin} disabled={!this.state.joinBtn}>Submit</a>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="messages" role="tabpanel">
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <NotificationContainer/>
      <Footer/>
      </>
    )
  }
}

  