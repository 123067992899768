import React from 'react'
import * as Cookies from 'js-cookie'

import '../../assets/fonts/css/icons.css'
import Validator from '../../utils/Validator'
import { RESOLUTION_ARR } from '../../utils/Settings'
 
import { useLocation, Link } from "react-router-dom";

class Sidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      joinBtn: false,
      channel: '',
      baseMode: '',
      transcode: '',
      attendeeMode: '',
      videoProfile: '',

    }

  }
 
  componentDidMount() {
    console.log(window.location.hash);
    // this.getDebates();
    // this.upcomingDebates();
    // this.debateInvitations();
 
  }
  render() {
    return ( 
      <div class="sidebar">
        {/*<a className= {window.location.hash == '#/upcoming-debates' ? 'active list-group-item list-group-item-action' : 'list-group-item list-group-item-action'} href="/#/upcoming-debates" role="tab">Upcoming Debates</a>
        <a className={window.location.hash == '#/pending-debates' ?  'active list-group-item list-group-item-action' : 'list-group-item list-group-item-action'}  href="/#/pending-debates" role="tab">Pending Debates</a>
        <a className={window.location.hash == '#/debate-invitations' ?  'active list-group-item list-group-item-action' : 'list-group-item list-group-item-action'}  href="/#/debate-invitations" role="tab">Debate Invitations</a>*/}
        {/*<a class={window.location.hash == '#/set-up-new-debate' ?  'active list-group-item list-group-item-action' : 'list-group-item list-group-item-action'}  href="#/set-up-new-debate" role="tab">Create Debate</a>*/}
        {/*<a class="list-group-item list-group-item-action"  href="#messages" role="tab">Cancel Debate</a>*/}
      
        
            <div class="sidebar-wrapper">
               <ul class="nav">
			            <li class="custom_logo"><a href="#/dashboard-stats"><img src="assets/images/logo.png"/></a></li>
                  <li className={window.location.hash == '#/dashboard-stats' ?  'active list-group-item list-group-item-action' : 'list-group-item list-group-item-action'}><a href="/#/dashboard-stats"><p class="custom_p">Dashboard</p></a></li>
                  <li className={window.location.hash == '#/dashboard-listing' ?  'active list-group-item list-group-item-action' : 'list-group-item list-group-item-action'}><a href="/#/dashboard-listing"><p class="custom_p">Debates</p></a></li>
                  <li className={window.location.hash == '#/dashboard-users' ?  'active list-group-item list-group-item-action' : 'list-group-item list-group-item-action'}><a href="/#/dashboard-users"><p class="custom_p">Users</p></a></li> 
                  <li className={window.location.hash == '#/whitelist-users' ?  'active list-group-item list-group-item-action' : 'list-group-item list-group-item-action'}><a href="/#/whitelist-users"><p class="custom_p">WhiteList Users</p></a></li>           
                </ul>
            </div>
         </div>
      
    )
  }
}

export default Sidebar