
// export const API_BASE_URL = "http://localhost:4001/api/";
// export const BASE_URL = "http://localhost:3000/";
// export const SOCKET_URL= 'http://localhost:3001';


export const API_BASE_URL = "https://rally-debates.itechnolabs.tech:4009/api/"; 
export const BASE_URL = "https://rally-debates.itechnolabs.tech";
export const SOCKET_URL= 'https://rally-debates.itechnolabs.tech:4004'



export const CUSTOMER = {
  REGISTER_CUSTOMER: "customer/register", 
  LOGIN_CUSTOMER: "auth/login",
  GET_CUSTOMER: "customer/get",
  UPDATE_CUSTOMER: "customer/update",
  RESET_PASSWORD: "auth/reset-password",
  UPDATE_CUSTOMER_PROFILE: "customer/update-customer-profile",
};


export const AUTH = {
  REGISTER: "customer/register-partner",
  LOGIN: "auth/login",
  FORGOT_PASSWORD: "auth/forgot-password-partner",
  RESET_PASSWORD: "auth/reset-password-partner",
};
 

 