import React from 'react'
import * as Cookies from 'js-cookie'

import '../../assets/fonts/css/icons.css'
import Validator from '../../utils/Validator'
import { RESOLUTION_ARR } from '../../utils/Settings'
import { API_BASE_URL,BASE_URL, SOCKET_URL } from "../constants/AppConstants";
import io from "socket.io-client"; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import soundfile from '../../assets/audio/juntos.mp3'
toast.configure();
const axios = require('axios').default;
const loginUsername= localStorage.getItem('loginUsername');
const loginUserProfile= localStorage.getItem('loginUserProfile');
const loginUser= localStorage.getItem('loginUser')
const socketUrl = SOCKET_URL;
var globalSocket;
class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      joinBtn: false,
      channel: '', 
      baseMode: '',
      transcode: '',
      attendeeMode: '',
      videoProfile: '',
      debate_create_counter:0,
      dbeate_accept_counter:0,
      is_user_login:true,
      logo:'assets/images/logo_w.png',
      hideLogo:false
    }
    
  }

   
  componentDidMount() {
    var token= localStorage.getItem('accessToken'); 
    //alert(token)
    if(token =="null") {
      //alert('j')
      this.setState({is_user_login:false})
    }else{

      //alert(token)
    }

    if(token ==null) {
      //alert('j')
      this.setState({is_user_login:false})
    }
    console.log('token ' + token)

    let url = window.location.hash;
    url = url.slice(0, url.lastIndexOf('/'));
    // url = url.slice(0, url.lastIndexOf('/'));
    if(url == "#/debate/start"){
      //alert(url)
      this.setState({
        hideLogo:true
      })
    }

    if(token == null){
      if(token == "null" ){
        //alert('h')
        // alert(window.location.hash);
        let url = window.location.hash;
        url = url.slice(0, url.lastIndexOf('/'));
        // url = url.slice(0, url.lastIndexOf('/'));
        if(url != "#/debate/start"){
          alert(url)
          // http://localhost:3000/#/debate/start/FB6F7E-7F6B-18B35F
          //window.location.hash = "login"
          //alert(localStorage.getItem('accessToken'));

        }
      } 
    } 
    this.initSocket()
  }

  
  initSocket = async () => {
    let socket = io.connect(socketUrl,{query: 'userId='+loginUser} )
    globalSocket=socket;
    //alert('hiii')
    this.setState({ socket })
    socket.on('connect', () => console.log( 'Connected')) 

    socket.on('create_debate',(data)=>{
      

      console.log("check this.state.debate_create_counter",this.state.debate_create_counter)
      if(this.state.debate_create_counter == 0){
      //alert(loginUser)
      //alert(data.data.debate_with)
        if(parseInt(loginUser) ===  parseInt(data.data.debate_with))
        {

          localStorage.setItem('notified', 'no');
          // alert(data.data.debate_with)
          var audio = new Audio(soundfile);
          audio.play();
        toast.success(`${data.data.challanger_name} with twitter handle ${data.data.challanger_twitter} wants to have debate with you`, {autoClose:10000});
          this.setState({debate_create_counter: 1})
          console.log("after increement debate_create_counter"+this.state.debate_create_counter)
        }  
      }
    })


    socket.on('accept_debate',async(data)=>{
       console.log("this.state.dbeate_accept_counter",this.state.dbeate_accept_counter)

      localStorage.getItem('notified');

      if(localStorage.getItem('notified') != 'yes'){
        if(parseInt(loginUser) ===  parseInt(data.data.challanger_id))
        {
          localStorage.setItem('notified', 'yes');
          this.setState({dbeate_accept_counter: 1})
          //alert('hii')
          var audio = new Audio(soundfile);
          audio.play();
          toast.success(`${data.data.debate_with_name} with twitter handle ${data.data.debate_with_twitter} has accepted your debate challenge`, {autoClose:10000});
          console.log("after increement dbeate_accept_counter "+this.state.dbeate_accept_counter)
        }   
      }
     
    
    })
    
    socket.on('decline_debate',async(data)=>{
       console.log("this.state.dbeate_accept_counter",this.state.dbeate_accept_counter)

      localStorage.getItem('notified');

      if(localStorage.getItem('notified') != 'yes'){
        if(parseInt(loginUser) ===  parseInt(data.data.challanger_id))
        {
          localStorage.setItem('notified', 'yes');
          this.setState({dbeate_accept_counter: 1})
          //alert('hii')
          var audio = new Audio(soundfile);
          audio.play();
          toast.error(`${data.data.debate_with_name} with twitter handle ${data.data.debate_with_twitter} has declined your debate challenge`, {autoClose:10000});
          console.log("after increement dbeate_accept_counter "+this.state.dbeate_accept_counter)
        }   
      }
     
    
    })
    
}
  gotoUpcomingDebate()
  { 
      var token= localStorage.getItem('accessToken');

      let url = window.location.hash;
      url = url.slice(0, url.lastIndexOf('/'));
      // url = url.slice(0, url.lastIndexOf('/'));
      if(url == "#/debate/start"){
         return;
      } 

      if(token ==null) {
        this.setState({is_user_login:false})
      }else{
        //window.location.assign(BASE_URL)
        //alert(token)
      }
       
  }
  // logout(){
  logout = async () => {
    // alert('j');
    let data = await axios.post(API_BASE_URL+'twitter/users/logout',{loginUser:loginUser});
    localStorage.setItem('accessToken',null); 
    localStorage.setItem('is_admin',null); 
    localStorage.setItem('loginUser',null);  
    localStorage.setItem('twitterHandle',null); 
    window.location.hash = "login";
  }
  render() {
    var token= localStorage.getItem('accessToken');
    return (
      <header class="headerCol">
        <div class="container custom_container">
          <div class="row align-items-center">
            <div class="col-md-6"> 
              <div class="header_left">
                <a class="logo" onClick={this.gotoUpcomingDebate}><img src="assets/images/logo_w.png" alt=""/></a>
              </div> 
            </div>
            <div class="col-md-6">


            { (this.state.is_user_login==true) ? (
        <div class="header_right">

                <span class="profile_span"><img src={loginUserProfile} alt=""/><p>{loginUsername}</p></span>
                <span class="logout"><a href="javascript:void(0)" onClick={this.logout}>Logout</a></span>
              </div>
      ) : (
        ''
      )}


            
              

            </div>
          </div>
        </div>
      </header>
    )
  }
}

 

export default Header