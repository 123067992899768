import React, { Component,useRef } from "react";
import * as Cookies from 'js-cookie'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import moment from "moment";
import DatePicker from "react-datepicker";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import '../../assets/fonts/css/icons.css';

import '../../assets/fonts/css/black-dashboard.css';
import '../../assets/fonts/css/demo.css';
import '../../assets/fonts/css/bootstrap.min.css';
import '../../assets/fonts/css/style.css';
import Validator from '../../utils/Validator';
import { RESOLUTION_ARR } from '../../utils/Settings';
import Header from '../common/DashboardHeader';
import Footer from '../common/DashboardFooter';
import Sidebar from '../common/DashboardSidebar';
import { API_BASE_URL } from "../constants/AppConstants";
import TimePicker from 'react-time-picker';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

const axios = require('axios').default;
const loginUser= localStorage.getItem('loginUser')
export default class EditDebate extends Component {

  constructor(props) {

    super(props)
    this.state = {

      joinBtn: false,
      channel: '',
      baseMode: '',
      transcode: '',
      attendeeMode: '',
      videoProfile: '',
      debateDuration:'',
      debateTopic:'',
      debateDate:'',
      debateTime:'', 
      filter:'',
      allDebates:[], 
      upcomingDebates:[],
      copied:false,
      copyBtn:"Copy",
      debateInvitations:[]

    }

  } 

  handleChange = async (date) => {
    
    var newdate=moment(date.toISOString()).format();
    var debate_date=newdate.split('T');

    this.setState({
      debateDate: date,
      filter: debate_date[0]
    })
    
  }

  
  componentDidMount() {
    this.getDebates();
    // this.upcomingDebates();
    // this.debateInvitations();
  }
 
  getDebates = async () => {
    //let response = await axios.get(API_BASE_URL+'admin/debates/');
    
    // console.log(response.data.data);
    // console.log('response.data.data');

    //this.setState({
      //allDebates: response.data.data 
    //}); 
  }
  

  acceptDebate = async (id) => {
    // alert(id);
    // preventDefault();
    let data = await axios.post(API_BASE_URL+'debate/accept',{'debate_id':id,'user_id':loginUser});
    
    toast.success('Thanks for accepting the debate invitation.', {autoClose:3000});

    //NotificationManager.success('', 'Thanks for accepting the debate invitation..');

    this.getDebates();
    console.log(this.state);
  }
  declineDebate = async (id) => {
    // alert(id);
    // preventDefault();
    let data = await axios.post(API_BASE_URL+'debate/decline',{'debate_id':id,'user_id':1});

    //NotificationManager.warning('', 'You have declined the debate invitation..');
    toast.success('You have declined the debate invitation.', {autoClose:3000});

    console.log(this.state);
    this.getDebates();
  }

  deleteDebate = async (id) => {
   // alert(id);
   // preventDefault();
   let data = await axios.post(API_BASE_URL+'debates',{'debate_id':id,'user_id':1});
   //NotificationManager.warning('', 'You have  deleted the debate invitation..');

   toast.success('You have deleted the debate invitation.', {autoClose:3000});

   console.log(this.state);
   this.getDebates();
 }

  cancelDebate=async(id)=>{
    let data = await axios.post(API_BASE_URL+'debate/decline',{'debate_id':id,'user_id':1});
    //NotificationManager.warning('', 'You have cancelled the debate invitation..');

    toast.success('You have cancelled the debate invitation.', {autoClose:3000});

    console.log(this.state); 
    this.getDebates();
  }
  inviteDebate=async(id)=>{
    Cookies.set('debate_id', id);
    // alert(id);
    setTimeout(function(){ 
        //window.location.hash = "my-created-debates"
        window.location.hash = "#/debate/invite/"+id;
    }, 1000);

  }
  filter=async()=>{
    // alert(this.state.debateDate)
    // var s=date.split('T');
    let response = await axios.post(API_BASE_URL+'debate/filter',{'debate_date':this.state.filter,userId:loginUser,'type':'pending'});
    this.setState({
      allDebates: response.data.data 
    });
  }
  
  handleJoin = async () => {

    console.log(this.state)
    Cookies.set('channel', this.state.channel)
    Cookies.set('baseMode', this.state.baseMode)
    Cookies.set('transcode', this.state.transcode)
    Cookies.set('attendeeMode', this.state.attendeeMode)
    Cookies.set('videoProfile', this.state.videoProfile)
    var payload= {
      'debate_duration':this.state.debateDuration,
      'topic':this.state.debateTopic,
      'debate_date':this.state.debateDate,
      'debate_time':this.state.debateTime,
      'userId':1
    } 
    await axios.post(API_BASE_URL+'debate',payload).then(function (response) {
      
     // NotificationManager.success('', 'Debate has been created.');

      toast.success('Debate has been created.', {autoClose:3000});
    }).catch(function (error) {
      console.log(error);
    });
    //window.location.hash = "meeting"
  }


  render() {
    const {allDebates} = this.state;
    console.log(this.state.allDebates)
    console.log('allDebates')
    return (
      <>
      
     
      </>
    )
  }
}

  