import React, { Component } from "react"; 
import * as Cookies from "js-cookie";
import { HashRouter as Router, Route , useParams , useLocation} from 'react-router-dom';
//import "./meeting.css";
import AgoraVideoCall from "../../components/AgoraVideoCall/index.js";
import { AGORA_APP_ID } from "../../agora.config";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
// import moment from "moment";
import queryString from 'query-string';
import '../../assets/fonts/css/icons.css'
import Validator from '../../utils/Validator'
import { RESOLUTION_ARR } from '../../utils/Settings'
import Header from '../common/Header'
import Footer from '../common/Footer'
import { API_BASE_URL,BASE_URL } from "../constants/AppConstants";
import TimePicker from 'react-time-picker';
import DatePicker from 'react-date-picker';
var moment = require('moment-timezone');
const axios = require('axios').default;
const loginUser= localStorage.getItem('loginUser')
const myTimeZone= localStorage.getItem('myTimeZone')
// moment().tz(myTimeZone).format();
export default class DebateStart extends Component {
  constructor(props) {
    super(props)

    this.state = {
      joinBtn: false,
      canJoin:false,
      channel: '',
      lat:'',
      long:'',
      baseMode: '',
      transcode: '',
      attendeeMode: '',
      videoProfile: '',
      debateDuration:'',
      debateTopic:'',
      debateDate:'',
      debateTime:'', 
      debateID:'',
      allDebates:[], 
      upcomingDebates:[],
      debateInvitations:[],
      min:"",
      sec:"",
      show_timer:false,
      orgm:'',
      invitee:'',
      current_state:"",
      debater:"",
      chellenger:""
    }

    this.videoProfile = "480p_4";
    this.channel = this.props.match.params.debateID;
    this.transcode = Cookies.get("transcode") || "interop";
    if(loginUser==null || loginUser=="undefined"){
      this.attendeeMode="audience"
      // alert(this.props.attendeeMode);
    }else{
      // alert(loginUser);
      this.attendeeMode = Cookies.get("attendeeMode") || "video";
    }
    this.baseMode = Cookies.get("baseMode") || "avc";
    this.appId = AGORA_APP_ID;
    if (!this.appId) {
      return alert("Get App ID first!");
    }
    this.uid = undefined;

    const search = this.props.location.search;
    const action = new URLSearchParams(search).get("action");
    this.isRealAudience= (action==null)?true:false;
    // alert(name)
    //this.state = this.initialState;


    // this.allDebates = this.allDebates.bind(this);
    //this.allDebates = this.allDebates.bind(this);
  }
   
  componentDidMount() {
    // console.log(myTimeZone)
    // window.addEventListener("beforeunload", (ev) => 
    // {  
    //     ev.preventDefault();
    //     return ev.returnValue = 'Are you sure you want to close?';
    // });
    var token= localStorage.getItem('accessToken');
    // if(token !="null") { 
    //   navigator.geolocation.getCurrentPosition( (position)=> {
    //     console.log("Latitude is :", position.coords.latitude);
    //     console.log("Longitude is :", position.coords.longitude);
    //     this.setState({
    //       lat:position.coords.latitude,
    //       long:position.coords.longitude,
    //     })
    //   });
    // } 

    // if(token !=null) { 
    //   navigator.geolocation.getCurrentPosition( (position)=> {
    //     console.log("Latitude is :", position.coords.latitude);
    //     console.log("Longitude is :", position.coords.longitude);
    //     this.setState({
    //       lat:position.coords.latitude,
    //       long:position.coords.longitude,
    //     })
    //   });
    // }
    
    const debateID= this.props.match.params.debateID;
    this.setState({
      debateID:debateID
    })

    //    alert(' debateID '+ debateID);
    this.getDebateDetail(debateID);
    // alert(this.props.match.params.debateID);
    //this.props.match.params.debateID;
  }
  
  async getDebateDetail(id) {
     //alert('hi' + id);
    if(myTimeZone!=null){
      moment().tz(myTimeZone).format();
    }else{
      let zone = await axios.post(API_BASE_URL+'getmytimezone',{lat:this.state.lat,long:this.state.long}); 
    }
    let res = await axios.get(API_BASE_URL+'debate/public/'+id);
    let response = res.data.data;
    this.state.debateTopic = response.topic;
    if(response.debate_with == true){
      response.debate_with = 1;
    }
    if(response.joinee1!='0'){

      var getData=JSON.parse(response.joinee1)
      
      if(getData.includes(loginUser)){
        //getData.push(req.body.loginUser);
        alert('You already on debate using another tab')
        //alert(JSON.stringify(getData))
        window.location.replace(BASE_URL)
      
      }
    }

    //alert('hii')
    console.log('response '+ response.userId + ' --- ' + response.debate_with);
    
    // console.log('response 123'+ response.debate_with);
    //alert(response.challenger_id)
    this.setState({
        orgm : response.userId,
        invitee : response.debate_with,
        chellenger : res.data.challenger_id,
        debater : res.data.debater_id
    })



    const testDate=moment.tz(myTimeZone).toDate();
    const curDate=moment.tz(testDate,myTimeZone).format('YYYY-MM-DD');
    var currentTime = moment.tz(myTimeZone).format('HH:mm:ss'); 
    const currentUtcDate=moment.utc().format("YYYY-MM-DD")
    
    var utcdattime=response.utc_date_time;
    var tt= utcdattime.split(' ');

    //alert(tt[0])
    if(currentUtcDate!=tt[0]){
      
      alert('You can not join this debate this at this moment');
      window.location.hash = "upcoming-debates";

    }else{  
        
        const curDate=moment.utc().format("YYYY-MM-DD")
        var currentTime = moment.utc().format("hh:mm:ss A") 

        let utc= moment(response.utc_date_time, 'YYYY-MM-DD hh:mm:ss A').format('YYYY-MM-DD hh:mm:ss a')
        let utc_time= utc.split(' ');
        let utcTime = moment(utc_time[1]+' '+utc_time[2], "h:mm:ss A").format("HH:mm:ss") 

        let utc_cure= moment(curDate+' '+currentTime, 'YYYY-MM-DD hh:mm:ss A').format('YYYY-MM-DD hh:mm:ss a')
        let utc_time_cur=utc_cure.split(' ');
        currentTime = moment(utc_time_cur[1]+' '+utc_time_cur[2], "h:mm:ss A").format("HH:mm:ss")

        //  var date = moment(currentTime,"HH:mm:ss").add('seconds', 300).format('HH:mm:ss');
        var date = moment.tz(myTimeZone).add( 900, 'seconds').format('HH:mm:ss'); 
        var date2 = moment(response.utc_date_time,"HH:mm:ss").add(response.debate_duration, 'minutes').format('HH:mm:ss'); 
        // alert(date)
        // alert(utcTime)
        // alert(currentTime)
        // alert(date2)
      
        // alert(response.isCompleted)

        if(response.isCompleted==1){
          alert('This Debate Has Ended'); 
          window.location.hash = "upcoming-debates";
          return; 
        }
        if(response.isCompleted==true){
          alert('This Debate Has Ended'); 
          window.location.hash = "upcoming-debates";
          return; 
        }
        if(currentTime> date2 || response.isCompleted==true){   
          alert('This Debate Has Ended'); 
          window.location.hash = "upcoming-debates";
          return; 
        }
 
       // alert(date + ' '+ response.debate_time);
        // if(date < response.debate_time){
        //     alert(date)
        //     alert(response.debate_time)
        //   alert('You can not join this debate this at this moment');
        //   window.location.hash = "upcoming-debates";
        //   return;
        // } 
        // else if(date2 > response.debate_time){
          
        //   //alert('Debate ended');
        //   //window.location.hash = "upcoming-debates";
        // }
        else{
          //alert('hi')
          if(response.userId==loginUser){
            this.setState({ canJoin: true });
          }else{
            let res1 = await axios.post(API_BASE_URL+'debate/security',{'debate_id':response.id,'userId':loginUser});
            let response1=res1.data.data; 
            if(res1.data.status =='200'){ 
              this.setState({ canJoin: true }); 
            }else{
              //alert('hi')
              this.attendeeMode="audience";
              this.setState({ canJoin: true });

              // alert(this.attendeeMode)
            }
          }
        }  
    }
  }

  
  setDebateCurrentState = async (currentActive) => {

    //alert(currentActive)

    if(currentActive=="intro1"){
      this.setState({ current_state:"Currently " + this.state.chellenger + " is giving introduction" })
    }else if(currentActive=="intro2"){
      this.setState({ current_state:"Currently " +this.state.debater + " is giving introduction" })
    }else if(currentActive=="argu1"){
      this.setState({ current_state:"Currently " +this.state.chellenger + " is doing argument" })
    }else if(currentActive=="argu2"){
      this.setState({ current_state:"Currently " +this.state.debater + " is doing argument" })
    }else if(currentActive=="discussion"){
      this.setState({ current_state:"Currently both are doing discussion" })
    }else if(currentActive=="final_thoughts_1"){
      this.setState({ current_state:"Currently " +this.state.chellenger + " is putting final thoughts"  })
    }else if(currentActive=="final_thoughts_2"){
      this.setState({ current_state:"Currently " +this.state.debater + " is putting final thoughts" })
    }
  }
  eventhandler = async (data) => {
    console.log("data",data)
    this.setState({
      min:data.m,
      sec:data.s,
    })
    console.log(data.m)
    console.log(data.s)
    if(data.m<0){

      this.setState({
        show_timer:false,
      })

    }else{

      this.setState({
        show_timer:true,
      })
    }
  } 

  render() {
    const {allDebates} = this.state;
    
    return (
      <>
      <Header/>
      <section class="debate_outer">
        <div class="container custom_container">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6">
                  <div class="debate_title">{ this.state.debateTopic }</div>
                </div>

                 
                <div class="col-md-6">
                  {this.state.show_timer==true &&
                    <div class="debate_title await_timer" style={{float:"right"}}>Debate starts in {this.state.min}:{this.state.sec}</div>
                  }

                  {this.state.current_state!="" &&
                    <div class="debate_title await_timer states" style={{float:"right"}}>{this.state.current_state}</div>
                  }
                </div>
              </div>
              <div class="debatetab_outer reminder_outer">
                <div class="debate_left debate_video_div">
                
                      <div class="row">
                          {this.state.canJoin == true &&
                            <div class="col-md-12"> 
                            <AgoraVideoCall
                              videoProfile={this.videoProfile}
                              channel={this.channel}
                              transcode={this.transcode}
                              attendeeMode={this.attendeeMode}
                              baseMode={this.baseMode}
                              appId={this.appId}
                              uid={this.uid}
                              orgId={this.state.orgm}
                              invitee={this.state.invitee}
                              debateID={this.state.debateID}
                              onChange={this.eventhandler}
                              onChangeState={this.setDebateCurrentState}
                              isRealAudience={this.isRealAudience}
                            />
                          </div> 
                          }
                          
                      </div>
                       
                     
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <NotificationContainer/>
      <Footer/>
      </>
    )
  }
}

  