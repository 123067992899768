import React from 'react'
import * as Cookies from 'js-cookie'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { API_BASE_URL, BASE_URL } from "../constants/AppConstants";
import { withRouter } from 'react-router';
import '../../assets/fonts/css/icons.css'
import Validator from '../../utils/Validator'
import { RESOLUTION_ARR } from '../../utils/Settings'
// import TwitterLogin from "react-twitter-login";
import TwitterLogin from 'react-twitter-auth/lib/react-twitter-auth-component.js';
import queryString from 'query-string';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from "react-router-dom";

const  API_KEY= "ym52hnipttdPKOHA2oyvPU88h";
const  SECRET_KEY="Z2vCnZHJROOgBRMchvalsxi7nM47z2lfoWJ2WU5B8aAWmeEtIu";
// const  API_KEY= "PyHxgJuyORZqhDiuKAne8LcxT";
// const  SECRET_KEY="RBqOgWJfflgk2GLGmKtHFnHituqvf3vROPfAqzOPpfKficIrI9";
const axios = require('axios').default;

class Login extends React.Component {
 
  constructor(props) {
    super(props)
    this.state = {
      joinBtn: false,
      channel: '',
      baseMode: '',
      transcode: '',
      attendeeMode: '',
      videoProfile: '',
      button:'Sign in with Twitter',
      isActive:true,
      errorMessage:'',
      loading: true,
      loginMsg: ""
    } 
  }
  

  // demoAsyncCall() {
  //   return new Promise((resolve) => setTimeout(() => resolve(), 3500));
  // }

  componentDidMount() { 
    // this.demoAsyncCall().then(() => this.setState({ loading: false })); 
    // const {oauth_token, oauth_verifier} = queryString.parse(window.location.search); 
   this.loginSuccess();
    //navigator.geolocation.getCurrentPosition( (position)=> {
      //console.log("Latitude is :", position.coords.latitude);
      //console.log("Longitude is :", position.coords.longitude);
      //this.setState({
        //lat:position.coords.latitude,
        //long:position.coords.longitude,
      //})
      
    //});
    var token= localStorage.getItem('accessToken');
    
    if(token!==null){ 
      if(token=="null"){
         
        // window.location.hash = "login"
        //alert(localStorage.getItem('accessToken'));
      }else{
        window.location.hash = "upcoming-debates"
         
        setTimeout(function(){ 
          window.location.hash = "upcoming-debates"
          //history.push('/blank');
          //window.location.reload();
        }, 100);
      }
    }
  }
  
  async loginSuccess(){

     
    const {oauth_token, oauth_verifier} = queryString.parse(window.location.search);  
        
    if (oauth_token && oauth_verifier) {
     try {

        this.setState({button:"Please Wait..."})
        //Oauth Step 3
        const oauth_token_secret=localStorage.getItem('oauth_token_secret');
        const allTokens=await axios({
          url: `${API_BASE_URL}/twitter/oauth/access_token`,  
          method: 'POST',
          data: {oauth_token, oauth_verifier,oauth_token_secret}
        }); 

         
        const oauth_access_token= allTokens.data.oauth_access_token;
        const oauth_access_token_secret= allTokens.data.oauth_access_token_secret;


        const {data: {name,screen_name, profile_image_url_https, status, entities,location}} = await axios({
          url: `${API_BASE_URL}/twitter/users/profile_banner`,
          method: 'POST',
          data:{oauth_access_token, oauth_access_token_secret}
        });
        
        
        let payload = {
          email:screen_name,
          location:location,
          name:name,
          profile_image:profile_image_url_https,
          lat:this.state.lat,
          long:this.state.long
        }
 
        this.login(payload);
         
         
     } catch (error) {
      console.error(error); 
     }
    }
  }

  login = async (data) => { 

    
    let response= await axios.post(API_BASE_URL+'users/login',data);
       
    if(response){
    console.log(response) 
      this.setState({
        isActive: false,
        loginMsg:"",
      });   
      if(response.data.status=='400' ){
        
        this.setState({
          isActive: true,
          button:"Sign In with Twitter",
          loginMsg:"",
          errorMessage: "Dear @"+response.data.data.email+", You are logged in another device, please signout from another system to get login"
        });          
        return;
      }

      if(response.data.data.login_access != 'active' ){
        
        this.setState({
          button:"Sign In with Twitter",
          isActive: true,
          loginMsg:"",
          errorMessage: "Dear @"+response.data.data.email+", your twitter handled is not approved by the admin. Please contact to the admin or wait for sometime"
        });          

      }else if(response.data.status=='200'){
        console.log(response.data.accessToken); 
        localStorage.setItem('twitterHandle', response.data.data.email);
        // alert(response.data.data.secondary_email);
        // alert(response.data.data.id);
        if(response.data.data.secondary_email=="" || response.data.data.secondary_email==undefined){
          window.location.hash = "get-started" 
        }else{
          localStorage.setItem('accessToken', response.data.accessToken);
          localStorage.setItem('loginUser', response.data.data.id);
          localStorage.setItem('is_admin', response.data.data.is_admin);
          localStorage.setItem('loginUsername', response.data.data.name);
          localStorage.setItem('loginUserProfile', response.data.data.profile_image);
          localStorage.setItem('myTimeZone', response.data.data.timeZoneId);
          // window.location.hash = "upcoming-debates" 
          // window.location.href(BASE_URL+"upcoming-debates")
          // history.push("/upcoming-debates");
         window.location.assign(BASE_URL)
        }  
        
      }else{
        
        // this.setState({
        //   isActive:false,
        //   errorMessage:"Dear @"+response.data.data.email+", your twitter handled is not approved by the admin. Please contact to the admin or wait for sometime"
        // })
        //alert('You can not login');
        return NotificationManager.warning('', 'Access Denied...');
              
      }
    } 
  }
  onSuccess = async (response) => {
    // const history = useHistory();
 
    this.setState({
      loginMsg:"Please wait....",
      isLoading: true
    })
 
    response.json().then( async body => {
        // alert(JSON.stringify(body));
        
        try {
          const oauth_access_token= body.oauth_token;
          const oauth_access_token_secret= body.oauth_token_secret;
    
          var {data: {name,screen_name, profile_image_url_https, status, entities,location}} = await axios({
            url: `${API_BASE_URL}/twitter/users/profile_banner`,
            method: 'POST',
            data:{oauth_access_token, oauth_access_token_secret}
          });

         
         
        let response = await axios({
          url: `${API_BASE_URL}users/login`,
          method: 'POST',
          data: {
            email:screen_name,
            location:location,
            name:name,
            profile_image:profile_image_url_https,
           lat:this.state.lat,
           long:this.state.long
          }
        });

        if(response){

          this.setState({
            isActive: false,
            loginMsg:"",
          });  


          if(response.data.status=='400' ){
            
            this.setState({
              isActive: true,
              loginMsg:"",
              errorMessage: "Dear @"+response.data.data.email+", You are logged in another device, please signout from another system to get login"
            });          
            return;
          }

          if(response.data.data.login_access != 'active' ){
            
            this.setState({
              isActive: true,
              loginMsg:"",
              errorMessage: "Dear @"+response.data.data.email+", your twitter handled is not approved by the admin. Please contact to the admin or wait for sometime"
            });          

          }else if(response.data.status=='200'){
            console.log(response.data.accessToken); 
            localStorage.setItem('twitterHandle', response.data.data.email);
            // alert(response.data.data.secondary_email);
            // alert(response.data.data.id);
            if(response.data.data.secondary_email=="" || response.data.data.secondary_email==undefined){
              window.location.hash = "get-started" 
            }else{
              localStorage.setItem('accessToken', response.data.accessToken);
              localStorage.setItem('loginUser', response.data.data.id);
              localStorage.setItem('is_admin', response.data.data.is_admin);
              localStorage.setItem('loginUsername', response.data.data.name);
              localStorage.setItem('loginUserProfile', response.data.data.profile_image);
              localStorage.setItem('myTimeZone', response.data.data.timeZoneId);
              // window.location.hash = "upcoming-debates" 
              // window.location.href(BASE_URL+"upcoming-debates")
              // history.push("/upcoming-debates");
             window.location.assign(BASE_URL)
            }  
            
          }else{
            
            // this.setState({
            //   isActive:false,
            //   errorMessage:"Dear @"+response.data.data.email+", your twitter handled is not approved by the admin. Please contact to the admin or wait for sometime"
            // })
            //alert('You can not login');
            return NotificationManager.warning('', 'Access Denied...');
                  
          }
        }         
      } catch (error) {       
        alert(error)
        console.error(error); 
      }
    });
  }
  
  onFailed(error) {
   // alert(error);
  }
 
  redirect(){
    //alert('hii');
    this.props.history.push('#/upcoming-debates')
  }

  

  twitterLogin = () => {
    (async () => {
      
      this.setState({
        lognMsg:"Please wait....",
        isLoading: true,
        button:"Please wait...."
      })
      try {
        const response = await axios({
          url: `${API_BASE_URL}twitter/oauth/request_token`, 
          method: 'POST'
        });
        
        const { oauth_token,oauth_token_secret } = response.data;
        // console.error(response.data); 
        localStorage.setItem('oauth_token_secret',oauth_token_secret);

        // window.location.href = `https://api.twitter.com/oauth/authorize?oauth_token=${oauth_token}`;
        window.location.href = `https://api.twitter.com/oauth/authenticate?oauth_token=${oauth_token}`;
      } catch (error) {
        console.error(error); 
      }
      
    })();
  }
  authHandler = (err, data) => {
    console.log('err, data');
    console.log(err, data);
  };
 
  render() {
     const customHeader = {};
     
    // customHeader['Test'] = 'test-header';

    // const {isActive, errorMessage} = this.state;
    
    // const { loading } = this.state;
    // if(loading) {
    //   return(
    //     <section class="section_space login_outer">
    //       <img src="https://cdn.dribbble.com/users/1186261/screenshots/3718681/media/1df2396f1eaa146bcb7dd3e73c1dc77b.gif" />  
    //     </section>
    //     )
    //   }
    var auth_twitter = API_BASE_URL + 'auth/twitter';
    var auth_twitter_reverse = API_BASE_URL + 'auth/twitter/reverse';
    return(
       <section class="section_space login_outer">
        <div class="container login_container">
          <div class="row align-items-center">
            <div class="col-md-6 login_left_col">
              <div class="login_left">
                <a class="logo" href="#/login"><img src="assets/images/logo.png" alt=""/></a>
                <img class="login-img" src="assets/images/login.png" alt=""/>
              </div>
            </div>
            <div class="col-md-6 login_col">
              <div class="login-right">
                
                <div class="login_title">Debate The Greatest Minds of &nbsp;Today</div>
                <p style= {{color:"red"}}>{ (this.state.isActive)?this.state.errorMessage:''}</p>
                <p style={{color:"#26a8df"}}>{this.state.loginMsg}</p>
                <div class="login_title">Sign Into Rally Debates</div>
                  

                <a class="signin_btn" href="javascript:void(0)" onClick={this.twitterLogin}><i class="fab fa-twitter"></i>{this.state.button}</a>
                <p class="copyright_text">Copyright © By Rally Debates 2021. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
        <NotificationContainer/>
      </section>
    )
  }
}

export default Login