import React, { Component } from 'react'
import { HashRouter as Router, Route , useParams } from 'react-router-dom'
import 'bulma/css/bulma.css'

import './App.css'
import Index from './index'
import Login from './login/Login'
import AdminLogin from './login/AdminLogin'
import GetStarted from './login/GetStarted'
import Home from './home/Home'  
import Meeting from './meeting'
import AddDebate from './meeting/AddDebate'
import PrivateRoute from './../utils/PrivateRoute';
import DebateInvitations from './home/DebateInvitations'
import UpcomingDebates from './home/UpcomingDebates'
import CreateDebate from './home/CreateDebate'
import DebateStart from './debate/DebateStart'
import Invite from './debate/Invite'
import dashboardStats from './dashboard/Stats'
import dashboardListing from './dashboard/Listing'
import EditDebate from './dashboard/EditDebate'
import dashboardUsers from './dashboard/Users'
import whitelistUsers from './dashboard/Whitelistusers'


class App extends Component {
  render() {
    return (
      <Router>
        <div className="full">
          <Route path="/" exact component={Login} />
          <Route path="/login" component={Login} />
          <Route path="/adminlogin" component={AdminLogin} />
          <Route path="/get-started" component={GetStarted} />
          <Route path="/home" component={Home} />
          <Route path="/debate-invitations" component={DebateInvitations} />
          <Route exact path="/event-link/:id" component={Invite} />
          <Route path="/debate/start/:debateID" component={DebateStart} /> 
          <Route path="/meeting/:debateID" component={Meeting} /> 
          <PrivateRoute  path="/upcoming-debates" component={UpcomingDebates} />
          <PrivateRoute path="/pending-debates" component={Home} />
          <PrivateRoute path="/set-up-new-debate" component={CreateDebate} />
          <PrivateRoute path="/dashboard" component={Index} />
          <PrivateRoute path="/dashboard-stats" component={dashboardStats} />
          <PrivateRoute path="/dashboard-listing" component={dashboardListing} />
          <PrivateRoute path="/dashboard-users" component={dashboardUsers} />
          <PrivateRoute path="/whitelist-users" component={whitelistUsers} />
          <PrivateRoute path="/debate/:id" component={EditDebate} />
          <PrivateRoute path="/add-new-debate/:id" component={AddDebate} /> 
        </div>
      </Router>
    )
  }
}

export default App

