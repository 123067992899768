import React from 'react';
import { Redirect, Route } from "react-router-dom";
// function PrivateRoute({ children, ...rest }) {
// // alert(localStorage.hasOwnProperty("accessToken") ? true : false)
//   return (
//       // <Route
//       //   {...rest}
//       //   render={({ location }) =>
//       //       (  (!localStorage.hasOwnProperty("accessToken") )    )  ? (
//       //       children
//       //     ) : (
//       //       <Redirect
//       //         to={{
//       //           pathname: "/login",
//       //           state: { from: location }
//       //         }}
//       //       />
//       //     )
//       //   }
//       // />

//     );
//   }



const  PrivateRoute = ({component:Component, ...rest}) =>(
  <Route {...rest} render={props => localStorage.hasOwnProperty("accessToken") ? (
      <Component {...props}/>
  ):''
  } 
  />
);

//<Redirect to={{pathname:"/login", state:{from: props.location}  }}/>
export default PrivateRoute;