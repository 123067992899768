import React from 'react'
import * as Cookies from 'js-cookie'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { API_BASE_URL, BASE_URL } from "../constants/AppConstants";
import { withRouter } from 'react-router';
import '../../assets/fonts/css/icons.css'
import Validator from '../../utils/Validator'
import { RESOLUTION_ARR } from '../../utils/Settings'
// import TwitterLogin from "react-twitter-login";
import TwitterLogin from 'react-twitter-auth/lib/react-twitter-auth-component.js';
import queryString from 'query-string';


const  API_KEY= "CULeo4agzjLQ62wyIYSrDcRvS";
const  SECRET_KEY="w1XpAuBIGfdtXPuhpF9sotvRwSQmX02QsaPSy7nKE6GvSKj7Fl";
// const  API_KEY= "PyHxgJuyORZqhDiuKAne8LcxT";
// const  SECRET_KEY="RBqOgWJfflgk2GLGmKtHFnHituqvf3vROPfAqzOPpfKficIrI9";
const axios = require('axios').default;
class AdminLogin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      joinBtn: false,
      channel: '',
      baseMode: '',
      transcode: '',
      attendeeMode: '',
      videoProfile: '',
      userEmail: '',
      userPassword: '',
      button:'Login',
      isActive:true,
      errorMessage:true,
      isValidEmail:true,
      isValidPass: true,
      userEmailErr:'',
      userPasswordErr:'',

    } 
  } 
  componentDidMount() {


    const {oauth_token, oauth_verifier} = queryString.parse(window.location.search); 

    // this.loginSuccess();
    //alert(oauth_token);
    //this.debateInvitations();

    
    var token= localStorage.getItem('accessToken');
    console.log(token)
    if(token!==null){
      if(token=="null"){
      //  window.location.hash = "login"
        //alert(localStorage.getItem('accessToken'));
      }else{

        setTimeout(function(){ 
          window.location.hash = "dashboard-stats"
          //history.push('/blank');
          //window.location.reload();
        }, 100);
      }
    }
    // && token!=='undefined'
  }

  formValidation = (e) => {
       
    let isValid = true;
  
 
    if(this.state.userEmail==='' || this.state.userEmail===undefined || this.state.userEmail=== null){ 
        var isValidEmail= false;
        this.setState({
         userEmailErr: "Please enter the Username/email"
        });
    }else{
     var isValidEmail= true;
      this.setState({
       userEmailErr: ""
      });
    }
 
    if(this.state.userPassword==='' || this.state.userPassword===undefined){ 
     var isValidPass= false;
        this.setState({
          userPasswordErr: "Please enter the password"
        });
    }else{
     var isValidPass= true;
      this.setState({
        userPasswordErr: ""
      });
    }
 
    if( isValidEmail  && isValidPass ){
     return true
    }
    return false;
 }
  
  loginSuccess = async () => {

  var isValid= await this.formValidation();

   if(isValid===true)
    {  
      try {
          
          let payload={
            email: this.state.userEmail,
            password: this.state.userPassword
          }
          this.login(payload);
          
      } catch (error) {
        console.error(error);  
      }
    }
}

 
  redirect(){

    this.props.history.push('#/dashboard-stats')
  }
  async login(data) {
     

    let response= await axios.post(API_BASE_URL+'admin/login',data);
    
    if(response){

      if(response.data.status=='200'){
        console.log(response.data.accessToken); 
        localStorage.setItem('twitterHandle', response.data.data.email);
        // alert(response.data.data.secondary_email);
        // alert(response.data.data.id);
        if(response.data.data.secondary_email=="" || response.data.data.secondary_email==undefined){
          window.location.hash = "get-started" 
        }else{
          localStorage.setItem('accessToken', response.data.accessToken);
          localStorage.setItem('loginUser', response.data.data.id);
          localStorage.setItem('is_admin', response.data.data.is_admin);
          localStorage.setItem('loginUsername', response.data.data.name);
          localStorage.setItem('loginUserProfile', response.data.data.profile_image);
          localStorage.setItem('myTimeZone', response.data.data.timeZoneId);
          window.location.hash = "dashboard-stats" 
        }
        
      }else{  
        
        this.setState({
          isActive:false,
          errorMessage:"Please enter valid login details"
        })
        //alert('You can not login');
        //return NotificationManager.warning('', 'Access Denied...');
              
      }
    }
  }

  twitterLogin = () => {
    (async () => {
      
      this.setState({
        button:"Please wait...."
      })
      try {
       
        
       // const { oauth_token,oauth_token_secret } = response.data;
        // console.error(response.data); 
      //  localStorage.setItem('oauth_token_secret',oauth_token_secret);

        // window.location.href = `https://api.twitter.com/oauth/authorize?oauth_token=${oauth_token}`;
       // window.location.href = `https://api.twitter.com/oauth/authenticate?oauth_token=${oauth_token}`;
      } catch (error) {
        console.error(error); 
      }
      
    })();
  }
  authHandler = (err, data) => {
    console.log('err, data');
    console.log(err, data);
  };
 
  render() {
    const customHeader = {};
    customHeader['Test'] = 'test-header';
    return(
       <section className="section_space login_outer">
        <div className="container login_container">
          <div className="row align-items-center">
            <div className="col-md-6 login_left_col">
              <div className="login_left">
                <a className="logo" href="#/login"><img src="assets/images/logo.png" alt=""/></a>
                <img className="login-img" src="assets/images/login.png" alt=""/>
              </div>
            </div>
            <div className="col-md-6 login_col">
              <div className="login-right">
                <p style= {{color:"red"}}>{this.state.isActive==false? this.state.errorMessage:""}</p>
                
                <div className="login_title">Admin Login</div>
                <div className="login_title1">
                  <label htmlFor="">Email</label>
                  <div style={{color:'red'}}>{this.state.userEmailErr}</div>
                  <input type="text" name="email" onChange={e => this.setState({ userEmail: e.target.value })} />
                </div>
                <div class="login_title1">
                  <label htmlFor="">Password</label>
                  <div style={{color:'red'}}>{this.state.userPasswordErr}</div>
                  <input type="password" name="password" onChange={e => this.setState({ userPassword: e.target.value })} />
                </div>

                

                <a className="signin_btn adminbutton" href="javascript:void(0)" onClick={this.loginSuccess}>{this.state.button}</a>
                <p className="copyright_text">Copyright © By Rally Debates 2021. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
        <NotificationContainer/>
      </section>
    )
  }
}

 

export default AdminLogin