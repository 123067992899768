import React, { Component,useRef } from "react";
import * as Cookies from 'js-cookie'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import moment from "moment";
import DatePicker from "react-datepicker";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import '../../assets/fonts/css/icons.css';

import '../../assets/fonts/css/black-dashboard.css';
import '../../assets/fonts/css/demo.css';
import '../../assets/fonts/css/bootstrap.min.css';
import '../../assets/fonts/css/style.css';
import Validator from '../../utils/Validator';
import { RESOLUTION_ARR } from '../../utils/Settings';
import Header from '../common/DashboardHeader';
import Footer from '../common/DashboardFooter';
import Sidebar from '../common/DashboardSidebar';
import { API_BASE_URL } from "../constants/AppConstants";
import TimePicker from 'react-time-picker';
// import DatePicker from 'react-date-picker';
const axios = require('axios').default;
const loginUser= localStorage.getItem('loginUser')
export default class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      joinBtn: false,
      channel: '',
      baseMode: '',
      transcode: '',
      attendeeMode: '',
      videoProfile: '',
      debateDuration:'',
      debateTopic:'',
      debateDate:'',
      debateTime:'', 
      filter:'',
      allDebates:[], 
      upcomingDebates:[],
      copied:false,
      copyBtn:"Copy",
      debateInvitations:[]

    }
  }

  handleChange = async (date) => {
    
    var newdate=moment(date.toISOString()).format();
    var debate_date=newdate.split('T');

    this.setState({
      debateDate: date,
      filter: debate_date[0]
    })
  }

  
  componentDidMount() {
    this.getDebates();
    // this.upcomingDebates();
    // this.debateInvitations();
 
  }
 
  getDebates = async () => {
    let response = await axios.get(API_BASE_URL+'debate/stats');
    console.log('response.data.data');
    this.setState({
      allDebates: response.data.data 
    }); 
  }
  

  acceptDebate = async (id) => {
    // alert(id);
    // preventDefault();
    let data = await axios.post(API_BASE_URL+'debate/accept',{'debate_id':id,'user_id':loginUser});
   
    NotificationManager.success('', 'Thanks for accepting the debate invitation..');
    this.getDebates();
    console.log(this.state);
  }
  declineDebate = async (id) => {
    // alert(id);
    // preventDefault();
    let data = await axios.post(API_BASE_URL+'debate/decline',{'debate_id':id,'user_id':1});
    NotificationManager.warning('', 'You have declined the debate invitation..');
    console.log(this.state);
    this.getDebates();
  }

  cancelDebate=async(id)=>{
    let data = await axios.post(API_BASE_URL+'debate/decline',{'debate_id':id,'user_id':1});
    NotificationManager.warning('', 'You have cancelled the debate invitation..');
    console.log(this.state); 
    this.getDebates();
  }
  inviteDebate=async(id)=>{
    Cookies.set('debate_id', id);
    // alert(id);
    setTimeout(function(){ 
        //window.location.hash = "my-created-debates"
        window.location.hash = "#/debate/invite/"+id;
    }, 1000);

  }

  
  createNotification = (type) => {
    return () => {
      switch (type) {
        case 'info':
          NotificationManager.info('Info message');
          break;
        case 'success':
          NotificationManager.success('Success message', 'Title here');
          break;
        case 'warning':
          NotificationManager.warning('Warning message', 'Close after 3000ms', 3000);
          break;
        case 'error':
          NotificationManager.error('Error message', 'Click me!', 5000, () => {
            alert('callback');
          });
          break;
      }
    };
  };


  handleJoin = async () => {

    //alert('jiii');
    // if (!this.state.joinBtn) {
    //   return
    // }

    console.log(this.state)
    Cookies.set('channel', this.state.channel)
    Cookies.set('baseMode', this.state.baseMode)
    Cookies.set('transcode', this.state.transcode)
    Cookies.set('attendeeMode', this.state.attendeeMode)
    Cookies.set('videoProfile', this.state.videoProfile)
    var payload= {
      'debate_duration':this.state.debateDuration,
      'topic':this.state.debateTopic,
      'debate_date':this.state.debateDate,
      'debate_time':this.state.debateTime,
      'userId':1
    } 
    await axios.post(API_BASE_URL+'debate',payload).then(function (response) {
      // this.createNotification('success')
      // alert('done');
      NotificationManager.success('', 'Debate has been created.');
    }).catch(function (error) {
      console.log(error);
    });
    //window.location.hash = "meeting"
  }


  render() {
    const {allDebates} = this.state;
    console.log( this.state.allDebates.users)
    console.log('allDebates')
    return (
      <>
      <div class="wrapper">
        <Sidebar/>
        <Header/>
        <div class="main-panel">
        <nav class="navbar navbar-expand-lg navbar-absolute navbar-transparent">
               <div class="container-fluid">
                  <div class="navbar-wrapper">
                     <div class="navbar-toggle d-inline">
                        <button type="button" class="navbar-toggler">
                        <span class="navbar-toggler-bar bar1"></span>
                        <span class="navbar-toggler-bar bar2"></span>
                        <span class="navbar-toggler-bar bar3"></span>
                        </button>
                     </div>
                 
                  </div>
               </div>
            </nav>
            <div class="modal modal-search fade" id="searchModal" tabindex="-1" role="dialog" aria-labelledby="searchModal" aria-hidden="true">
               <div class="modal-dialog" role="document">
                  <div class="modal-content">
                     <div class="modal-header">
                        <input type="text" class="form-control" id="inlineFormInputGroup" placeholder="SEARCH" />
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <i class="tim-icons icon-simple-remove"></i>
                        </button>
                     </div>
                     </div>               
               </div>
            </div>      
            
          <div class="content">
              <div class="dashbord_outer">
                <h3>Dashboard</h3>
              </div>
            <div class="outer_bg45">
              <div class="row">
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="purpal_outer color_par">
                  <h4>{this.state.allDebates.users}+</h4>
                  <p>Total No. of Users</p>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="purpal_outer color_par2">
                  <h4>{this.state.allDebates.total}+</h4>
                  <p>Total No. of Debates</p>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="purpal_outer color_par4">
                  <h4>{this.state.allDebates.accepted}+</h4>
                  <p>Total No. of Accepted Debates</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="purpal_outer color_par3">
                  <h4>{this.state.allDebates.declined}+</h4>
                  <p>Total No. of Declined Debates</p>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="purpal_outer color_par5">
                  <h4>{this.state.allDebates.started}+</h4>
                  <p>Total No. of Started Debates</p>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="purpal_outer color_par4">
                  <h4>{this.state.allDebates.completed}+</h4>
                  <p>Total No. of Completed Debates</p>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 col-12">
                  <div class="purpal_outer color_par3">
                  <h4>{this.state.allDebates.cancelled}+</h4>
                  <p>Total No. of Cancelled Debates</p>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="purpal_outer color_par6">
                  <h4>{this.state.allDebates.pending}+</h4>
                  <p>Total No. of Pending Debates</p>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 col-12">
                  <div class="purpal_outer color_par">
                  <h4>{this.state.allDebates.viewCount}+</h4>
                  <p>Total No. of Views</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
         </div>
        <NotificationContainer/>
        <Footer/>
      </div>
     
      </>
    )
  }
}

  