import React, { Component } from "react";
import * as Cookies from 'js-cookie'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import DatePicker from "react-datepicker";
import '../../assets/fonts/css/icons.css'
import Validator from '../../utils/Validator'
import { RESOLUTION_ARR } from '../../utils/Settings'
import Header from '../common/Header'
import Footer from '../common/Footer'
import Sidebar from '../common/Sidebar'
import { API_BASE_URL,SOCKET_URL ,BASE_URL} from "../constants/AppConstants";
import TimePicker from 'react-time-picker';
import io from "socket.io-client"; 
import { ToastContainer, toast } from 'react-toastify';
import Socket from './Socket.js';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();
const socketUrl = SOCKET_URL;
var globalSocket;
const axios = require('axios').default;
const loginUser= localStorage.getItem('loginUser')
var moment = require('moment-timezone');
const myTimeZone= localStorage.getItem('myTimeZone')


export default class UpcomingDebates extends Component {
  constructor(props) {
    super(props)
    this.state = {
      joinBtn: false,
      channel: '',
      baseMode: '',
      transcode: '',
      attendeeMode: '',
      videoProfile: '',
      debateDuration:'',
      debateTopic:'',
      debateDate:'',
      pending:0,
      upcoming:0,
      filter:'', 
      allDebates:[], 
      upcomingDebates:[],
      debateInvitations:[],
      copied:false,
      copyBtn:"Copy",
      socket:"",
      lat:"",
      long:""
    } 
  }
  
  componentDidMount() {

    //alert("as")
    var token= localStorage.getItem('accessToken');
    if(token =="null") { 
      this.setState({is_user_login:false})
      window.location.hash = "login"
    }else{

      //alert(token)
    }


    this.upcomingDebates();
    navigator.geolocation.getCurrentPosition( (position)=> {
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
      this.setState({
        lat:position.coords.latitude,
        long:position.coords.longitude,
      })
    });

    localStorage.getItem('loadServer')
    const loadServer =localStorage.setItem('loadServer',null); 
    // alert(loadServer)
    this.initSocket() 
  }

  initSocket = async () => {
    let socket = io.connect(socketUrl)
    globalSocket=socket;
     
  }
  componentDidUpdate() {

    // this.state.socket.on('watch_debate',  (data) => {
    //     console.log(data)
    //     if(this.state.live_users!=data.live_count){
    //       alert(data.live_count)
    //       this.setState({live_users:data.live_count})
    //     } 
    // });

    if(this.state.lat!=""){
      this.updateLocation()
    }
  }
  
  updateLocation = async () => {
      let response = await axios({
          url: `${API_BASE_URL}users/update-location`,
          method: 'POST',
          data: {
            lat:this.state.lat,
            long:this.state.long,
            loginUser
          }
        });
  }
  upcomingDebates = async () => {
    let upcomingCount=0;
    let response = await axios.get(API_BASE_URL+'upcoming-debates/'+loginUser);  
    for(let deb of response.data.data){
      // alert(deb.started)
      if(deb.started !== "2"){
        // alert("entered")
        upcomingCount++;
      }
    } 
    
    this.setState({
      upcomingDebates: response.data.data,
      pending:response.data.counts_pending,
      // upcoming:response.data.counts_upcoming
      upcoming:upcomingCount
    }); 
  }
 
  handleChange = async (date) => {
    
    var newdate=moment(date.toISOString()).format();
    var debate_date=newdate.split('T');
    this.setState({
      debateDate: date,
      filter: debate_date[0]
    })
  }

  updateNotification = async (id) => {
    let noti = await axios.get(API_BASE_URL+'update-upcoming-notification/'+loginUser, {'debate_id':id,'user_id':loginUser});
  }
  joinDebate=async(link)=>{
    // alert(link)
    window.location.assign(link);
  }
  cancelDebate=async(id)=>{
    let data = await axios.post(API_BASE_URL+'debate/decline',{'debate_id':id,'user_id':1});
    await globalSocket.emit( 'decline_debate', {'debate_id':id,'user_id':loginUser} )
    NotificationManager.warning('', 'You have cancelled the debate..');
    this.updateNotification(id);
    this.upcomingDebates();
  }

  handleJoin = async () => {
    console.log(this.state)
    Cookies.set('channel', this.state.channel)
    Cookies.set('baseMode', this.state.baseMode)
    Cookies.set('transcode', this.state.transcode)
    Cookies.set('attendeeMode', this.state.attendeeMode)
    Cookies.set('videoProfile', this.state.videoProfile)
    var payload= {
      'debate_duration':this.state.debateDuration,
      'topic':this.state.debateTopic,
      'debate_date':this.state.debateDate,
      'debate_time':this.state.debateTime,
      'userId':1
    } 
    await axios.post(API_BASE_URL+'debate',payload).then(function (response) {
      //this.createNotification('success')
    //  alert('done');
      NotificationManager.success('', 'Debate has been created.');
    }).catch(function (error) {
      console.log(error);
    });

  }

  filter=async()=>{
    // alert(this.state.debateDate.toISOString())
    // alert(moment(this.state.debateDate.toISOString()).format())
    let upcomingCount=0;
    let response = await axios.post(API_BASE_URL+'debate/filter',{'debate_date':this.state.filter,userId:loginUser,'type':'upcoming'});
    for(let deb of response.data.data){
      // alert(deb.started)
      if(deb.started !== "2"){
        // alert("entered")
        upcomingCount++;
      }
    } 
    this.setState({
      upcomingDebates: response.data.data ,
      upcoming:upcomingCount
    });

  }
  render() {
    //const {allDebates} = this.state;
    //console.log(this.state.allDebates)
    //console.log('allDebates')
 
    //length = this.state.upcomingDebates.filter(function(item){ return item.is_read; }).length;
   // length = this.state.upcomingDebates.length;

    return (
      <>
      <Header/>
      {/* <Socket/> */}
      <section class="debate_outer">
        <div class="container custom_container">
          <div class="row">
            <div class="col-md-12">
              <div class="set_debate_main">              
                <div class="debate_title">Set Up A Debate</div>
                <a class="create_debate_btn1" href="#/set-up-new-debate">Create Debate</a>
              </div>
              <div class="debatetab_outer">
                <div class="debate_left">
                  <Sidebar 
                    upcoming={this.state.upcoming}
                    pending={this.state.pending}
                  />
                </div>
                <div class="debate_right upcoming_debate_outer">
                  <div class="mt-2 tab-content">
                    <div class="tab-pane active" id="home" role="tabpanel">
                      <div class="tab_inner upcoming_debate">
                        <div class="share_div filter_outer"> 
                          <div class="date_input">
                           <DatePicker
                                className="custom_input"
                                selected={ this.state.debateDate } 
                                onChange={ this.handleChange }
                                name="startDate"
                                minDate={moment().toDate()}
                                dateFormat="dd-MM-yyyy"
                                autoComplete="off"
                            />
                          </div>
                          <input class="" type="button" value="Filter" name="" onClick={this.filter}/>
                        </div>
                        <ul class="upcoming_ul"> 
                          {  
                              this.state.upcomingDebates.map((row,i) =>(     

                                row.started !== "2" ?
                              <div>   


                               
                                <li key={i} className={ (row.isAccepted == '0' || row.started == "1")  ? 'invite':''}>
                                
                                  <div class="upcoming_date" >Date - {moment(row.debate_date).format("LL")} </div>
                                  <div class="upcoming_date">Scheduled Time - {moment.utc(row.utc_date_time).tz(row.timezone).format('hh:mm A') } @ {row.timezone=="America/Kentucky/Monticello"?"Eastern Daylight Time":row.timezone} </div>
                                  {/* <div class="upcoming_date">My Time Zone - {moment.utc(row.utc_date_time).tz(loggedinTimeZone).format('hh:mm:ss A') } @ {loggedinTimeZone}   </div> */}

                                  <div class="upcoming_li_inner">
                                    <div class="upcoming_li_left">
                                      <img src={(row.userId == loginUser)?row.debateWith.profile_image:row.user.profile_image} alt=""/>
                                      <div class="desc_div">
                                          { (row.userId == loginUser)?
                                            <a href={"https://twitter.com/"+ row.debateWith.email}  target="_blank">
                                              <div class="name_title">{(row.userId == loginUser)?row.debateWith.name: row.user.name}</div>
                                            </a>
                                              :
                                            <a href={"https://twitter.com/"+ row.user.email }  target="_blank">
                                              <div class="name_title">{(row.userId == loginUser)?row.debateWith.name: row.user.name}</div>
                                            </a> 
                                          }
                                        
                                        <p>                                       
                                          @{(row.userId == loginUser)?row.debateWith.email.substring(0, 8): row.user.email.substring(0, 8)}...</p>
                                      </div>
                                     </div>
                                     <div class="upcoming_li_right">
                                      <p><span>Topic : </span> {row.topic}</p> 
                                      <p><span>Debate Link : </span>{row.debate_public_link.substring(0, 20)}....  <CopyToClipboard text={BASE_URL+'#/event-link/'+row.id} onCopy={() => toast.success('Link copied.', {autoClose:3000}) }><button class="copy"><i className="fal fa-copy"></i></button></CopyToClipboard></p>
                                      {row.started == "1" &&
                                        <div>
                                        <a class="submit_btn invite" href={row.debate_public_link} onClick={e=>this.joinDebate(row.debate_public_link)}>Join debate</a>
                                        <a class="cancel_btn" href="javascript:void(0)" onClick={e=>this.cancelDebate(row.id)}>Cancel</a>
                                        </div>
                                      }
                                      
                                      {row.started == "2" &&
                                        <a class="cancel_btn invite" href="javascript:void(0)">Debate Ended</a>
                                      }
                                      {row.started == "0" &&
                                        <a class="cancel_btn" href="javascript:void(0)" onClick={e=>this.cancelDebate(row.id)}>Cancel</a>
                                      }

                                      {this.state.copied ? toast.success('Link copied.', {autoClose:3000}) : null}
                                     </div>
                                  </div>
 
                                </li>   
                                </div>
                              :''))
                         }  
                        </ul>
                        <div class="row no_records">
                          { this.state.upcomingDebates.length<1 || this.state.upcoming == 0 ? "You have no upcoming debates":""}
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="profile" role="tabpanel">
                      <div class="tab_inner">
                        <div class="share_div"><a href="#"><i class="fas fa-share"></i>Share</a></div>
                        <div class="tab_inputs">
                          <label class="custom_label">Topic</label>
                          <input class="custom_input" type="text" placeholder="" name="" onChange={e => this.setState({ debateTopic: e.target.value })}/>
                          
                          <label class="custom_label">Date</label>
                          <div class="date_input">
                            <input class="custom_input" type="date" placeholder="" name="" onChange={e => this.setState({ debateDate: e.target.value })}/>
                            <i class="fal fa-calendar"></i>
                          </div>
                          {/*<DatePicker
                            onChange={e => this.setState({ debateTime: e.target.value })}
                            value={this.state.debateTime}
                          />*/}
                          <label class="custom_label">Time</label>
                          <div class="date_input">
                            <input class="custom_input" type="time" placeholder="" name=""onChange={e => this.setState({ debateTime: e.target.value })}/>
                            <i class="fal fa-calendar"></i>
                          </div>
                          {/*<TimePicker
                            onChange={e => this.setState({ debateDuration: e.target.value })}
                            value={this.state.debateDuration}
                          />*/}
                          <label class="custom_label">Debate Duration</label>
                          <input class="custom_input" type="text" placeholder="" name="" onChange={e => this.setState({ debateDuration: e.target.value })}/>
                          

                          <a class="submit_btn" onClick={this.handleJoin} disabled={!this.state.joinBtn}>Submit</a>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="messages" role="tabpanel">
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <NotificationContainer/>
      <Footer/>
      </>
    )
  }
}

  